/*jshint esversion: 6 */
/*global fetch, btoa */
import Q from 'q';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';

/**
 * HCl Now Portal API Docs
 * @class HnpApiUtils
 * @param {(string|object)} [domainOrOptions] - The project domain or options object. If object, see the object's optional properties.
 * @param {string} [domainOrOptions.domain] - The project domain
 * @param {object} [domainOrOptions.token] - auth token - object with value property and optional headerOrQueryName and isQuery properties
 */
const HnpApiUtils = (function () {
    'use strict';

    function HnpApiUtils(options) {
        let domain = typeof options === 'object' ? options.domain : options;
        this.domain = domain ? domain : '';
        if (this.domain.length === 0) {
            throw new Error('Domain parameter must be specified as a string.');
        }
        this.apiKey =
            typeof options === 'object'
                ? options.apiKey
                    ? options.apiKey
                    : {}
                : {};
    }

    function serializeQueryParams(parameters) {
        let str = [];
        for (let p in parameters) {
            if (parameters.hasOwnProperty(p)) {
                str.push(
                    encodeURIComponent(p) +
                        '=' +
                        encodeURIComponent(parameters[p])
                );
            }
        }
        return str.join('&');
    }

    function mergeQueryParams(parameters, queryParameters) {
        if (parameters.$queryParameters) {
            Object.keys(parameters.$queryParameters).forEach(function (
                parameterName
            ) {
                let parameter = parameters.$queryParameters[parameterName];
                queryParameters[parameterName] = parameter;
            });
        }
        return queryParameters;
    }

    /**
     * HTTP Request
     * @method
     * @name HnpApiUtils#request
     * @param {string} method - http method
     * @param {string} url - url to do request
     * @param {object} parameters
     * @param {object} body - body parameters / object
     * @param {object} headers - header parameters
     * @param {object} queryParameters - querystring parameters
     * @param {object} form - form data object
     * @param {object} deferred - promise object
     */
    HnpApiUtils.prototype.request = function (
        method,
        url,
        parameters,
        body,
        headers,
        queryParameters,
        form,
        deferred
    ) {
        const queryParams =
            queryParameters && Object.keys(queryParameters).length
                ? serializeQueryParams(queryParameters)
                : null;
        const urlWithParams = url + (queryParams ? '?' + queryParams : '');

        if (body && !Object.keys(body).length) {
            body = undefined;
        }

        fetch(urlWithParams, {
            method,
            headers,
            body: JSON.stringify(body),
            credentials: 'include'
        })
            .then(async response => {
                let res = {
                    data: response.json(),
                    ok: response.ok,
                    status: response.status,
                    statusText: response.statusText
                };
                const data = await res.data;
                res = {
                    ...res,
                    data
                };
                if (!response.ok) {
                    throw res;
                } else {
                    return res;
                }
            })
            .then(body => {
                deferred.resolve(body);
            })
            .catch(error => {
                if (error.data.message === 'Session timeout') {
                    safeRedirect(`${this.domain}/logout`);
                }
                deferred.reject(error);
            });
    };

    /**
     * Set Api Key
     * @method
     * @name HnpApiUtils#setApiKey
     * @param {string} value - apiKey's value
     * @param {string} headerOrQueryName - the header or query name to send the apiKey at
     * @param {boolean} isQuery - true if send the apiKey as query param, otherwise, send as header param
     */
    HnpApiUtils.prototype.setApiKey = function (
        value,
        headerOrQueryName,
        isQuery
    ) {
        this.apiKey.value = value;
        this.apiKey.headerOrQueryName = headerOrQueryName;
        this.apiKey.isQuery = isQuery;
    };
    /**
     * Set Auth headers
     * @method
     * @name HnpApiUtils#setAuthHeaders
     * @param {object} headerParams - headers object
     */
    HnpApiUtils.prototype.setAuthHeaders = function (headerParams) {
        let headers = headerParams ? headerParams : {};
        if (!this.apiKey.isQuery && this.apiKey.headerOrQueryName) {
            headers[this.apiKey.headerOrQueryName] = this.apiKey.value;
        }
        return headers;
    };

    /**
     * Get order(s) for specific customer account(s)
     * @method
     * @name HnpApiUtils#getOrder
     * @param {object} parameters - method options and parameters
     * @param {array} parameters.hcn - HCL Customer Number of a customer account
     * @param {array} parameters.orderId - orderId of specific customer order
     * @param {array} parameters.partNumber - Product Part Number of a specific lineItem
     * @param {array} parameters.envType - Fetch only the specific field(s) for the given input
     * @param {array} parameters.fields - Fetch only the specific field(s) for the given input
     */
    HnpApiUtils.prototype.getOrder = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/order';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        if (parameters['hcn'] !== undefined) {
            queryParameters['hcn'] = parameters['hcn'];
        }

        if (parameters['orderId'] !== undefined) {
            queryParameters['orderId'] = parameters['orderId'];
        }

        if (parameters['partNumber'] !== undefined) {
            queryParameters['partNumber'] = parameters['partNumber'];
        }

        if (parameters['envType'] !== undefined) {
            queryParameters['envType'] = parameters['envType'];
        }

        if (parameters['fields'] !== undefined) {
            queryParameters['fields'] = parameters['fields'];
        }

        if (parameters['nowOrderType'] !== undefined) {
            queryParameters['nowOrderType'] = parameters['nowOrderType'];
        }

        if (parameters['includeTrialOrders'] !== undefined) {
            queryParameters['includeTrialOrders'] =
                parameters['includeTrialOrders'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * This API creates customer account and its orders in Portal DB if customer account associated with input HCN is not already there otherwise it adds only order(s) and line item(s) details as part of existing customer account
     * @method
     * @name HnpApiUtils#postOrder
     * @param {object} parameters - method options and parameters
     * @param {} parameters.payload - Customer and order details
     */
    HnpApiUtils.prototype.postOrder = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/order';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        if (parameters['payload'] !== undefined) {
            body = parameters['payload'];
        }

        if (parameters['payload'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: payload'));
            return deferred.promise;
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'POST',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Get stakeholder(s) of a specific customer account
     * @method
     * @name HnpApiUtils#getStakeholder
     * @param {object} parameters - method options and parameters
     * @param {array} parameters.hcn - HCL Customer Number of a customer account
     * @param {array} parameters.partNumber - Product Part Number of a specific lineItem
     * @param {array} parameters.notification - Notification type for stakeholders
     * @param {array} parameters.role - Role of stakeholders
     * @param {array} parameters.fields - Fetch only the specific field(s) for the given input
     */
    HnpApiUtils.prototype.getStakeholder = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/stakeholder';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        if (parameters['hcn'] !== undefined) {
            queryParameters['hcn'] = parameters['hcn'];
        }

        if (parameters['partNumber'] !== undefined) {
            queryParameters['partNumber'] = parameters['partNumber'];
        }

        if (parameters['notification'] !== undefined) {
            queryParameters['notification'] = parameters['notification'];
        }

        if (parameters['role'] !== undefined) {
            queryParameters['role'] = parameters['role'];
        }

        if (parameters['fields'] !== undefined) {
            queryParameters['fields'] = parameters['fields'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Update user(s) and stakeholder(s) if already existing, delete in db if not listed in CSV otherwise create new for a specific customer account
     * @method
     * @name HnpApiUtils#putStakeholder
     * @param {object} parameters - method options and parameters
     * @param {file} parameters.stakeholders - CSV file contains stakeholder(s) details associated with a specific customer account
     */
    HnpApiUtils.prototype.putStakeholder = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/stakeholder';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['multipart/form-data'];

        if (parameters['stakeholders'] !== undefined) {
            form['stakeholders'] = parameters['stakeholders'];
        }

        if (parameters['stakeholders'] === undefined) {
            deferred.reject(
                new Error('Missing required  parameter: stakeholders')
            );
            return deferred.promise;
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'PUT',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Export stakeholder(s) details with activation link
     * @method
     * @name HnpApiUtils#getStakeholderActivationlink
     * @param {object} parameters - method options and parameters
     * @param {array} parameters.hcn - HCL Customer Number of a customer account
     */
    HnpApiUtils.prototype.getStakeholderActivationlink = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/stakeholder/activationlink';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['text/csv'];

        if (parameters['hcn'] !== undefined) {
            queryParameters['hcn'] = parameters['hcn'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Get HCL Now Portal user information of logged in user
     * @method
     * @name HnpApiUtils#getUserinfo
     * @param {object} parameters - method options and parameters
     */
    HnpApiUtils.prototype.getUserinfo = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/userinfo';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Create new or update existing provisioned environment details for a specific customer account
     * @method
     * @name HnpApiUtils#putProvisionedenv
     * @param {object} parameters - method options and parameters
     * @param {} parameters.payload - Provisioned environment details
     */
    HnpApiUtils.prototype.putProvisionedenv = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/provisionedenv';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];
        headers['Content-Type'] = ['application/json'];

        if (parameters['payload'] !== undefined) {
            body = parameters['payload'];
        }

        if (parameters['payload'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: payload'));
            return deferred.promise;
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'PUT',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Proxy to Service Now API(s) to get Service Now details for a specific customer and product
     * @method
     * @name HnpApiUtils#getServiceNowByPath
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.path - Service Now API path with valid query parameters like customer, start_date and end_date
     */
    HnpApiUtils.prototype.getServiceNowByPath = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/service-now/{path}';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        path = path.replace('{path}', parameters['path']);

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Proxy to OpenSearch API(s) to get OpenSearch details for a specific customer and product
     * @method
     * @name HnpApiUtils#getOpensearchServiceMetrics
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.customer - HCL Customer Number of a customer account
     * @param {string} parameters.product - Product name
     * @param {number} parameters.days - Number of days
     * @param {number} parameters.size - Page limit of results
     */
    HnpApiUtils.prototype.getOpensearchServiceMetrics = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/opensearch/service-metrics';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        if (parameters['customer'] !== undefined) {
            queryParameters['customer'] = parameters['customer'];
        }

        if (parameters['customer'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: customer'));
            return deferred.promise;
        }

        if (parameters['product'] !== undefined) {
            queryParameters['product'] = parameters['product'];
        }

        if (parameters['from'] !== undefined) {
            queryParameters['from'] = parameters['from'];
        }

        if (parameters['to'] !== undefined) {
            queryParameters['to'] = parameters['to'];
        }

        if (parameters['size'] !== undefined) {
            queryParameters['size'] = parameters['size'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Proxy to OpenSearch API(s) to get OpenSearch details for a specific customer and product
     * @method
     * @name HnpApiUtils#getOpenCasesCountSummary
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.hcn - HCL Customer Number of a customer account
     */
    HnpApiUtils.prototype.getOpenCasesCountSummary = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/proxy/opensearch/open-cases-count';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        if (parameters['hcn'] !== undefined) {
            queryParameters['hcn'] = parameters['hcn'];
        }

        if (parameters['hcn'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: hcn'));
            return deferred.promise;
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };
    /**
     * Proxy to OpenSearch API(s) to get Priority Case summary for a specific customer and product
     * @method
     * @name HnpApiUtils#getOpensearchPriorityCases
     * @param {object} parameters - method options and parameters
     * @param {string} parameters.customer - HCL Customer Number of a customer account
     * @param {string} parameters.product - Product name
     * @param {number} parameters.size - Page limit of results
     */
    HnpApiUtils.prototype.getOpensearchPriorityCases = function (parameters) {
        if (parameters === undefined) {
            parameters = {};
        }
        let deferred = Q.defer();
        let domain = this.domain,
            path = '/proxy/opensearch/priority-cases';
        let body = {},
            queryParameters = {},
            headers = {},
            form = {};

        headers = this.setAuthHeaders(headers);
        headers['Accept'] = ['application/json'];

        if (parameters['hcn'] !== undefined) {
            queryParameters['hcn'] = parameters['hcn'];
        }

        if (parameters['hcn'] === undefined) {
            deferred.reject(new Error('Missing required  parameter: hcn'));
            return deferred.promise;
        }

        if (parameters['product'] !== undefined) {
            queryParameters['product'] = parameters['product'];
        }

        if (parameters['size'] !== undefined) {
            queryParameters['size'] = parameters['size'];
        }

        queryParameters = mergeQueryParams(parameters, queryParameters);

        this.request(
            'GET',
            domain + path,
            parameters,
            body,
            headers,
            queryParameters,
            form,
            deferred
        );

        return deferred.promise;
    };

    return HnpApiUtils;
})();

export default HnpApiUtils;
