import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import MobileStepper from '@mui/material/MobileStepper';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { hclNowLinks } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';

const PREFIX = 'HnpPriorityAlert';

const classes = {
    priorityAlertBox: `${PREFIX}-priorityAlertBox`,
    alertStepper: `${PREFIX}-alertStepper`,
    support: `${PREFIX}-support`,
    errorIcon: `${PREFIX}-errorIcon`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.priorityAlertBox}`]: {
        '& .MuiAlert-root': {
            backgroundColor: colors.alertRed,
            borderRadius: theme.spacing(0.5),
            borderLeft: `${theme.spacing(1)} solid ${colors.error} !important`,
            border: `${theme.spacing(0.125)} solid ${colors.error}`,
            padding: theme.spacing(1.25, 3),
            color: colors.black1
        },
        '& .MuiAlert-action': {
            flexShrink: 0
        },
        '& .MuiAlert-message': {
            margin: theme.spacing(1, 0),
            padding: 0,
            fontWeight: 600,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical'
        }
    },
    [`& .${classes.alertStepper}`]: {
        background: colors.alertRed,
        padding: theme.spacing(0),
        alignSelf: 'center'
    },
    [`& .${classes.support}`]: {
        color: colors.support,
        cursor: 'pointer',
        fontWeight: 400,
        textDecoration: 'underline',
        marginLeft: theme.spacing(0.625)
    },
    [`& .${classes.errorIcon}`]: {
        '&.MuiSvgIcon-root': {
            fontSize: theme.spacing(3)
        },
        color: colors.error
    }
}));

const HnpPriorityAlert = props => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const { alertData } = props;
    const maxSteps = alertData.length;

    const [activeStep, setActiveStep] = useState(0);
    const [alert, setAlert] = useState(alertData[0]);
    const [open, setOpen] = useState(true);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
    const handleClose = () => {
        setOpen(!open);
    };
    const handleRedirect = url => e => {
        e.preventDefault();
        safeRedirect(url, true);
    };
    const lowerCaseType = caseType => {
        return caseType.charAt(0).toLowerCase() + caseType.slice(1);
    };
    const getCaseNumbers = alertData => {
        return alertData.map(obj => obj.caseNumber).join(', ');
    };

    const forDetailsContactElement = (
        <Fragment>
            <Box component="span">For details, contact</Box>
            <Box
                className={classes.support}
                component="span"
                onClick={handleRedirect(hclNowLinks.hclCSM)}
            >
                HCL Support
            </Box>
            <Box component="span">.</Box>
        </Fragment>
    );
    const alertForDesktop = (
        <Box className={classes.priorityAlertBox}>
            <Alert
                icon={<ErrorIcon className={classes.errorIcon} />}
                action={
                    <MobileStepper
                        className={classes.alertStepper}
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <IconButton
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        }
                        backButton={
                            <IconButton
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                        }
                    />
                }
            >
                {`Info P${alert.priority} ${lowerCaseType(alert.u_type)} `}
                <strong>{alert.caseNumber}</strong>
                {` is open : ${alert.subject}. `}
                {forDetailsContactElement}
            </Alert>
        </Box>
    );
    const alertForMobile = (
        <Box className={classes.priorityAlertBox}>
            <Collapse in={open}>
                <Alert
                    icon={<ErrorIcon className={classes.errorIcon} />}
                    action={
                        <IconButton size="small" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                >
                    {`Info ${alertData.length} P1 `}
                    {alertData.length === 1
                        ? `case (${alertData[0].caseNumber}) is`
                        : `cases (${getCaseNumbers(alertData)}) are`}
                    {' open. '}
                </Alert>
            </Collapse>
        </Box>
    );

    useEffect(() => {
        setAlert(alertData[activeStep]);
    }, [activeStep]);

    return <Root>{isMobileScreen ? alertForDesktop : alertForMobile}</Root>;
};

HnpPriorityAlert.propTypes = {
    alertData: PropTypes.array
};

export default HnpPriorityAlert;
