import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { StyledPaper, StyledSwitch } from '../HnpMuiComponents';
import Cookies from 'js-cookie';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { hnpFeatureFlagsData } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import HnpNotFound from '../HnpNotFound';
import { HnpContext } from '../../contexts/HnpContext';

const PREFIX = 'HnpFeatureFlagsControl';

const classes = {
    container: `${PREFIX}-container`,
    heading: `${PREFIX}-heading`,
    paper: `${PREFIX}-paper`,
    text: `${PREFIX}-text`,
    form: `${PREFIX}-form`,
    formRow: `${PREFIX}-formRow`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        padding: theme.spacing(7.8, 0, 3)
    },
    [`& .${classes.heading}`]: {
        color: 'white'
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(3, 4)
    },
    [`& .${classes.text}`]: {
        marginTop: theme.spacing(3)
    },
    [`& .${classes.form}`]: {
        marginTop: theme.spacing(7),
        minHeight: '40vh'
    },
    [`& .${classes.formRow}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiFormControlLabel-label': {
            color: `${colors.turquoise1}`
        }
    }
}));

const HnpFeatureFlagsControl = () => {
    const { appStore } = useContext(HnpContext);
    const { userDetails } = appStore || {};
    const [isLoading, setIsLoading] = useState(true);
    const [featureFlags, setFeatureFlags] = useState(
        hnpFeatureFlagsData.reduce((obj, ffObj) => {
            obj[ffObj.name] = false;
            return obj;
        }, {})
    );

    useEffect(() => {
        setIsLoading(false);
        let ffsData = Cookies.get('hnp_ffs');
        if (ffsData) {
            ffsData = JSON.parse(ffsData);
            setFeatureFlags({ ...featureFlags, ...ffsData });
        }
    }, []);

    const handleSwitch = e => {
        const newFfData = {
            ...featureFlags,
            [e.target.name]: e.target.checked
        };
        // Store the feature flags in cookies
        Cookies.set('hnp_ffs', JSON.stringify(newFfData), {
            secure: process.env.NODE_ENV !== 'development',
            sameSite: 'strict'
        });
        // Update the state
        setFeatureFlags(newFfData);
    };

    return userDetails && userDetails.role !== 'admin' ? (
        <HnpNotFound />
    ) : (
        <StyledBox className={classes.container} sx={sxStyles.appContainer}>
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
                <Grid xs={3}>
                    <Typography variant="h6" className={classes.heading}>
                        Upcoming |
                    </Typography>
                    <Typography variant="h6" className={classes.heading}>
                        Unsupported Features
                    </Typography>
                </Grid>
                <Grid xs={9}>
                    <StyledPaper square className={classes.paper}>
                        <Typography variant="h5">
                            Try out upcoming features
                        </Typography>
                        <Typography display="block" className={classes.text}>
                            To enable the following upcoming/unsupported
                            features, toggle below and refresh page to explore.
                        </Typography>
                        <Typography display="block">
                            NOTE: These features are not complete and have not
                            yet been tested on every browser.
                        </Typography>
                        {isLoading ? null : (
                            <FormControl
                                component="fieldset"
                                className={classes.form}
                            >
                                <FormGroup
                                    aria-label="position"
                                    row
                                    className={classes.formRow}
                                >
                                    {hnpFeatureFlagsData.map(
                                        ({ label, name }, i) => (
                                            <FormControlLabel
                                                key={i}
                                                control={
                                                    <StyledSwitch
                                                        name={name}
                                                        checked={
                                                            featureFlags[name]
                                                        }
                                                        onChange={handleSwitch}
                                                    />
                                                }
                                                label={`${label}`}
                                            />
                                        )
                                    )}
                                </FormGroup>
                            </FormControl>
                        )}
                    </StyledPaper>
                </Grid>
            </Grid>
        </StyledBox>
    );
};

export default HnpFeatureFlagsControl;
