import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';

const PREFIX = 'HnpFooter';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    linkContainer: `${PREFIX}-linkContainer`,
    copyRight: `${PREFIX}-copyRight`,
    links: `${PREFIX}-links`
};

const Root = styled('footer')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        minHeight: theme.spacing(7.75),
        backgroundColor: colors.grey10,
        boxShadow: 'none',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: colors.white2,
        padding: theme.spacing(2.625, 3.75),
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: theme.spacing(1.25),
            padding: theme.spacing(2),
            justifyContent: 'center'
        }
    },
    [`& .${classes.linkContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            order: '2',
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: theme.spacing(32.5)
        }
    },
    [`& .${classes.copyRight}`]: {
        color: colors.white,
        [theme.breakpoints.down('sm')]: {
            order: '3',
            fontSize: theme.spacing(1.5)
        },
        [theme.breakpoints.between('sm', 'md')]: {
            order: '3'
        }
    },
    [`& .${classes.links}`]: {
        cursor: 'pointer',
        color: colors.white,
        background: 'transparent',
        border: 'none',
        padding: theme.spacing(0.25, 0.25, 0, 0.25),
        fontFamily: 'inherit',
        fontSize: theme.spacing(1.75),
        letterSpacing: 'inherit',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            fontSize: theme.spacing(1.5),
            padding: theme.spacing(0, 1, 0, 1)
        }
    }
}));

const hclPrivacyUrl = 'https://www.hcltechsw.com/legal/privacy';
const termsUrl = process.env.PUBLIC_URL + '/doc/HCLNowWebsiteTermsofUse.pdf';

let HnpFooter = () => {
    const handleRedirect = url => e => {
        e.preventDefault();
        safeRedirect(url, true);
    };

    return (
        <Root className={classes.root}>
            <Box className={classes.container}>
                <Box className={classes.linkContainer}>
                    <Box
                        className={classes.links}
                        sx={sxStyles.tabFocus}
                        component="button"
                        onClick={handleRedirect(termsUrl)}
                    >
                        Terms
                    </Box>
                    <Box sx={sxStyles.separator}>|</Box>
                    <Box
                        className={classes.links}
                        sx={sxStyles.tabFocus}
                        component="button"
                        onClick={handleRedirect(hclPrivacyUrl)}
                    >
                        Privacy Statement
                    </Box>
                    <Box sx={sxStyles.separator}>|</Box>
                    <Box
                        component="button"
                        className={`${classes.links} ot-sdk-show-settings`}
                        sx={sxStyles.tabFocus}
                    >
                        Cookie Preferences
                    </Box>
                </Box>
                <Box component="span" className={classes.copyRight}>
                    Copyright &#169; 2022 HCL Technologies Ltd.
                </Box>
            </Box>
        </Root>
    );
};

export default HnpFooter;
