import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

const PREFIX = 'HnpMessage';

const classes = {
    root: `${PREFIX}-root`,
    msgText: `${PREFIX}-msgText`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(10, 2, 4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(16.875, 2, 4)
        }
    },
    [`& .${classes.msgText}`]: {
        fontSize: theme.spacing(1.75),
        fontStyle: 'italic',
        color: colors.grey5,
        opacity: 0.85
    }
}));

const HnpMessage = props => {
    return (
        <StyledBox className={classes.root}>
            <Box className={classes.msgText}>{props.msg}</Box>
        </StyledBox>
    );
};

HnpMessage.propTypes = {
    msg: PropTypes.string.isRequired
};

export default HnpMessage;
