import Cookies from 'js-cookie';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

// Check data type of a variable
export const checkDataType = data =>
    Object.prototype.toString.call(data).slice(8, -1);

// Form a display name of a user without dp. e.g.: John Doe = JD or Ram Kumar Gopal = RG
export const getDisplayName = fullName => {
    // fullName is `undefined`
    if (!fullName) {
        return '';
    }
    // fullName could be `Object` or `Array` which is invalid type
    const typeOfFullName = checkDataType(fullName);
    if (typeOfFullName === 'Object' || typeOfFullName === 'Array') {
        return '';
    }
    // Finally, fullName is `String`
    let displayName = '';
    const fullNameArr = fullName.split(' ');
    const fullNameArrLen = fullNameArr.length;

    displayName =
        fullNameArrLen > 1
            ? fullNameArr[0].charAt(0) +
              fullNameArr[fullNameArrLen - 1].charAt(0).toUpperCase()
            : fullNameArr[0].charAt(0).toUpperCase();

    return displayName;
};

//Show User role in UI
export const getDisplayUserRole = role => {
    if (role === 'hcl' || role === 'customer' || role === 'partner') {
        return 'STAKEHOLDER';
    } else {
        return role.toUpperCase();
    }
};

// Check if the user is eligible to contribute source code through solutions for a particular challenge or not; CFIUS
export const checkIfEligibleMember = (
    userDetails,
    developmentlocations,
    softwareproducts,
    selectedSofProduct
) => {
    if (
        userDetails &&
        developmentlocations &&
        developmentlocations.length &&
        softwareproducts &&
        softwareproducts.length &&
        selectedSofProduct
    ) {
        const isApprovedDevLoc = developmentlocations.findIndex(
            devLoc =>
                devLoc.country.toLowerCase() ===
                    userDetails.country.toLowerCase() && devLoc.isApproved
        );
        const isInScopeSofProd = softwareproducts.findIndex(
            sofProd =>
                sofProd.name.toLowerCase() ===
                    selectedSofProduct.toLowerCase() && sofProd.isInScope
        );
        if (isInScopeSofProd > -1 && isApprovedDevLoc === -1) {
            return true;
        }
    }

    return false;
};

// Generic or specific error messages
export const fieldLevelMsg = {
    errorText: 'field is required'
};

// Regex to validate email address
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Safe anchor/link redirection
export const safeRedirect = (url, isNewTab = false) => {
    if (url && url !== '#!') {
        const redirectLink = document.createElement('a');
        redirectLink.href = url;
        if (isNewTab) {
            redirectLink.target = '_blank';
            redirectLink.rel = 'noopener noreferrer';
        }
        redirectLink.click();
    }
};

// To get Label for the selected duration
export const getLabel = (hnpDurations, duration) => {
    switch (duration) {
        case hnpDurations.thisMonth.value:
            return hnpDurations.thisMonth.label;
        case hnpDurations.lastMonth.value:
            return hnpDurations.lastMonth.label;
        case hnpDurations.past30Days.value:
            return hnpDurations.past30Days.label;
        case hnpDurations.past60Days.value:
            return hnpDurations.past60Days.label;
        case hnpDurations.past90Days.value:
            return hnpDurations.past90Days.label;
        default:
            return '';
    }
};

// Logout and redirect to login page
export const logoutAndRedirect = (hnpApiUtils, Cookies) => {
    Cookies.remove('hnp_ffs');
    Cookies.remove('hnp_pers');
    // Redirect to logout URL to destroy the session
    safeRedirect(`${hnpApiUtils.domain}/logout`);
};

export const sortArrayOfObjects = arr =>
    (arr &&
        arr
            .map(obj => obj.value || obj.name)
            .sort()
            .reduce((resArr, str) => {
                arr.forEach(item => {
                    if (item.value === str) {
                        resArr.push(item);
                    }
                });

                return resArr;
            }, [])) ||
    [];

// Sort the array by customer name in alphabetical order
export const sortCustNamesByAlphOrder = (firstItem, secondItem) => {
    const nameA = firstItem.data2
        ? firstItem.data2.toUpperCase()
        : typeof firstItem === 'string' && firstItem.toUpperCase();
    const nameB = secondItem.data2
        ? secondItem.data2.toUpperCase()
        : typeof secondItem === 'string' && secondItem.toUpperCase();
    return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
};

// Flatten [GET]/userinfo details to conform the existing UI
export const flattenUserInfo = data =>
    (data && { ...data, name: `${data.firstName} ${data.lastName}` }) || null;

// Get HCL Customer Number (HCN) filter options from [GET]/order response
export const getHcnOptions = orders =>
    (orders &&
        orders.reduce((arr, order) => {
            const { hcn, name } = order.customerAccount;

            arr.push({
                value: hcn,
                label: hcn,
                data: hcn,
                data2: name
            });

            return arr;
        }, [])) ||
    [];

// Get orderId filter options from [GET]/order response
export const getOrderIdOptions = orders =>
    (orders &&
        orders.reduce((arr, order) => {
            order.orders.forEach(iOrder => {
                arr.push({
                    value: iOrder.orderId,
                    label: iOrder.orderId,
                    data: iOrder.orderId
                });
            });

            return arr;
        }, [])) ||
    [];

// Get products filter options from [GET]/order response
export const getProductPartNoOptions = orders => {
    const productsMap = {};

    return (
        (orders &&
            orders.reduce((arr, order) => {
                order.orders.forEach(({ lineItems }) => {
                    lineItems.forEach(({ partNumber, name, shortName }) => {
                        if (productsMap[partNumber] !== partNumber) {
                            // Add values to map
                            productsMap[partNumber] = partNumber;
                            // Update product display name
                            const updatedDisplayName = name
                                .split(',')[0]
                                .replace(
                                    /(Enterprise|Platform)|(on Now)/gim,
                                    ''
                                )
                                .trim();
                            // Push to final option array
                            arr.push({
                                value: updatedDisplayName,
                                label: updatedDisplayName,
                                data: partNumber,
                                data2: shortName
                            });
                        }
                    });
                });

                return arr;
            }, [])) ||
        []
    );
};

// Get environment type filter options from [GET]/order response
export const getEnvTypeOptions = orders => {
    const envTypesMap = {};

    return (
        (orders &&
            orders.reduce((arr, order) => {
                order.orders.forEach(({ lineItems }) => {
                    lineItems.forEach(({ environments }) => {
                        environments.forEach(
                            ({ provisionedEnvironment: { type } }) => {
                                if (envTypesMap[type] !== type) {
                                    // Add values to map
                                    envTypesMap[type] = type;
                                    // Update environment display name
                                    const updatedEnvName =
                                        type === 'dr' || type === 'qa'
                                            ? type.toUpperCase()
                                            : `${type[0].toUpperCase()}${type.slice(
                                                  1
                                              )}`;
                                    // Push to final option array
                                    arr.push({
                                        value: updatedEnvName,
                                        label: updatedEnvName,
                                        data: type
                                    });
                                }
                            }
                        );
                    });
                });

                return sortArrayOfObjects(arr);
            }, [])) ||
        []
    );
};

//Get p1 and p2 status of an environment
export const getEnvStatus = (priorityCases, type) => {
    if (type === 'prod' && priorityCases && priorityCases.cases.length > 0) {
        const criticalCases = priorityCases.cases.filter(
            item => item.priority === 1
        );
        const highCases = priorityCases.cases.filter(
            item => item.priority === 2
        );

        let status = '';
        let message = 'Running';

        const constructMessage = (caseType, cases) =>
            `${cases.length} ${caseType} ${
                cases.length === 1 ? 'case' : 'cases'
            } open`;

        if (criticalCases.length > 0) {
            status = 'error';
            message = constructMessage('P1', criticalCases);
        } else if (highCases.length > 0) {
            status = 'warning';
            message = constructMessage('P2', highCases);
        }
        return { status, message };
    } else if (
        type === 'prod' &&
        priorityCases &&
        priorityCases.cases.length === 0
    ) {
        return { status: '', message: 'Running' };
    }
};

// Get environment by id
export const getEnvById = (envs, envId) =>
    (envs && envs.filter(env => env._id === envId)[0]) || {};

// Get environment by type
export const getEnvByType = (envs, type) =>
    (envs &&
        type &&
        envs.filter(env => env.provisionedEnvironment.type === type)[0]) ||
    {};

// Get Duration values for metrics
export const getDurationValue = duration =>
    duration && duration.split(/[=&]/).filter(value => value.includes('now'));

// Stringify array values
export const stringifyArrayValues = arr =>
    (arr && arr.map(item => item.data).join(',')) || '';

// Check if environments contain any environment which is part of sofy solution
export const isEnvSofySolution = envs =>
    envs &&
    envs.filter(
        env =>
            env.environmentContent &&
            env.environmentContent.solutionType === 'sofy'
    ).length
        ? true
        : false;

// Serialize query parameters
export const serializeQueryParams = parameters => {
    let str = [];
    for (let p in parameters) {
        if (parameters.hasOwnProperty(p)) {
            str.push(
                encodeURIComponent(p) + '=' + encodeURIComponent(parameters[p])
            );
        }
    }

    return str.join('&');
};

// Get customer name from order details by `hcn` with alphabetical sort to display customer names
export const getCustomerNameByHcn = (orders, filters) =>
    (orders &&
        filters &&
        Object.keys(filters)
            .filter(key => key === 'hcn')
            .reduce((arr, key) => {
                filters[key].forEach(option => {
                    orders.forEach(({ customerAccount: { hcn, name } }) => {
                        if (hcn === option.value) {
                            arr.push(name);
                        }
                    });
                });

                return arr;
            }, [])
            .sort(sortCustNamesByAlphOrder)
            .join(', ')
            .trim()) ||
    '';

// Add props like `customerName`, `productName` and `name` (user full name) in stakeholders API response data
export const addUiPropsInStakehData = (orders, stakehData) =>
    (orders &&
        stakehData &&
        stakehData.reduce((arr, stakeh) => {
            arr.push({
                ...stakeh,
                productName: getProductNameFromOrdersByPartNumber(
                    orders,
                    stakeh.partNumber
                ),
                user: {
                    ...stakeh.user,
                    name: `${stakeh.user.firstName} ${stakeh.user.lastName}`
                }
            });

            return arr;
        }, [])) ||
    null;

// Get customer name from `orders` data by `hcn`
export const getCustomerNameFromOrdersByHcn = (orders, hcn) =>
    (orders &&
        hcn &&
        orders.reduce((str, { customerAccount }) => {
            if (hcn === customerAccount.hcn) {
                str = customerAccount.name;
            }

            return str;
        }, '')) ||
    '';

// Get product name from `orders` data by `partNumber`
export const getProductNameFromOrdersByPartNumber = (orders, partNumber) =>
    (orders &&
        partNumber &&
        orders.reduce((str, order) => {
            order.orders.forEach(({ lineItems }) => {
                lineItems.forEach(lineItem => {
                    if (partNumber === lineItem.partNumber) {
                        str = lineItem.name
                            .split(',')[0]
                            .replace(/(Enterprise|Platform)|(on Now)/gim, '')
                            .trim();
                    }
                });
            });

            return str;
        }, '')) ||
    '';

// Get notification type filter options from [GET]/stakeholder response
export const getNotificationTypeOptions = () =>
    [
        {
            type: 'App Performance',
            options: ['Warnings', 'Critical Alerts', 'Daily Job Failure']
        },
        {
            type: 'CritSits',
            options: [
                'Major Outage',
                'Extended Outage Escalation',
                'Escalation Priority',
                'Emergency Change'
            ]
        },
        {
            type: 'Maintenance',
            options: [
                'Datacenter Manintenance',
                'Infra Change',
                'Change Approval Priority'
            ]
        }
    ].reduce((arr, { type, options }) => {
        options.forEach(iKey => {
            const iKeyStr = iKey.replace(/\s/gi, '');
            const strVal = `${iKeyStr[0].toLowerCase()}${iKeyStr.slice(1)}`;
            arr.push({
                value: strVal,
                label: iKey,
                data: type
            });
        });

        return arr;
    }, []) || [];

export const getProductNameByCommaRule = partNumberName => {
    return partNumberName
        .split(',')[0]
        .replace(/(Enterprise|Platform)|(on Now)/gim, '')
        .trim();
};

export const getFirstEnvIdRule = provisionedEnvs => {
    return (
        (provisionedEnvs.length && getEnvByType(provisionedEnvs, 'prod')._id) ||
        (provisionedEnvs.length && provisionedEnvs[0]._id) ||
        null
    );
};

// Get the P1 cases from the result
export const getPriorityAlertData = (hcn, priorityCases) => {
    const [result] = priorityCases.data.result.filter(obj => obj.hcn === hcn);
    return result ? result.cases.filter(obj => obj.priority === 1) : [];
};

// Filter out the data from filter options
export const getFilteredOptions = valueToCompare => obj => {
    return valueToCompare.includes(obj.data);
};

// To enable features for certain roles
export const getFilteredRoles = userRole => {
    const stakeholdersRoles = ['admin', 'viewer', 'hcl'];
    return stakeholdersRoles.includes(userRole);
};

// get cookie value for provided key
export const getCookieValue = key => {
    return Cookies.get(key) ? JSON.parse(window.atob(Cookies.get(key))) : null;
};

// get and set cookie value for provided key
export const setCookieValue = (key, obj) => {
    const cookie_data = getCookieValue(key);
    const newCookieData = {
        ...cookie_data,
        ...obj
    };
    // Persist the values in cookies
    Cookies.set(key, window.btoa(JSON.stringify(newCookieData)), {
        secure: process.env.NODE_ENV !== 'development',
        sameSite: 'strict'
    });
};

// construct and return the metrics osd url based on the flag and roles
export const getMetricsUrl = (role, urlData) => {
    return role === 'customer'
        ? `${urlData.osdUrl}?_g=(filters:!())`
        : `${urlData.osdUrl}${urlData.osdFilters}`;
};

// chip color based on the environment type
export const getEnvChipColor = (hnpEnvironments, type) => {
    switch (type) {
        case hnpEnvironments.prod:
            return colors.loginGrad2;
        case hnpEnvironments.preProd:
            return colors.grey2;
        case hnpEnvironments.qa:
            return colors.blue2;
        default:
            return '';
    }
};
