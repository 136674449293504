import React, {
    createContext,
    useEffect,
    useState,
    Fragment,
    useMemo
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { StyledLinearProgress } from '../components/HnpMuiComponents';
import HnpApiUtils from '@hclnow-portal/hclnow-portal-swaggergen-utils';
import {
    envUrlandPort,
    updateEnvsWithProps,
    hnpNotificationTypes,
    hnpOrganization,
    hnpNowOrderType,
    hnpRoutes,
    hnpLoginStatus,
    hnpDurations,
    hnpFilters
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import {
    flattenUserInfo,
    getHcnOptions,
    getOrderIdOptions,
    getProductPartNoOptions,
    getEnvTypeOptions,
    addUiPropsInStakehData,
    getFilteredOptions,
    getCookieValue,
    getLabel
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';

const { fetch: originalFetch } = window;
// Instantiate API util
const hnpApiUtils = new HnpApiUtils({
    domain: envUrlandPort.hnp.api
});

const PREFIX = 'HnpContext';

const classes = {
    root: `${PREFIX}-root`,
    progress: `${PREFIX}-progress`
};
// MUI styles
const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        margin: theme.spacing(0),
        padding: theme.spacing(0)
    },
    [`& .${classes.progress}`]: {
        textAlign: 'center',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0
    }
}));
// Define initial global states
const INITIAL_STATE = {
    currentUrl: hnpRoutes.loginPage,
    isLogin: hnpLoginStatus.initialState,
    isAPISvcDown: false,
    userDetails: null,
    hnpApiUtils,
    duration: {
        value: hnpDurations.thisMonth.value,
        label: hnpDurations.thisMonth.label
    },
    orderDetails: null,
    filters: {
        hcn: [],
        orderId: [],
        partNumber: [],
        envType: [],
        notification: [],
        role: [],
        isHcnBlur: true,
        isOrderIdBlur: true,
        isPartNumberBlur: true,
        isEnvTypeBlur: true,
        isNotificationBlur: true,
        isRoleBlur: true,
        options: {
            hcn: [],
            orderId: [],
            partNumber: [],
            envType: [],
            notification: hnpNotificationTypes,
            role: hnpOrganization
        }
    },
    provisionedEnvs: [],
    priorityAlert: [],
    selectedOption: null,
    stakeholdersDetails: null,
    caseMetrics: {
        total: null,
        open: null,
        closed: null
    },
    openCases: {
        totalIncidents: null,
        totalServiceRequests: null,
        totalChangeRequests: null
    },
    isDemoMode: false
};
// Define main context API
const HnpContext = createContext(hnpApiUtils);
// Define main provider component
let HnpProvider = props => {
    const navigate = useNavigate();
    const currentUrl = useLocation().pathname;
    const hnpFeatureFlags = Cookies.get('hnp_ffs')
        ? JSON.parse(Cookies.get('hnp_ffs'))
        : null;
    const hnpPersData = getCookieValue('hnp_pers');
    const [appStore, setAppStore] = useState({
        ...INITIAL_STATE,
        currentUrl,
        isDemoMode: hnpPersData && hnpPersData.isDemoMode,
        ...(hnpPersData &&
            hnpPersData.duration && {
                duration: {
                    value: hnpPersData.duration,
                    label: getLabel(hnpDurations, hnpPersData.duration)
                }
            })
    });
    // Filter names
    const {
        hcnName,
        orderIdName,
        partNumberName,
        envTypeName,
        notificationName,
        roleName
    } = hnpFilters;

    useMemo(() => {
        window.fetch = async (...args) => {
            let [resource, config] = args;
            try {
                const response = await originalFetch(resource, config);
                if (response.status === 503) {
                    setAppStore({ ...appStore, isAPISvcDown: true });
                    navigate(hnpRoutes.maintenancePage);
                }
                return response;
            } catch (error) {
                window.location.reload();
            }
        };
    }, []);

    useEffect(() => {
        getUserInfo();
    }, []);
    useEffect(() => {
        // Send the user type(internal user or external user) information to GA4
        if (appStore.isLogin === hnpLoginStatus.loggedIn) {
            if (appStore.userDetails) {
                if (appStore.userDetails.email.split('@')[1] === 'hcl.com') {
                    window.dataLayer &&
                        window.dataLayer.push({
                            event: 'eventUserType',
                            userType: 'internal'
                        });
                } else {
                    window.dataLayer &&
                        window.dataLayer.push(
                            {
                                event: 'eventUserType',
                                userType: 'external'
                            },
                            {
                                event: 'eventCustomerName',
                                customerName:
                                    appStore.userDetails.email.split('@')[1]
                            }
                        );
                }
            }
        }
    }, [appStore.isLogin]);
    useEffect(() => {
        // Redirect to login page (default) if the user is not logged in
        if (
            appStore.isLogin === hnpLoginStatus.loggedOut &&
            currentUrl !== hnpRoutes.loginPage
        ) {
            navigate(hnpRoutes.loginPage);
        } else if (
            appStore.isLogin === hnpLoginStatus.loggedIn &&
            currentUrl === hnpRoutes.loginPage
        ) {
            navigate(hnpRoutes.environmentPage);
        }
    }, [appStore.isLogin]);
    useEffect(() => {
        // Update current URL state
        setAppStore({
            ...appStore,
            currentUrl
        });
        if (currentUrl.includes('/guide')) {
            document.oncontextmenu = null;
            document.onkeydown = null;
        } else {
            document.oncontextmenu = function (e) {
                e.preventDefault();
            };
            document.onkeydown = function (e) {
                const key = e.key.toLowerCase();
                //meta key refers to command key in macbook
                if ((e.ctrlKey || e.metaKey) && key === 'a') {
                    e.preventDefault();
                }
            };
        }
        window.scrollTo(0, 0);
    }, [currentUrl]);

    const getUserInfo = async () => {
        try {
            // Get current user info
            const userInfoRes = await hnpApiUtils.getUserinfo();
            // Get the order details of the user immediately
            let ordersRes = await hnpApiUtils.getOrder({
                nowOrderType: hnpNowOrderType.hclSoftwareProduct,
                includeTrialOrders: false
            });
            let stakeholdersRes = {};
            // Update dependent state like `orderId`, `partNumber` and `envType`
            let dependentState = {};
            // Get `hcn` options
            const updatedHcnOptions = getHcnOptions(ordersRes.data);

            if (hnpPersData && hnpPersData.filters) {
                if (
                    hnpPersData.filters.hcn.length ||
                    hnpPersData.filters.orderId.length ||
                    hnpPersData.filters.partNumber.length ||
                    hnpPersData.filters.envType.length
                ) {
                    const orderParams = {
                        nowOrderType: hnpNowOrderType.hclSoftwareProduct,
                        includeTrialOrders: false,
                        ...(hnpPersData.filters.hcn.length && {
                            hcn: hnpPersData.filters.hcn
                                .map(val => val)
                                .join(',')
                        }),
                        ...(hnpPersData.filters.orderId.length && {
                            orderId: hnpPersData.filters.orderId[0]
                        }),
                        ...(hnpPersData.filters.partNumber.length && {
                            partNumber: hnpPersData.filters.partNumber[0]
                        }),
                        ...(hnpPersData.filters.envType.length && {
                            envType: hnpPersData.filters.envType[0]
                        })
                    };
                    ordersRes = await hnpApiUtils.getOrder({
                        $queryParameters: orderParams
                    });
                }
            }

            let updatedOrderIdOptions = getOrderIdOptions(ordersRes.data);
            let updatedPartNoOptions = getProductPartNoOptions(ordersRes.data);
            let updatedEnvTypeOptions = getEnvTypeOptions(ordersRes.data);

            // The filter values will be preselect for three scenarios
            // 1. If there is single provisoined environment
            const provisionedEnvs = updateEnvsWithProps(ordersRes.data);
            if (provisionedEnvs.length === 1) {
                updatedOrderIdOptions = updatedOrderIdOptions.filter(
                    getFilteredOptions(provisionedEnvs[0].orderId)
                );
                updatedPartNoOptions = updatedPartNoOptions.filter(
                    getFilteredOptions(provisionedEnvs[0].partNumber)
                );
                updatedEnvTypeOptions = updatedEnvTypeOptions.filter(
                    getFilteredOptions(
                        provisionedEnvs[0].provisionedEnvironment.type
                    )
                );
            }
            // 2. Filter values from hnp_pers cookie
            if (hnpPersData && hnpPersData.filters.hcn.length)
                dependentState[hcnName] = updatedHcnOptions.filter(
                    getFilteredOptions(hnpPersData.filters.hcn)
                );
            if (hnpPersData && hnpPersData.filters.notification.length)
                dependentState[notificationName] =
                    appStore.filters.options.notification.filter(
                        getFilteredOptions(hnpPersData.filters.notification)
                    );
            if (hnpPersData && hnpPersData.filters.role.length)
                dependentState[roleName] = appStore.filters.options.role.filter(
                    getFilteredOptions(hnpPersData.filters.role)
                );
            // 3. If there is single option for the filters
            // Pre-select the first option if the user is a customer or has only one hcn option
            if (updatedHcnOptions.length === 1)
                // Update `hcn` only when single option is present (to pre-select)
                dependentState[hcnName] = updatedHcnOptions;

            if (updatedOrderIdOptions.length === 1)
                // Update `orderId` only when single option is present (to pre-select)
                dependentState[orderIdName] = updatedOrderIdOptions;

            if (updatedPartNoOptions.length === 1)
                // Update `partNumber` only when single option is present (to pre-select)
                dependentState[partNumberName] = updatedPartNoOptions;

            if (updatedEnvTypeOptions.length === 1)
                // Update `envType` only when single option is present (to pre-select)
                dependentState[envTypeName] = updatedEnvTypeOptions;

            const filterKeys = [
                hcnName,
                partNumberName,
                notificationName,
                roleName
            ];

            // Build query parameter object
            const stakehParams = Object.keys(dependentState)
                .filter(key => filterKeys.includes(key))
                .reduce((obj, key) => {
                    if (dependentState[key].length) {
                        obj[key] = dependentState[key]
                            .map(option =>
                                key === hcnName ? option.value : option.data
                            )
                            .join(',');
                    }

                    return obj;
                }, {});

            // Get the stakeholders details
            stakeholdersRes = await hnpApiUtils.getStakeholder({
                $queryParameters: stakehParams
            });

            // Update the state
            setAppStore({
                ...appStore,
                isLogin: hnpLoginStatus.loggedIn,
                userDetails: flattenUserInfo(userInfoRes.data),
                orderDetails: ordersRes.data,
                filters: {
                    ...appStore.filters,
                    ...dependentState,
                    options: {
                        ...appStore.filters.options,
                        hcn: updatedHcnOptions,
                        orderId: updatedOrderIdOptions,
                        partNumber: updatedPartNoOptions,
                        envType: updatedEnvTypeOptions
                    }
                },
                provisionedEnvs: provisionedEnvs,
                stakeholdersDetails: addUiPropsInStakehData(
                    ordersRes.data,
                    stakeholdersRes.data
                )
            });
        } catch (err) {
            // Reset the global states
            if (err.data.message === 'Session timeout') {
                setAppStore(INITIAL_STATE);
            } else {
                setAppStore({ ...appStore, isLogin: hnpLoginStatus.loggedOut });
            }
        }
    };
    const showPogressBar = appStore.isAPISvcDown
        ? false
        : appStore.isLogin === hnpLoginStatus.initialState;

    return (
        <StyledBox className={showPogressBar ? classes.root : ''}>
            <HnpContext.Provider
                value={{
                    hnpFeatureFlags,
                    appStore,
                    setAppStore
                }}
            >
                {showPogressBar ? (
                    <StyledLinearProgress className={classes.progress} />
                ) : (
                    <Fragment>{props.children}</Fragment>
                )}
            </HnpContext.Provider>
        </StyledBox>
    );
};

HnpProvider.propTypes = {
    children: PropTypes.any
};

export { HnpContext, INITIAL_STATE, HnpProvider };
