/**
 * Base colors
 */
export const colors = {
    white: '#FFFFFF',
    white2: '#F7F7F7',
    white3: '#E2E7EE80',
    loginGrad1: '#00464C',
    loginGrad2: '#007A87',
    loginUnderline: '#E0E86A',
    turquoise1: '#037780',
    turquoise2: '#0CB0B0',
    turquoise3: '#47DECF',
    turquoise4: '#0CB0B0',
    turquoise5: '#047B88',
    turquoise6: '#047b88b3',
    turquoise7: '#004040',
    turquoise8: '#80B8BC',
    turquoise9: '#538D98',
    turquoise10: '#2ec0cb',
    turquoise11: '#006075',
    teal: '#1DB6C0',
    header: '#035F69',
    chip: '#017175',
    black: '#000000',
    black1: '#000000de',
    black2: '#00000026',
    black3: '#00000040',
    black4: '#363636',
    black5: '#212121',
    black6: '#262626',
    black7: '#2E3034',
    black8: '#2A2A2C',
    black9: '#2d2c2c',
    grey: '#CCCCCC',
    grey1: '#DDDDDD',
    grey2: '#8F8F8F',
    grey3: '#F1F5F9',
    grey4: '#ededed',
    grey5: '#474747',
    grey6: '#747474',
    grey7: '#f2f2f2',
    grey8: '#4A5159',
    grey9: '#979799',
    grey10: '#121212',
    grey11: '#5C5C5C',
    grey12: '#313237',
    grey13: '#F5F5F5',
    grey14: '#E6E6E6',
    blue: '#2468F6',
    blue1: '#4780FF',
    blue2: '#0780C7',
    blue3: '#058f9e',
    blue4: '#97ABF8',
    blue5: '#a7bff5c9',
    blue6: '#7986cb',
    purple1: '#5721E5',
    purple2: '#8D62E2',
    purple3: '#B7A1E1',
    aqua: '#36d6d9',
    blackTransparent: '#333333e8',
    alertRed: '#FFF2F2',
    error: '#C10C0D',
    support: '#0066B0'
};

/**
 * Custom element sizes; e.g.: Avatar
 * n=[1, 2, ..., infinity] where 1 = 8px
 */
export const sizes = {};

export const sxStyles = {
    mt: {
        marginTop: theme => theme.spacing(1)
    },
    mt2: {
        marginTop: theme => theme.spacing(2)
    },
    mt3: {
        marginTop: theme => theme.spacing(3)
    },
    mr: {
        marginRight: theme => theme.spacing(1)
    },
    mr2: {
        marginRight: theme => theme.spacing(2)
    },
    mb: {
        marginBottom: theme => theme.spacing(1)
    },
    mb2: {
        marginBottom: theme => theme.spacing(2)
    },
    mb3: {
        marginBottom: theme => theme.spacing(3)
    },
    mb4: {
        marginBottom: theme => theme.spacing(4)
    },
    ml2: {
        marginLeft: theme => theme.spacing(2)
    },
    italicText: {
        fontStyle: 'italic'
    },
    regularLineHeight: {
        lineHeight: 1.43
    },
    envDetailsCard: theme => ({
        height: theme.spacing(36.25),
        padding: theme.spacing(1, 1, 1.5, 1),
        position: 'relative',
        '& .MuiCardHeader-root': {
            height: theme.spacing(10.125),
            display: 'flex',
            alignItems: 'flex-start'
        },
        '& .MuiCardHeader-content': {
            overflow: 'hidden'
        },
        '& .MuiCardHeader-title': {
            fontSize: theme.spacing(2),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontWeight: 600
        },
        '& .MuiCardHeader-subheader .MuiTypography-body2:nth-of-type(2)': {
            marginTop: theme.spacing(-0.625)
        },
        '& .MuiAvatar-square': {
            width: theme.spacing(6.25),
            height: theme.spacing(6.25),
            marginTop: theme.spacing(-0.625)
        },
        '& .MuiCardContent-root': {
            height: theme.spacing(17.5)
        },
        '& .MuiCardActions-root': {
            justifyContent: 'flex-end',
            padding: theme.spacing(1, 1.75, 0.875, 1)
        },
        '& .MuiTypography-colorTextSecondary': {
            color: colors.grey6
        }
    }),
    appContainer: theme => ({
        width: '78%',
        [theme.breakpoints.between('xs', 'md')]: {
            width: '85%'
        },
        [theme.breakpoints.up('lg1')]: {
            width: '82%'
        },
        [theme.breakpoints.between('lg', 'lg1')]: {
            width: '90%'
        }
    }),
    verticalEllipsis: theme => ({
        display: '-webkit-box',
        maxHeight: theme.spacing(37.5),
        maxWidth: '100%',
        minHeight: theme.spacing(10),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical'
    }),
    verticalEllipsis1: theme => ({
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        marginTop: theme.spacing(-0.2),
        maxWidth: '90%',
        height: theme.spacing(9)
    }),
    verticalEllipsis6: theme => ({
        display: '-webkit-box',
        maxHeight: theme.spacing(37.5),
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 6,
        WebkitBoxOrient: 'vertical'
    }),
    inputEllipsis2: theme => ({
        display: 'inline-block',
        width: '95%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: theme.spacing(1.75)
    }),
    inputEllipsis3: theme => ({
        display: 'inline-block',
        width: theme.spacing(45),
        minHeight: theme.spacing(5.375),
        fontSize: theme.spacing(3.75),
        fontFamily: 'HCLTech Roobert',
        fontWeight: 400,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: colors.white,
        fontWeight: theme.typography.fontWeightRegular,
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(32.5),
            fontSize: theme.spacing(2.5),
            minHeight: theme.spacing(3.625)
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            fontSize: theme.spacing(3),
            minHeight: theme.spacing(4.375)
        }
    }),
    prodChip: {
        backgroundColor: colors.loginGrad2,
        fontSize: theme => theme.spacing(1.5),
        color: colors.white2
    },
    preProdChip: theme => ({
        backgroundColor: colors.grey2,
        fontSize: theme.spacing(1.5),
        color: colors.white2
    }),
    testChip: theme => ({
        backgroundColor: colors.blue2,
        fontSize: theme.spacing(1.5),
        color: colors.white2
    }),
    envIcon: theme => ({
        color: colors.white,
        height: theme.spacing(2),
        width: theme.spacing(2)
    }),
    noProductLogoBg: theme => ({
        backgroundColor: 'transparent',
        width: theme.spacing(6),
        height: theme.spacing(6)
    }),
    envStatusIcon: theme => ({
        fontSize: theme.spacing(3),
        color: theme.palette.success.main,
        '&.warning': {
            color: theme.palette.warning.main
        },
        '&.error': {
            color: theme.palette.error.main
        },
        '&.unknown': {
            color: colors.grey2
        }
    }),
    filterOptionText: theme => ({
        fontSize: '0.875rem',
        paddingTop: theme.spacing(0.25),
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical'
    }),
    horizontalEllipsis: {
        width: '99%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'inline-block'
    },
    dropDownMenu: theme => ({
        fontColor: colors.grey12,
        fontSize: theme.spacing(1.5),
        padding: theme.spacing(0.5, 1.5),
        opacity: 0.54
    }),
    tabFocus: theme => ({
        '&:focus-visible': {
            outline: `${theme.spacing(0.25)} solid ${colors.blue4}`,
            outlineOffset: theme.spacing(0.125),
            borderRadius: theme.spacing(0.125)
        }
    }),
    separator: theme => ({
        display: 'inline-block',
        margin: theme.spacing(0, 1),
        [theme.breakpoints.down('sm')]: {
            margin: 0
        }
    }),
    containedBtn: theme => ({
        borderRadius: theme => theme.spacing(0),
        backgroundColor: colors.loginGrad2,
        padding: theme.spacing(1, 2.5),
        color: colors.white,
        '&:hover': {
            backgroundColor: colors.turquoise1
        }
    }),
    textBtn: theme => ({
        textTransform: 'capitalize',
        color: colors.loginGrad2,
        paddingRight: theme.spacing(0.25),
        fontSize: theme.spacing(1.75)
    }),
    demoConfirmBtn: theme => ({
        border: `solid ${theme.spacing(0.25)} ${colors.turquoise1}`,
        borderRadius: theme.spacing(0.25),
        textTransform: 'none',
        color: colors.white,
        padding: theme.spacing(1.25, 2.5),
        background: colors.loginGrad2,
        fontSize: theme.spacing(1.75),
        fontWeight: 600,
        lineHeight: theme.spacing(2.5),
        '&:hover': {
            background: colors.turquoise11,
            borderColor: colors.turquoise11
        }
    }),
    demoCancelBtn: theme => ({
        border: `solid ${theme.spacing(0.25)} ${colors.loginGrad2}`,
        borderRadius: theme.spacing(0.25),
        textTransform: 'none',
        color: colors.loginGrad2,
        padding: theme.spacing(1.25, 2.5),
        fontSize: theme.spacing(1.75),
        fontWeight: 600,
        lineHeight: theme.spacing(2.5),
        '&:hover': {
            backgroundColor: colors.grey14,
            color: colors.turquoise11,
            borderColor: colors.turquoise11
        }
    }),
    loginBtn: theme => ({
        fontSize: '1rem',
        borderRadius: 0,
        backgroundColor: colors.loginGrad2,
        color: colors.white,
        padding: theme.spacing(0.75, 2),
        '&:hover': {
            background: colors.loginGrad2
        }
    })
};
