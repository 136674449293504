import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

const PREFIX = 'HnpDemoMsg';

const classes = {
    deprecationBox: `${PREFIX}-deprecationBox`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.deprecationBox}`]: {
        marginTop: theme.spacing(7)
    }
}));

const HnpDeprecation = () => {
    const theme = useTheme();

    return (
        <StyledBox className={classes.deprecationBox}>
            <Alert
                severity="error"
                sx={{
                    borderLeft: `${theme.spacing(1)} solid ${colors.error}`,
                    borderRadius: 0
                }}
                icon={<ErrorIcon sx= {{color: colors.error}}/>}
            >
                <Typography
                    sx={{
                        fontSize: theme.spacing(1.75),
                        fontWeight: 600,
                        color: colors.error
                    }}
                >
                    ANNOUNCEMENT!
                </Typography>
                <Typography
                    sx={{ fontSize: theme.spacing(1.75), color: colors.black }}
                >
                    The HCL Now App is being <strong>deprecated</strong> and
                    will <strong>not be accessible</strong> after 5 September,
                    2024.
                </Typography>
            </Alert>
        </StyledBox>
    );
};

export default HnpDeprecation;
