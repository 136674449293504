import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import Chip from '@mui/material/Chip';
import SecurityIcon from '@mui/icons-material/Security';
import { Tooltip } from '@mui/material';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

// Styled Paper
export const StyledPaper = styled(Paper)(({ theme }) => ({
    '&.MuiPaper-root': {
        borderRadius: theme.spacing(0)
    }
}));

// Export the customized card component
export const StyledCard = styled(Card)(({ theme }) => ({
    '&.MuiCard-root': {
        borderRadius: theme.spacing(0),
        '&:hover': {
            boxShadow: `0 3px 0 0 ${colors.turquoise2}`,
            transform: 'scale(1.01)',
            transition: 'transform 0.35s'
        },
        '& .MuiCardHeader-title': {
            color: colors.black4,
            fontSize: theme.spacing(2),
            fontWeight: 600
        },
        '& .MuiCardHeader-subheader': {
            fontSize: theme.spacing(1.5),
            color: colors.grey12
        }
    }
}));

// Styled linear progress
export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700]
    },
    '& .MuiLinearProgress-bar': {
        backgroundColor: colors.turquoise1
    }
}));

// Styled paper for filter dropdown
const StyledFilterPaper = styled(Paper)(({ theme }) => ({
    '&.MuiAutocomplete-paper': {
        backgroundColor: colors.black7,
        borderRadius: 0,
        padding: 0,
        '& .MuiAutocomplete-noOptions': {
            color: colors.white2
        },
        '& .MuiAutocomplete-option': {
            color: colors.white,
            backgroundColor: colors.black6,
            padding: theme.spacing(0.5, 2),
            // Hover
            '&:hover': {
                backgroundColor: colors.black9
            },
            // Selected
            '&[aria-selected="true"]': {
                borderColor: colors.white,
                backgroundColor: colors.black4,
                '&.Mui-focused': {
                    borderColor: colors.white,
                    backgroundColor: colors.black4
                }
            }
        },
        '& .MuiAutocomplete-listbox': {
            padding: 0,
            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                backgroundColor: colors.black6
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: colors.black8,
                border: `${theme.spacing(0.15)} solid ${colors.turquoise9}`,
                borderRadius: theme.spacing(0.5)
            }
        }
    }
}));

const FilterPaper = ({ children, ...paperProps }) => (
    <StyledFilterPaper {...paperProps}>{children}</StyledFilterPaper>
);
FilterPaper.propTypes = {
    children: PropTypes.any
};

// Styled autocomplete
const MuiAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '&.MuiAutocomplete-root': {
        backgroundColor: colors.black7,
        '& .MuiOutlinedInput-root': {
            color: colors.white,
            borderRadius: 0,
            padding: theme.spacing(1.25, 8.125, 0.75, 1.75),
            flexWrap: 'nowrap',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colors.grey8
            },
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.white3,
                    borderWidth: theme.spacing(0.125)
                }
            }
        },
        '& .MuiAutocomplete-inputRoot': {
            '& .MuiAutocomplete-input': {
                minWidth: theme.spacing(0)
            }
        },
        '& .MuiInputLabel-root': {
            color: colors.white,
            fontSize: theme.spacing(1.75),
            top: theme.spacing(0.25),
            '&.Mui-focused': {
                color: colors.white,
                fontWeight: 500,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.white,
                    borderWidth: theme.spacing(0.125)
                }
            },
            '&.Mui-disabled': {
                color: colors.grey9
            }
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontWeight: 600
        }
    },
    '& .MuiAutocomplete-inputRoot': {
        '&.Mui-disabled': {
            color: colors.grey9
        }
    },
    '& .MuiAutocomplete-popupIndicator': {
        color: colors.grey,
        '&.Mui-disabled': {
            color: colors.grey9
        }
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: colors.grey
    }
}));
// Export the customized autocomplete
export const StyledAutocomplete = props => {
    return <MuiAutocomplete {...props} PaperComponent={FilterPaper} />;
};

// Styled button
const StyledButton = styled(Button)(({ theme }) => ({
    '&.MuiButtonBase-root': {
        fontWeight: 600,
        '&:focus-visible': {
            outline: `${theme.spacing(0.25)} solid ${colors.blue4}`,
            outlineOffset: theme.spacing(0.125),
            borderRadius: theme.spacing(0.125)
        }
    }
}));
// Export the customized button component
export const HnpButton = props => (
    <StyledButton disableFocusRipple {...props} />
);

// Export custom styled switch
export const StyledSwitch = styled(Switch)(({ theme }) => ({
    '&.MuiSwitch-root': {
        width: 32,
        height: 16,
        padding: 0,
        margin: 13,
        display: 'flex'
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        color: theme.palette.grey[500],
        '&.Mui-checked': {
            transform: 'translateX(17px)',
            color: theme.palette.common.white
        },
        '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: `${colors.turquoise1}`,
            borderColor: `${colors.turquoise1}`,
            [theme.breakpoints.down('md')]: {
                backgroundColor: colors.teal,
                borderColor: colors.teal
            }
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: '#000',
            [theme.breakpoints.down('md')]: {
                backgroundColor: colors.white
            }
        }
    },
    '& .MuiSwitch-switchBase.Mui-disabled': {
        color: '#bdbdbd'
    },
    '& .MuiSwitch-thumb': {
        width: 12,
        height: 12,
        boxShadow: 'none'
    },
    '& .MuiSwitch-track': {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white
    }
}));

// Export custom styled snackbar
export const StyledSnackBar = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: colors.blackTransparent,
        color: colors.white2,
        fontWeight: 500,
        minWidth: theme.spacing(32.75),
        maxWidth: theme.spacing(32.75)
    }
}));

export const StyledChip = styled(Chip, {
    shouldForwardProp: prop => prop !== 'bgcolor'
})(({ bgcolor, theme }) => ({
    backgroundColor: bgcolor,
    fontSize: theme.spacing(1.75),
    color: colors.white2
}));

export const StyledEnvIcon = styled(SecurityIcon)(({ theme }) => ({
    '&.MuiSvgIcon-root.MuiChip-icon': {
        color: colors.white,
        height: theme.spacing(2),
        width: theme.spacing(2)
    }
}));

// Styled tooltip component with style customization.
export const StyledTooltip = styled(({ styles, className, ...props }) => (
    <Tooltip
        {...props}
        slotProps={{
            tooltip: {
                style: styles
            }
        }}
        classes={{ popper: className }}
    />
))(() => ({}));
