import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { DatasetComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { SVGRenderer } from 'echarts/renderers';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { HnpContext } from '../../contexts/HnpContext';

// Register the required components
echarts.use([DatasetComponent, GridComponent, BarChart, SVGRenderer]);

const option = {
    grid: {
        containLabel: true,
        left: 0,
        top: 0,
        right: 5,
        bottom: 0
    },
    xAxis: {
        type: 'value',
        show: false,
        boundaryGap: [0, 0.01]
    },
    yAxis: {
        type: 'category',
        show: true,
        data: [null, null, null],
        position: 'right',
        splitLine: {
            show: false
        },
        axisLabel: {
            color: colors.black4,
            fontSize: 14,
            fontFamily: 'inherit',
            fontWeight: '400',
            position: 'inside'
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        }
    },
    series: [
        {
            type: 'bar',
            barGap: '10%',
            showBackground: true,
            silent: true,
            barWidth: '20%',
            barMinHeight: 25,
            backgroundStyle: {
                color: colors.grey4,
                borderRadius: 8
            },
            itemStyle: {
                borderRadius: 8
            },
            label: {
                show: true,
                position: [0, -15],
                formatter: '{b}',
                fontSize: 14,
                left: 0,
                fontFamily: 'inherit',
                align: 'left',
                padding: 0,
                distance: 6,
                color: colors.black,
                opacity: 0.87
            },
            data: [
                {
                    value: null,
                    itemStyle: {
                        color: colors.black4
                    },
                    name: 'In Progress'
                },
                {
                    value: null,
                    itemStyle: {
                        color: colors.turquoise2
                    },
                    name: 'Closed'
                },
                {
                    value: null,
                    itemStyle: {
                        color: colors.grey2
                    },
                    name: 'Opened'
                }
            ]
        }
    ]
};

const HnpCasesChart = props => {
    const { appStore } = useContext(HnpContext);
    const { caseMetrics } = appStore || {};
    const [caseChartOption, setCaseChartOption] = useState(option);

    // style object for react echart core, the styles cannot apply using classname
    const caseStyleOption = {
        height: '163px'
    };

    useEffect(() => {
        if (caseMetrics) {
            setCaseChartOption({
                ...caseChartOption,
                yAxis: {
                    ...caseChartOption.yAxis,
                    data: [
                        caseMetrics.open,
                        caseMetrics.closed,
                        caseMetrics.total
                    ]
                },
                series: {
                    ...caseChartOption.series,
                    data: [
                        {
                            value: caseMetrics.open,
                            itemStyle: {
                                color: colors.black4
                            },
                            name: 'In Progress'
                        },
                        {
                            value: caseMetrics.closed,
                            itemStyle: {
                                color: colors.turquoise2
                            },
                            name: 'Closed'
                        },
                        {
                            value: caseMetrics.total,
                            itemStyle: {
                                color: colors.grey2
                            },
                            name: 'Opened'
                        }
                    ]
                }
            });
        }
    }, [caseMetrics]);

    return (
        // props attirbute for echart init configuration from unit tests only
        <ReactEChartsCore
            echarts={echarts}
            option={caseChartOption}
            style={caseStyleOption}
            {...(props.opts && props)}
        />
    );
};

HnpCasesChart.propTypes = {
    opts: PropTypes.any
};

export default HnpCasesChart;
