import React, { Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DialogContent } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { HnpContext } from '../../contexts/HnpContext';
import { HnpButton, StyledTooltip, StyledSwitch } from '../HnpMuiComponents';
import HnpDialog from '../HnpDialog';
import {
    colors,
    sxStyles
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import {
    getCookieValue,
    setCookieValue
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { hnpDemoModeAlerts } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import { ReactComponent as InfoIcon } from '../../assets/images/info-icon.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/cancel-icon.svg';

const PREFIX = 'HnpDemo';

const classes = {
    switch: `${PREFIX}-switch`,
    container: `${PREFIX}-container`,
    switchLabel: `${PREFIX}-switchLabel`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        padding: theme.spacing(0.25, 2),
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
    },
    [`& .${classes.switch}`]: {
        '&.MuiFormControlLabel-root': {
            [theme.breakpoints.down('md')]: {
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
            }
        },
        '& .MuiSwitch-root': {
            marginLeft: theme.spacing(1.375)
        },
        '&.Mui-disabled': {
            '& .MuiFormControlLabel-label': {
                color: colors.black,
                opacity: 0.87
            }
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            '& .MuiTypography-root.MuiTypography-body2': {
                paddingRight: theme.spacing(10)
            }
        }
    },
    [`& .${classes.switchLabel}`]: {
        marginLeft: theme.spacing(-0.5),
        fontSize: theme.spacing(1.75)
    }
}));
const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    height: theme.spacing(4),
    width: theme.spacing(4),
    position: 'relative',
    top: theme.spacing(1.125),
    marginRight: theme.spacing(1.75),
    color: colors.loginGrad2
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
    height: theme.spacing(6),
    width: theme.spacing(6),
    position: 'relative',
    top: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    padding: theme.spacing(1.25),
    color: colors.grey5,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: colors.grey14
    }
}));

const HnpDemo = ({ closeUserTray }) => {
    const theme = useTheme();
    const tooltipStyle = {
        marginTop: theme.spacing(0),
        textAlign: 'center'
    };
    const ttpOnNoCusSelect = {
        ...tooltipStyle,
        width: theme.spacing(15.5),
        left: theme.spacing(-8)
    };
    const ttpOnCusSelect = {
        ...tooltipStyle,
        width: theme.spacing(11),
        left: theme.spacing(-8.5)
    };
    const isScreenSizeMedium = useMediaQuery(theme.breakpoints.up('md'));
    const { appStore, setAppStore } = useContext(HnpContext);
    const { filters, isDemoMode } = appStore;
    const hnpPersData = getCookieValue('hnp_pers');
    const [checked, setChecked] = useState(
        (hnpPersData && hnpPersData.isDemoMode) || false
    );
    const [dialog, setDialog] = useState(false);

    useEffect(() => {
        if (isDemoMode !== null) {
            setCookieValue('hnp_pers', {
                isDemoMode: isDemoMode
            });
        }
    }, [isDemoMode]);

    const handleChange = () => {
        if (!checked) {
            closeUserTray(false);
            setChecked(true);
            setAppStore(prevState => ({
                ...prevState,
                isDemoMode: true
            }));
        } else {
            setDialog(true);
        }
    };

    const handleClose = () => {
        setDialog(!dialog);
    };

    const handleDemoOff = () => {
        closeUserTray(false);
        setChecked(!checked);
        setDialog(!dialog);
        setAppStore(prevState => ({
            ...prevState,
            isDemoMode: !isDemoMode
        }));
    };

    const getTooltipTitle = () => {
        if (filters.hcn.length !== 1) {
            return hnpDemoModeAlerts.demoModeDisable;
        } else {
            if (isDemoMode) {
                return hnpDemoModeAlerts.turnOffDemo;
            } else {
                return hnpDemoModeAlerts.turnOnDemo;
            }
        }
    };

    const ActionsButtons = () => {
        const matches = useMediaQuery(theme.breakpoints.down('sm'));

        return (
            <DialogContent
                style={{
                    backgroundColor: colors.grey7,
                    padding: matches
                        ? theme.spacing(2.5, 2)
                        : theme.spacing(2.5, 6.5)
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <HnpButton
                        sx={sxStyles.demoCancelBtn}
                        onClick={handleClose}
                        style={{ marginRight: theme.spacing(2.25) }}
                    >
                        Cancel
                    </HnpButton>
                    <HnpButton
                        sx={sxStyles.demoConfirmBtn}
                        onClick={handleDemoOff}
                    >
                        Turn off
                    </HnpButton>
                </div>
            </DialogContent>
        );
    };

    const DialogBoxContent = () => (
        <Fragment>{hnpDemoModeAlerts.turnOffFilter}</Fragment>
    );

    const DialogBoxTitle = () => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <div>
                <StyledInfoIcon />
                {hnpDemoModeAlerts.dialogBoxTitle}
            </div>
            <StyledCancelIcon onClick={handleClose} />
        </div>
    );

    return (
        <StyledBox className={classes.container}>
            <StyledTooltip
                title={getTooltipTitle()}
                styles={
                    filters.hcn.length !== 1 ? ttpOnNoCusSelect : ttpOnCusSelect
                }
                placement="bottom-end"
                arrow
            >
                <FormGroup row>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <StyledSwitch
                                checked={checked}
                                onChange={handleChange}
                                name="checked"
                                disabled={
                                    filters.hcn.length !== 1 && !isDemoMode
                                }
                                sx={{
                                    marginRight: theme.spacing(0.5),
                                    marginLeft: theme.spacing(1)
                                }}
                            />
                        }
                        label={
                            isScreenSizeMedium ? (
                                <span className={classes.switchLabel}>
                                    Demo mode
                                </span>
                            ) : (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: colors.grey7,
                                        fontWeight: 600
                                    }}
                                >
                                    Demo mode
                                </Typography>
                            )
                        }
                        labelPlacement={isScreenSizeMedium ? 'end' : 'start'}
                    />
                </FormGroup>
            </StyledTooltip>
            <HnpDialog
                dialogOn={dialog}
                actions={<ActionsButtons />}
                content={<DialogBoxContent />}
                title={<DialogBoxTitle />}
            />
        </StyledBox>
    );
};

HnpDemo.propTypes = {
    closeUserTray: PropTypes.func
};

export default HnpDemo;
