import React, { Fragment, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import { sxStyles } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { StyledCard, HnpButton } from '../HnpMuiComponents';
import { HnpContext } from '../../contexts/HnpContext';
import { useIsMount } from '../../customHooks/customHooks';
import {
    hnpFilters,
    hnpNoStakehMsgTile,
    hnpRoutes
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import { addUiPropsInStakehData } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import Stakeholders from '../../assets/images/stakeholders.png';

const PREFIX = 'HnpStakeholderCard';

const classes = {
    contact: `${PREFIX}-contact`,
    graphic: `${PREFIX}-graphic`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.contact}`]: {
        '& .MuiCardHeader-avatar': {
            margin: theme.spacing(0, 1.5, 0, -0.625)
        },
        '& .MuiSvgIcon-root': {
            display: 'flex',
            fontSize: theme.spacing(6.125),
            marginTop: theme.spacing(-0.5)
        },
        '& .MuiCardContent-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        '& .MuiCardContent-root .MuiTypography-root': {
            width: '65%'
        },
        '& .MuiButtonBase-root': {
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
            textTransform: 'none'
        }
    },
    [`& .${classes.graphic}`]: {
        backgroundImage: `url(${Stakeholders})`,
        backgroundSize: '30%',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '90%',
        backgroundPositionY: '65%',
        [theme.breakpoints.down('sm')]: {
            backgroundSize: '25%'
        }
    }
}));

const HnpStakeholderCard = () => {
    const navigate = useNavigate();
    const { appStore, setAppStore } = useContext(HnpContext);
    const { filters, orderDetails, hnpApiUtils, stakeholdersDetails } =
        appStore;
    // Filter names
    const { hcnName, partNumberName } = hnpFilters;
    const isMount = useIsMount();

    const handleStakeholdersClick = () => {
        navigate(hnpRoutes.stakeHolderPage);
    };

    const getStakeHolderDetails = async () => {
        const filterKeys = [hcnName, partNumberName];
        // Filter the stakeholders details if the user is on stakeholders page
        const stakehStates = {};
        // Build query parameter object
        const stakehParams = Object.keys(filters)
            .filter(key => filterKeys.includes(key))
            .reduce((obj, key) => {
                if (filters[key].length) {
                    obj[key] = filters[key]
                        .map(option =>
                            key === hcnName ? option.value : option.data
                        )
                        .join(',');
                }

                return obj;
            }, {});

        // Get filtered stakeholders details
        const stakehRes = await hnpApiUtils.getStakeholder({
            $queryParameters: stakehParams
        });

        stakehStates.stakeholdersDetails = addUiPropsInStakehData(
            orderDetails,
            stakehRes.data
        );

        setAppStore(prevState => ({
            ...prevState,
            ...stakehStates
        }));
    };

    useEffect(() => {
        getStakeHolderDetails();
    }, []);

    useEffect(() => {
        // isMount will be true for the first render. consuming the stakeholder api for filter changes.
        if (!isMount) {
            getStakeHolderDetails();
        }
    }, [orderDetails]);

    return (
        <StyledGrid xs={12} sm={6} lg={3}>
            <StyledCard
                className={classes.contact}
                sx={sxStyles.envDetailsCard}
            >
                <CardHeader
                    avatar={<AccountBoxIcon />}
                    title="Stakeholders"
                    subheader={
                        <Box
                            sx={[
                                sxStyles.horizontalEllipsis,
                                sxStyles.regularLineHeight
                            ]}
                        >
                            {stakeholdersDetails.length ? (
                                <Fragment>
                                    Last updated on:{' '}
                                    <Moment
                                        format="MMM DD, YYYY"
                                        date={
                                            stakeholdersDetails[0]
                                                .lastUpdatedTime
                                        }
                                    />
                                </Fragment>
                            ) : null}
                        </Box>
                    }
                />
                <CardContent className={classes.graphic}>
                    {stakeholdersDetails.length ? (
                        <Fragment>
                            <Typography
                                variant="body2"
                                sx={sxStyles.verticalEllipsis6}
                            >
                                View your stakeholder details and contact them
                                based on notification type
                            </Typography>
                        </Fragment>
                    ) : (
                        <Typography
                            variant="body2"
                            sx={[
                                sxStyles.verticalEllipsis,
                                sxStyles.italicText
                            ]}
                        >
                            {hnpNoStakehMsgTile}
                        </Typography>
                    )}
                </CardContent>
                <Divider sx={[sxStyles.ml2, sxStyles.mr2]} />
                <CardActions>
                    <HnpButton
                        size="small"
                        sx={sxStyles.textBtn}
                        onClick={handleStakeholdersClick}
                        disabled={!stakeholdersDetails.length}
                    >
                        View Details
                    </HnpButton>
                </CardActions>
            </StyledCard>
        </StyledGrid>
    );
};

export default HnpStakeholderCard;
