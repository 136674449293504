import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { HnpContext } from '../../contexts/HnpContext';

const PREFIX = 'HnpStakeholderMobile';

const classes = {
    root: `${PREFIX}-root`,
    customerName: `${PREFIX}-customerName`,
    companyName: `${PREFIX}-companyName`,
    details: `${PREFIX}-details`,
    collapseBox: `${PREFIX}-collapseBox`,
    boldDetails: `${PREFIX}-boldDetails`,
    collapseIcon: `${PREFIX}-collapseIcon`
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    [`& .${classes.root}`]: {
        backgroundColor: colors.grey4
    },
    [`& .${classes.customerName}`]: {
        fontWeight: 500,
        fontSize: '0.85rem'
    },
    [`& .${classes.companyName}`]: {
        fontSize: '0.85rem',
        color: colors.grey2
    },
    [`& .${classes.details}`]: {
        fontSize: '0.85rem',
        color: 'rgba(0,0,0,.87)'
    },
    [`& .${classes.collapseBox}`]: {
        padding: theme.spacing(1, 2, 1, 2)
    },
    [`& .${classes.boldDetails}`]: {
        fontWeight: 500
    },
    [`& .${classes.collapseIcon}`]: {
        color: colors.turquoise1
    }
}));

const heading = ['Name/Company', 'Product', ''];

const StakeholderRow = props => {
    const { index, user, productName, company, notification } = props;
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow className={index % 2 === 0 ? classes.root : ''}>
                {heading.map((col, i) => (
                    <TableCell key={col} align="left" colSpan={1}>
                        {i === 0 ? (
                            <Box>
                                <Box className={classes.customerName}>
                                    {user.name}
                                </Box>
                                <Box className={classes.companyName}>
                                    {company}
                                </Box>
                            </Box>
                        ) : i === 1 ? (
                            productName
                        ) : (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon
                                        className={classes.collapseIcon}
                                    />
                                ) : (
                                    <KeyboardArrowDownIcon
                                        className={classes.collapseIcon}
                                    />
                                )}
                            </IconButton>
                        )}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={3}>
                    <Collapse in={open} unmountOnExit>
                        <Box
                            className={`${
                                index % 2 === 0 ? classes.root : ''
                            } ${classes.collapseBox}`}
                        >
                            <Typography
                                variant="body2"
                                className={classes.details}
                            >
                                Notification Type:{' '}
                                <Box
                                    component="span"
                                    className={classes.boldDetails}
                                >
                                    {Object.keys(notification)
                                        .map(key => {
                                            const keyStr = key.replace(
                                                'app',
                                                ''
                                            );

                                            return `${keyStr[0].toUpperCase()}${keyStr.slice(
                                                1
                                            )}`;
                                        })
                                        .join(', ')}
                                </Box>
                            </Typography>
                            <Typography
                                variant="body2"
                                className={classes.details}
                            >
                                Title:{' '}
                                <Box
                                    component="span"
                                    className={classes.boldDetails}
                                >
                                    {user.jobTitle}
                                </Box>
                            </Typography>
                            <Typography
                                variant="body2"
                                className={classes.details}
                            >
                                Escalation:{' '}
                                <Box
                                    component="span"
                                    className={classes.boldDetails}
                                >
                                    {notification.critSits
                                        ? notification.critSits
                                              .escalationPriority
                                        : null}
                                </Box>
                            </Typography>
                            <Typography
                                variant="body2"
                                className={classes.details}
                            >
                                Email:{' '}
                                <Box
                                    component="span"
                                    className={classes.boldDetails}
                                >
                                    {user.email}
                                </Box>
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

StakeholderRow.propTypes = {
    index: PropTypes.number,
    user: PropTypes.object,
    productName: PropTypes.string,
    company: PropTypes.string,
    notification: PropTypes.object
};

const HnpStakeholderMobile = () => {
    const { appStore } = useContext(HnpContext);
    const { stakeholdersDetails } = appStore || {};

    return stakeholdersDetails ? (
        <StyledTableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {heading.map((col, i) => (
                            <TableCell key={col} align="left" colSpan={1}>
                                {i === 0 ? (
                                    <Box component="span">{col}</Box>
                                ) : i === 1 ? (
                                    <Box component="span">{col}</Box>
                                ) : null}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stakeholdersDetails.map(
                        (
                            { _id, user, productName, company, notification },
                            index
                        ) => (
                            <StakeholderRow
                                index={index}
                                key={_id}
                                user={user}
                                productName={productName}
                                company={company}
                                notification={notification}
                            />
                        )
                    )}
                </TableBody>
            </Table>
        </StyledTableContainer>
    ) : null;
};

export default HnpStakeholderMobile;
