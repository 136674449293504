import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import {
    hnpLoginStatus,
    hnpDurations
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import {
    setCookieValue,
    getLabel
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { HnpContext } from '../../contexts/HnpContext';

const StyledSelect = styled(Select)(({ theme }) => ({
    padding: theme.spacing(0, 1, 0, 0),

    '& .MuiInputBase-input': {
        padding: theme.spacing(0, 2, 0.375, 0),
        fontSize: theme.spacing(1.5)
    },
    '& .MuiSelect-select': {
        background: 'none',
        color: colors.grey12,
        width: theme.spacing(10)
    },
    '& .MuiSelect-select:focus': {
        background: 'none'
    },
    '& .MuiSelect-icon': {
        color: colors.grey2,
        top: theme.spacing(0.5)
    }
}));

let HnpSelect = () => {
    const theme = useTheme();
    const { appStore, setAppStore } = useContext(HnpContext);
    const { isLogin, duration } = appStore || {};

    const handleChange = event => {
        setAppStore(prevState => ({
            ...prevState,
            duration: {
                value: event.target.value,
                label: getLabel(hnpDurations, event.target.value)
            }
        }));
        // set the duration value in cookie
        setCookieValue('hnp_pers', {
            duration: event.target.value
        });
    };

    return isLogin === hnpLoginStatus.loggedIn ? (
        <Box>
            <FormControl variant="standard">
                <StyledSelect
                    variant="standard"
                    value={duration ? duration.value : ''}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: 0
                            },
                            style: {
                                minWidth: theme.spacing(13.125)
                            }
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        }
                    }}
                    onChange={handleChange}
                    disableUnderline
                >
                    {Object.entries(hnpDurations).map(([key, value]) => {
                        return (
                            <MenuItem
                                key={key}
                                value={value.value}
                                sx={sxStyles.dropDownMenu}
                            >
                                {value.label}
                            </MenuItem>
                        );
                    })}
                </StyledSelect>
            </FormControl>
        </Box>
    ) : null;
};

export default HnpSelect;
