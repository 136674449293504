import React, { useContext, Fragment, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {
    StyledCard,
    HnpButton,
    StyledSnackBar,
    StyledEnvIcon,
    StyledChip
} from '../HnpMuiComponents';
import { HnpContext } from '../../contexts/HnpContext';
import HnpFilters from '../HnpFilters';
import HnpMessage from '../HnpMessage';
import {
    colors,
    sxStyles
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import {
    getDisplayName,
    safeRedirect,
    getEnvById,
    getFirstEnvIdRule,
    getProductNameByCommaRule,
    getEnvStatus,
    getPriorityAlertData,
    getMetricsUrl,
    getEnvChipColor
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import {
    hnpNoMetricsMsg,
    hnpEnvironments,
    hnpFilters,
    hnpLoginStatus,
    hnpMetricsAlert,
    hnpRoutes
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import { useIsMount } from '../../customHooks/customHooks';
import HnpTitleTooltip from '../HnpTitleTooltip';
import HnpPriorityAlert from '../HnpPriorityAlert';
import HnpEnvStatusIcon from '../common/HnpEnvStatusIcon';
import SofyTile from '../../assets/images/sofy-tile.png';
import ProductMetrics from '../../assets/images/product-metrics-tile.png';
import InfraMetrics from '../../assets/images/infra-metrics-tile.png';
import ServiceMetrics from '../../assets/images/service-metrics-tile.png';
import BusinessMetrics from '../../assets/images/business-metrics-tile.png';

const PREFIX = 'HnpMetrics';

const classes = {
    container: `${PREFIX}-container`,
    productNameSection: `${PREFIX}-productNameSection`,
    productNameChip: `${PREFIX}-productNameChip`,
    envChip: `${PREFIX}-envChip`,
    productName: `${PREFIX}-productName`,
    content: `${PREFIX}-content`,
    contentDetails: `${PREFIX}-contentDetails`,
    card: `${PREFIX}-card`,
    header: `${PREFIX}-header`,
    cardActions: `${PREFIX}-cardActions`,
    detailsBtn: `${PREFIX}-detailsBtn`,
    prodBtn: `${PREFIX}-prodBtn`,
    productDetailsCard: `${PREFIX}-productDetailsCard`,
    productDetailsCardHeader: `${PREFIX}-productDetailsCardHeader`,
    bgTiles: `${PREFIX}-bgTiles`,
    bgSofyTile: `${PREFIX}-bgSofyTile`,
    bgServiceTile: `${PREFIX}-bgServiceTile`,
    bgProductTile: `${PREFIX}-bgProductTile`,
    bgBusinessTile: `${PREFIX}-bgBusinessTile`,
    bgInfraTile: `${PREFIX}-bgInfraTile`,
    customerNameBox: `${PREFIX}-customerNameBox`,
    customerName: `${PREFIX}-customerName`,
    priorityContainer: `${PREFIX}-priorityContainer`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2.5)
    },
    [`& .${classes.productNameSection}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(3)
    },
    [`& .${classes.productNameChip}`]: {
        display: 'flex',
        alignItems: 'center',
        color: colors.black,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        }
    },
    [`& .${classes.envChip}`]: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0.5)
        }
    },
    [`& .${classes.productName}`]: {
        fontSize: theme.spacing(2.25),
        marginRight: theme.typography.pxToRem(16)
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        width: '100%',
        marginTop: theme.spacing(3)
    },
    [`& .${classes.contentDetails}`]: {
        display: 'flex',
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        height: theme.spacing(36.25),
        position: 'relative',
        '& .MuiCardHeader-action': {
            marginTop: theme.spacing(0.5),
            marginRight: theme.spacing(0)
        },
        '& .MuiTypography-colorTextSecondary': {
            color: colors.grey6
        },
        '& .MuiCardHeader-root': {
            height: theme.spacing(9.5),
            alignItems: 'flex-start'
        },
        '& .MuiCardContent-root': {
            height: theme.spacing(15.75)
        }
    },
    [`& .${classes.header}`]: {
        '& .MuiCardHeader-subheader': {
            paddingTop: theme.spacing(0.75)
        }
    },
    [`& .${classes.cardActions}`]: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1, 1.5, 0.75),
        marginTop: 'auto'
    },
    [`& .${classes.detailsBtn}`]: {
        textTransform: 'none',
        margin: theme.spacing(0.75, 0.5, 0, 'auto')
    },
    [`& .${classes.prodBtn}`]: {
        textTransform: 'none',
        margin: theme.spacing(0, 0.5, 0, 'auto')
    },
    [`& .${classes.productDetailsCard}`]: {
        paddingBottom: theme.spacing(2.25)
    },
    [`& .${classes.bgTiles}`]: {
        backgroundSize: theme.spacing(18.75),
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: 'top',
        borderRight: '2px solid #FFFFFF',
        borderTop: '2px solid #FFFFFF',
        '& .MuiCardContent-root .MuiTypography-body2': {
            width: '70%'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            '& .MuiCardHeader-title': {
                maxWidth: '60%',
                display: 'flex',
                flexWrap: 'wrap'
            }
        },
        [theme.breakpoints.down('md')]: {
            backgroundSize: theme.spacing(20),
            '& .MuiCardHeader-title': {
                height: theme.spacing(3)
            }
        },
        [theme.breakpoints.down('sm')]: {
            backgroundSize: theme.spacing(16.25)
        }
    },
    [`& .${classes.bgSofyTile}`]: {
        backgroundImage: `url(${SofyTile})`
    },
    [`& .${classes.bgServiceTile}`]: {
        backgroundImage: `url(${ServiceMetrics})`
    },
    [`& .${classes.bgProductTile}`]: {
        backgroundImage: `url(${ProductMetrics})`
    },
    [`& .${classes.bgBusinessTile}`]: {
        backgroundImage: `url(${BusinessMetrics})`
    },
    [`& .${classes.bgInfraTile}`]: {
        backgroundImage: `url(${InfraMetrics})`
    },
    [`& .${classes.customerNameBox}`]: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1.5),
        [theme.breakpoints.between('sm', 'lg')]: {
            margin: theme.spacing(-0.5, 0, 1, 0),
            minHeight: theme.spacing(4)
        }
    },
    [`& .${classes.productDetailsCardHeader}`]: {
        '&.MuiCardHeader-root': {
            paddingBottom: theme.spacing(1.25)
        },
        '& .MuiCardHeader-title': {
            width: '90%',
            height: theme.spacing(3.5)
        },
        '& .MuiTypography-root': {
            width: '90%'
        },
        '& .MuiCardHeader-content': {
            width: '90%'
        },
        [theme.breakpoints.down('md')]: {
            '& .MuiCardHeader-title': {
                width: '80%'
            },
            '& .MuiTypography-root': {
                width: '80%'
            },
            '& .MuiCardHeader-content': {
                width: '60%'
            }
        }
    },
    [`& .${classes.priorityContainer}`]: {
        marginBottom: theme.spacing(2)
    }
}));

const HnpMetrics = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { appStore, setAppStore } = useContext(HnpContext);
    const {
        isLogin,
        provisionedEnvs,
        priorityAlert,
        filters,
        selectedOption,
        hnpApiUtils,
        stakeholdersDetails,
        userDetails
    } = appStore || {};
    const [selectedEnv, setSelectedEnv] = useState({});
    const [updateFilters, setUpdateFilters] = useState(false);
    const [envState, setEnvState] = useState(null);
    const [showFilterAlert, setShowFilterAlert] = useState(false);
    const isScreenDesktopSize = useMediaQuery(theme.breakpoints.up('md'));
    const { provisionedEnvironment } = selectedEnv || {};
    const isMount = useIsMount();
    const { solId } = useParams();

    // Pre populating the filter values for selected environment.
    const prePopulateFilters = getSelectedEnv => {
        const dependentState = {};
        const { hcn, orderId, partNumber, envType } = filters.options;
        // Filter names
        const { hcnName, orderIdName, partNumberName, envTypeName } =
            hnpFilters;

        dependentState[hcnName] = hcn.filter(
            obj => obj.value === getSelectedEnv.customer.hcn
        );

        const selectedOrder = orderId.filter(
            obj => obj.value === getSelectedEnv.orderId
        );
        dependentState[orderIdName] = selectedOrder;

        const selectedProduct = partNumber.filter(
            obj => obj.data === getSelectedEnv.partNumber
        );
        dependentState[partNumberName] = selectedProduct;

        const selectedEnvType = envType.filter(
            obj => obj.data === getSelectedEnv.provisionedEnvironment.type
        );
        dependentState[envTypeName] = selectedEnvType;

        const filteredStakeholdersDetails =
            stakeholdersDetails &&
            stakeholdersDetails.filter(
                obj =>
                    obj.hcn === getSelectedEnv.customer.hcn &&
                    obj.partNumber === getSelectedEnv.partNumber
            );

        const [filteredOrderDetails] = appStore.orderDetails.filter(
            obj => obj.customerAccount.hcn === getSelectedEnv.customer.hcn
        );
        filteredOrderDetails.orders = filteredOrderDetails.orders.filter(
            order => order.orderId === getSelectedEnv.orderId
        );

        const [order] = filteredOrderDetails.orders;
        order.lineItems = order.lineItems.filter(
            lineItem => lineItem.partNumber === getSelectedEnv.partNumber
        );

        const filterLengthCheck = () => {
            return (
                filters.hcn.length === 1 &&
                filters.orderId.length === 1 &&
                filters.partNumber.length === 1 &&
                filters.envType.length === 1
            );
        };

        setAppStore({
            ...appStore,
            filters: {
                ...filters,
                ...dependentState,
                options: {
                    ...appStore.filters.options,
                    orderId: selectedOrder,
                    partNumber: selectedProduct,
                    envType: selectedEnvType
                }
            },
            orderDetails: [filteredOrderDetails],
            provisionedEnvs: [getSelectedEnv],
            stakeholdersDetails: filteredStakeholdersDetails,
            selectedOption: 'preselected@'
        });

        if (!filterLengthCheck() && selectedOption !== 'preselected@') {
            setShowFilterAlert(true);
        }
    };

    // updating the environment status for selected environment.
    const updateEnvStatus = async getSelectedEnv => {
        if (!getSelectedEnv.envState || !priorityAlert.length) {
            const priorityCases = await hnpApiUtils.getOpensearchPriorityCases({
                hcn: getSelectedEnv.customer.hcn,
                size: 500
            });

            setAppStore(prevState => ({
                ...prevState,
                priorityAlert: getPriorityAlertData(
                    getSelectedEnv.customer.hcn,
                    priorityCases
                )
            }));

            const newEnvState = getEnvStatus(
                priorityCases.data.result[0],
                getSelectedEnv.provisionedEnvironment.type
            );

            getSelectedEnv.envState = newEnvState;
            setEnvState(newEnvState);
        } else {
            setEnvState(getSelectedEnv.envState);
        }
    };

    const updateEnvType = getSelectedEnv => {
        const selectedEnvType = filters.options.envType.filter(
            obj => obj.data === getSelectedEnv.provisionedEnvironment.type
        );

        setAppStore({
            ...appStore,
            filters: {
                ...filters,
                envType: selectedEnvType,
                options: {
                    ...appStore.filters.options,
                    envType: selectedEnvType
                }
            },
            selectedOption: `envType@${hnpEnvironments.prod}`
        });
    };

    useEffect(() => {
        const paramSolId = solId || 0;
        const getSelectedEnv = getEnvById(provisionedEnvs, paramSolId);
        Object.keys(getSelectedEnv).length && updateEnvStatus(getSelectedEnv);
        setSelectedEnv(getSelectedEnv);
        setUpdateFilters(true);
    }, []);

    useEffect(() => {
        if (selectedEnv.partNumber) {
            prePopulateFilters(selectedEnv);
        }
    }, [updateFilters]);

    useEffect(() => {
        // isMount is the custom hook result that holds component initial render status.
        // isMount is used to find whether the useEffect hook is running for initial render or filter changes.
        if (!isMount && selectedOption !== 'preselected@') {
            // resetting the env state for every filter change
            setEnvState(null);
            setAppStore(prevState => ({
                ...prevState,
                priorityAlert: []
            }));

            if (!filters.orderId.length || !filters.partNumber.length) {
                setSelectedEnv({});
            } else {
                const firstEnvId = getFirstEnvIdRule(provisionedEnvs);
                const getSelectedEnv = getEnvById(provisionedEnvs, firstEnvId);
                Object.keys(getSelectedEnv).length &&
                    updateEnvStatus(getSelectedEnv);
                !filters.envType.length && updateEnvType(getSelectedEnv);
                setSelectedEnv(getSelectedEnv);
                firstEnvId &&
                    navigate(`${hnpRoutes.metricsPage}/${firstEnvId}`, {
                        replace: true
                    });
            }
        }
    }, [provisionedEnvs]);

    const handleOnClick = (link, type, buttonType) => e => {
        e.preventDefault();
        if (type === 'osd' && link) {
            // Get the URL for metrics
            const metricsURL = getMetricsUrl(userDetails.role, link);
            // Redirect to OpenSearch dashboard
            safeRedirect(metricsURL, true);
            // Passing button click information to GA4 to know which metric type clicked by user
            window.dataLayer &&
                window.dataLayer.push({
                    event: 'eventMetricsName',
                    metricsName: buttonType
                });
        } else if (link) {
            // Redirect to the link (product home page or sofy console)
            safeRedirect(link, true);
        }
    };

    const handleAlertClose = () => {
        setShowFilterAlert(false);
    };

    // Render non-prod message
    const renderNonProdMsg = () => (
        <Fragment>
            <Grid xs={12} md={4}>
                <StyledCard className={`${classes.card}`}>
                    <CardHeader
                        title="Notice"
                        subheader={`${provisionedEnvironment.name} environment`}
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            sx={sxStyles.verticalEllipsis}
                        >
                            {`Since this is a ${provisionedEnvironment.name} environment, no metrics can be displayed. Only production environments will display Service and Product Health metrics. For questions regarding this environment, please contact HCL Support.`}
                        </Typography>
                    </CardContent>
                </StyledCard>
            </Grid>
        </Fragment>
    );
    // Render metrics details
    const renderMetricsDetails = () => (
        <Grid container spacing={2}>
            {[selectedEnv].map(
                ({
                    _id,
                    name: partNumberName,
                    description,
                    homePageUrl,
                    logo,
                    provisionedEnvironment: { type },
                    environmentContent: { version }
                }) => (
                    <Grid key={_id} xs={12} lg={8}>
                        <StyledCard
                            className={`${classes.card} ${classes.productDetailsCard}`}
                        >
                            <CardHeader
                                className={classes.productDetailsCardHeader}
                                avatar={
                                    <Avatar
                                        variant="square"
                                        aria-label="Product logo"
                                        alt="Product logo"
                                        src={logo}
                                        sx={sxStyles.noProductLogoBg}
                                    >
                                        {getDisplayName(partNumberName)}
                                    </Avatar>
                                }
                                action={
                                    type === hnpEnvironments.prod &&
                                    envState && (
                                        <HnpEnvStatusIcon envState={envState} />
                                    )
                                }
                                title={
                                    <HnpTitleTooltip
                                        title={partNumberName}
                                        arrow
                                        type="productNameMetricsPage"
                                    >
                                        {partNumberName}
                                    </HnpTitleTooltip>
                                }
                                subheader={`Version ${version}`}
                            />
                            <CardContent
                                sx={{ paddingBottom: theme.spacing(1.25) }}
                            >
                                <Typography
                                    variant="body2"
                                    sx={sxStyles.verticalEllipsis}
                                >
                                    {description}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <HnpButton
                                    variant="contained"
                                    className={classes.prodBtn}
                                    sx={sxStyles.containedBtn}
                                    onClick={handleOnClick(homePageUrl)}
                                >{`${getProductNameByCommaRule(
                                    partNumberName
                                ).replace('HCL', '')} Home Page`}</HnpButton>
                            </CardActions>
                        </StyledCard>
                    </Grid>
                )
            )}
            {provisionedEnvironment.type === hnpEnvironments.prod
                ? renderMetricsTiles()
                : renderNonProdMsg()}
        </Grid>
    );
    // Render metrics tiles
    const renderMetricsTiles = () => (
        <Fragment>
            {selectedEnv.businessMetrics && (
                <Grid xs={12} md={6} lg={4}>
                    <StyledCard
                        className={`${classes.card} ${classes.productDetailsCard} ${classes.bgTiles} ${classes.bgBusinessTile}`}
                    >
                        <CardHeader
                            className={classes.header}
                            title="Business Metrics"
                            subheader="Last 1 year"
                        />
                        <CardContent>
                            <Typography
                                variant="body2"
                                sx={sxStyles.verticalEllipsis}
                            >
                                View business metrics &#40;such as orderlines
                                consumption trends&#41; related to the deployed
                                product environment.
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <HnpButton
                                variant="contained"
                                className={classes.detailsBtn}
                                sx={sxStyles.containedBtn}
                                onClick={handleOnClick(
                                    selectedEnv.businessMetrics,
                                    'osd',
                                    'businessMetrics'
                                )}
                                disabled={
                                    selectedEnv.environmentContent
                                        .solutionType === 'sofy'
                                }
                            >
                                View
                            </HnpButton>
                        </CardActions>
                    </StyledCard>
                </Grid>
            )}
            <Grid xs={12} md={6} lg={4}>
                <StyledCard
                    className={`${classes.card} ${classes.productDetailsCard} ${classes.bgTiles} ${classes.bgServiceTile}`}
                >
                    <CardHeader title="Service Metrics" />
                    <CardContent>
                        <Typography
                            variant="body2"
                            sx={sxStyles.verticalEllipsis}
                        >
                            View details of Now Incidents, Change Requests and
                            Service Requests related to the deployed
                            environment.
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <HnpButton
                            variant="contained"
                            className={classes.detailsBtn}
                            sx={sxStyles.containedBtn}
                            onClick={handleOnClick(
                                selectedEnv.serviceMetrics,
                                'osd',
                                'serviceMetrics'
                            )}
                        >
                            View
                        </HnpButton>
                    </CardActions>
                </StyledCard>
            </Grid>
            {selectedEnv.environmentContent.solutionType === 'sofy' ? (
                <Grid xs={12} md={6} lg={4}>
                    <StyledCard
                        className={`${classes.card} ${classes.productDetailsCard} ${classes.bgTiles} ${classes.bgSofyTile}`}
                    >
                        <CardHeader title="Solution Console" />
                        <CardContent>
                            <Typography
                                variant="body2"
                                sx={sxStyles.verticalEllipsis}
                            >
                                {selectedEnv.solConsoleMessage}
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <HnpButton
                                variant="contained"
                                className={classes.detailsBtn}
                                sx={sxStyles.containedBtn}
                                onClick={
                                    selectedEnv.sofySolnConsole
                                        ? handleOnClick(
                                              selectedEnv.sofySolnConsole.url
                                          )
                                        : null
                                }
                                disabled={
                                    selectedEnv.environmentContent
                                        .solutionType !== 'sofy'
                                }
                            >
                                View
                            </HnpButton>
                        </CardActions>
                    </StyledCard>
                </Grid>
            ) : (
                <Fragment>
                    {selectedEnv.productMetrics && (
                        <Grid xs={12} md={6} lg={4}>
                            <StyledCard
                                className={`${classes.card} ${classes.productDetailsCard} ${classes.bgTiles} ${classes.bgProductTile}`}
                            >
                                <CardHeader title="Product Health Metrics" />
                                <CardContent>
                                    <Typography
                                        variant="body2"
                                        sx={sxStyles.verticalEllipsis}
                                    >
                                        View and monitor detailed performance
                                        and health metrics of the deployed
                                        environment.
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                    <HnpButton
                                        variant="contained"
                                        className={classes.detailsBtn}
                                        sx={sxStyles.containedBtn}
                                        onClick={handleOnClick(
                                            selectedEnv.productMetrics,
                                            'osd',
                                            'productMetrics'
                                        )}
                                        disabled={
                                            selectedEnv.environmentContent
                                                .solutionType === 'sofy'
                                        }
                                    >
                                        View
                                    </HnpButton>
                                </CardActions>
                            </StyledCard>
                        </Grid>
                    )}

                    <Grid xs={12} md={6} lg={4}>
                        <StyledCard
                            className={`${classes.card} ${classes.productDetailsCard} ${classes.bgTiles} ${classes.bgInfraTile}`}
                        >
                            <CardHeader title={'Infra Metrics'} />
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    sx={sxStyles.verticalEllipsis}
                                >
                                    View and monitor details of cloud
                                    infrastructure resource usage by the
                                    deployed environment.
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <HnpButton
                                    variant="contained"
                                    className={classes.detailsBtn}
                                    sx={sxStyles.containedBtn}
                                    onClick={handleOnClick(
                                        selectedEnv.infraMetrics,
                                        'osd',
                                        'infraMetrics'
                                    )}
                                    disabled={
                                        selectedEnv.environmentContent
                                            .solutionType === 'sofy'
                                    }
                                >
                                    View
                                </HnpButton>
                            </CardActions>
                        </StyledCard>
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    );

    return isLogin === hnpLoginStatus.loggedIn && selectedEnv ? (
        <StyledBox className={classes.container} sx={sxStyles.appContainer}>
            <Box className={classes.customerNameBox} sx={sxStyles.mb2}>
                <HnpTitleTooltip
                    title={
                        selectedEnv.customerName ? selectedEnv.customerName : ''
                    }
                    arrow
                    type="mainCustomerName"
                >
                    {selectedEnv.customerName}
                </HnpTitleTooltip>
            </Box>
            {/* Render the Fitler component */}
            <HnpFilters />
            <StyledSnackBar
                open={showFilterAlert}
                onClose={handleAlertClose}
                message={`${hnpMetricsAlert.message} ${selectedEnv.customerName}`}
                autoHideDuration={isScreenDesktopSize ? 5000 : 4000}
                anchorOrigin={
                    isScreenDesktopSize
                        ? hnpMetricsAlert.topRightPlacement
                        : hnpMetricsAlert.bottomCenterPlacement
                }
            />

            {Object.keys(selectedEnv).length ? (
                <Fragment>
                    <Box className={classes.productNameSection}>
                        <Box className={classes.productNameChip}>
                            <Box
                                component="span"
                                className={classes.productName}
                            >
                                {getProductNameByCommaRule(selectedEnv.name)}
                            </Box>
                            <Box className={classes.envChip}>
                                <StyledChip
                                    size="small"
                                    label={provisionedEnvironment.name}
                                    bgcolor={getEnvChipColor(
                                        hnpEnvironments,
                                        provisionedEnvironment.type
                                    )}
                                    icon={
                                        provisionedEnvironment.type ===
                                        hnpEnvironments.prod ? (
                                            <StyledEnvIcon />
                                        ) : null
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.content}>
                        <Box className={classes.contentDetails}>
                            {renderMetricsDetails()}
                        </Box>
                    </Box>
                    {priorityAlert.length > 0 &&
                    (isScreenDesktopSize ||
                        (!isScreenDesktopSize && !showFilterAlert)) ? (
                        <Grid container className={classes.priorityContainer}>
                            <Grid xs={12} sm={12} lg={12}>
                                <HnpPriorityAlert alertData={priorityAlert} />
                            </Grid>
                        </Grid>
                    ) : null}
                </Fragment>
            ) : (
                <HnpMessage msg={hnpNoMetricsMsg} />
            )}
        </StyledBox>
    ) : null;
};

export default HnpMetrics;
