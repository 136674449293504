import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { styled, useTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import BookIcon from '@mui/icons-material/Book';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Badge from '@mui/material/Badge';
import HelpIcon from '@mui/icons-material/Help';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { HnpContext } from '../../contexts/HnpContext';
import HnpUserTray from '../HnpUserTray';
import { StyledSnackBar, StyledTooltip } from '../HnpMuiComponents';
import {
    getDisplayName,
    getDisplayUserRole,
    getFirstEnvIdRule,
    logoutAndRedirect,
    getFilteredRoles
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import {
    hnpVersion,
    hnpName,
    hnpRoutes,
    hnpSupportTitle,
    hnpLoginStatus,
    hnpDemoModeAlerts,
    hnpMetricsAlert,
    hnpNavigationItems,
    hclswUrl
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import HnpSupport from '../HnpSupport';
import HnpDemo from '../HnpDemo';
import { userMaleDP } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpImgDataURI';
import HCLSWLogo from '../../assets/images/hclsw-brand.svg';

const PREFIX = 'HnpHeader';
const supportWidth = 390;
const appBarMinHeight = 56;

const classes = {
    root: `${PREFIX}-root`,
    hamburgerBtn: `${PREFIX}-hamburgerBtn`,
    mobileToolbar: `${PREFIX}-mobileToolbar`,
    search: `${PREFIX}-search`,
    searchIcon: `${PREFIX}-searchIcon`,
    searchInput: `${PREFIX}-searchInput`,
    logoContainer: `${PREFIX}-logoContainer`,
    logo: `${PREFIX}-logo`,
    appName: `${PREFIX}-appName`,
    headerSeparator: `${PREFIX}-headerSeparator`,
    tealBorder: `${PREFIX}-tealBorder`,
    desktopNavItemsContainer: `${PREFIX}-desktopNavItemsContainer`,
    desktopAppbarNavItems: `${PREFIX}-desktopAppbarNavItems`,
    desktopAppbarNavItemsLeft: `${PREFIX}-desktopAppbarNavItemsLeft`,
    desktopAppbarNavItemsRight: `${PREFIX}-desktopAppbarNavItemsRight`,
    desktopAppbarNavItem: `${PREFIX}-desktopAppbarNavItem`,
    drawerListItemText: `${PREFIX}-drawerListItemText`,
    iconsContainer: `${PREFIX}-iconsContainer`,
    drawerUserContainer: `${PREFIX}-drawerUserContainer`,
    userDPsize: `${PREFIX}-userDPsize`,
    listItemIconSize: `${PREFIX}-listItemIconSize`,
    desktopAppbarNavItemBox: `${PREFIX}-desktopAppbarNavItemBox`,
    activeDrawerLink: `${PREFIX}-activeDrawerLink`,
    userDPname: `${PREFIX}-userDPname`,
    desktopUserTray: `${PREFIX}-desktopUserTray`,
    desktopUserNRcontainer: `${PREFIX}-desktopUserNRcontainer`,
    appBar: `${PREFIX}-appBar`,
    headerBorderBottom: `${PREFIX}-headerBorderBottom`,
    toolBar: `${PREFIX}-toolBar`,
    desktopUserTrayHanger: `${PREFIX}-desktopUserTrayHanger`,
    contactUs: `${PREFIX}-contactUs`,
    closeIcon: `${PREFIX}-closeIcon`,
    userName: `${PREFIX}-userName`,
    userEmail: `${PREFIX}-userEmail`,
    userRoleDetail: `${PREFIX}-userRoleDetail`,
    logoutIcon: `${PREFIX}-logoutIcon`,
    logout: `${PREFIX}-logout`,
    greyBorder: `${PREFIX}-greyBorder`,
    userRoleBox: `${PREFIX}-userRoleBox`,
    noTopPadding: `${PREFIX}-noTopPadding`
};

const Root = styled('header')(({ theme }) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
        display: 'flex',
        [`& fieldset`]: {
            borderRadius: 0
        }
    },
    [`& .${classes.appBar}`]: {
        alignItems: 'center',
        backgroundColor: colors.grey10,
        height: theme.spacing(7),
        boxShadow: 'none'
    },
    [`& .${classes.hamburgerBtn}`]: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    [`& .${classes.logoContainer}`]: {
        display: 'flex',
        alignItems: 'center'
    },
    [`& .${classes.logo}`]: {
        cursor: 'pointer',
        textDecoration: 'none',
        background: 'transparent',
        border: 'none',
        padding: theme.spacing(0),
        '&:visited, &:active, &:focus': {
            color: colors.white
        },
        [theme.breakpoints.between('md', 'lg')]: {
            '& > img': {
                width: theme.spacing(15.625)
            }
        }
    },
    [`& .${classes.appName}`]: {
        cursor: 'pointer',
        color: colors.aqua,
        fontSize: theme.spacing(2),
        fontFamily: 'inherit',
        letterSpacing: theme.spacing(0.025),
        fontWeight: 600,
        padding: theme.spacing(0.25, 0, 0, 0.5),
        background: 'transparent',
        border: 'none'
    },
    [`& .${classes.headerSeparator}`]: {
        fontSize: theme.spacing(4),
        fontWeight: 100,
        color: colors.grey2,
        margin: theme.spacing(0, 0.5, 0.25)
    },
    [`& .${classes.desktopAppbarNavItemsRight}`]: {
        flex: 1,
        justifyContent: 'flex-end'
    },
    [`& .${classes.headerBorderBottom}`]: {
        borderBottom: `${theme.spacing(0.125)} solid ${colors.grey11}`
    },
    [`& .${classes.toolBar}`]: {
        width: '100%',
        padding: theme.spacing(1.25, 4),
        minHeight: appBarMinHeight
    }
}));
const StyledMobileMenuDrawer = styled(Box)(({ theme }) => ({
    [`& .${classes.mobileToolbar}`]: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '44px !important',
        borderBottom: `${theme.spacing(0.125)} solid ${colors.grey11}`,
        ...theme.mixins.toolbar
    },
    [`& .${classes.closeIcon}`]: {
        color: colors.grey,
        margin: theme.spacing(1),
        position: 'absolute',
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
        top: 0,
        right: 0
    },
    [`& .${classes.userRoleBox}`]: {
        border: `${theme.spacing(0.125)} solid ${colors.teal}`,
        padding: theme.spacing(0.25),
        color: colors.teal,
        borderRadius: theme.spacing(0.25)
    },
    [`& .${classes.greyBorder}`]: {
        borderBottom: `${theme.spacing(0.125)} solid ${colors.grey11}`
    }
}));

const StyledMenuList = styled(Box)(({ theme }) => ({
    [`&.${classes.desktopNavItemsContainer}`]: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    [`& .${classes.desktopAppbarNavItemsLeft}`]: {
        flex: 4,
        justifyContent: 'center'
    },
    [`& .${classes.desktopAppbarNavItems}`]: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    [`& .${classes.desktopAppbarNavItemBox}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1.5, 2),
        margin: theme.spacing(0, 0.5),
        color: colors.white,
        borderBottom: `${theme.spacing(0.25)} solid ${colors.grey10}`,
        '&:hover:not(.HnpHeader-tealBorder)': {
            color: colors.turquoise10
        }
    },
    [`& .${classes.tealBorder}`]: {
        borderBottom: `${theme.spacing(0.25)} solid ${colors.aqua}`
    },
    [`& .${classes.search}`]: {
        position: 'relative',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        borderRadius: 0,
        width: '100%',
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto'
        }
    },
    [`& .${classes.searchIcon}`]: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.searchInput}`]: {
        height: '1.1876em',
        padding: theme.spacing(1, 1, 1, 0),
        // Vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    },
    [`& .${classes.iconsContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        '& .MuiSvgIcon-root': {
            fill: colors.white2
        }
    },
    [`& .${classes.contactUs}`]: {
        fontSize: '1.5rem'
    },
    [`& .${classes.desktopUserNRcontainer}`]: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minHeight: appBarMinHeight,
        padding: theme.spacing(0, 0, 0, 1.5),
        cursor: 'pointer'
    },
    [`& .${classes.desktopUserTrayHanger}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    // Mobile menu link and common classes
    [`& .${classes.listItemIconSize}`]: {
        minWidth: theme.spacing(4.5)
    },
    [`& .${classes.activeDrawerLink}`]: {
        color: colors.grey10
    },
    [`& .${classes.drawerListItemText}`]: {
        '& a': {
            justifyContent: 'flex-start',
            minHeight: 0,
            textTransform: 'capitalize',
            color: 'inherit',
            '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }
        }
    },
    [`& .${classes.desktopAppbarNavItem}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        fontSize: theme.spacing(1.75),
        fontWeight: 600,
        textDecoration: 'none',
        letterSpacing: theme.spacing(0.02),
        color: 'inherit',
        outline: 'none',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.spacing(1.25)
        }
    },
    [`& .${classes.noTopPadding}`]: {
        paddingTop: 0
    }
}));
// userDpStyled used in two styled components hence used as variable
const userDpStyle = {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.aqua,
    color: colors.grey10,
    fontWeight: 600,
    height: '1.875rem',
    width: '1.875rem',
    borderRadius: '50%',
    fontSize: '0.75rem',
    overflow: 'hidden',
    textTransform: 'uppercase',
    '&:hover': {
        backgroundColor: colors.aqua,
        color: colors.grey10
    },
    '& img': {
        height: '1.875rem',
        width: '1.875rem'
    }
};
const StyledUserDp = styled(Box)(({ theme }) => ({
    [`&.${classes.userDPname}`]: userDpStyle,
    [`& .${classes.userDPsize}`]: {
        height: theme.spacing(12),
        width: theme.spacing(12),
        border: `1px solid ${colors.blue6}`,
        '& > img': {
            marginLeft: 0
        }
    }
}));

let HnpHeader = props => {
    const theme = useTheme();
    const isScreenSizeMedium = useMediaQuery(theme.breakpoints.up('md'));
    const currPathname = useLocation().pathname;
    const { appStore, setAppStore, hnpFeatureFlags } = useContext(HnpContext);
    const { isLogin, userDetails, hnpApiUtils, provisionedEnvs, filters } =
        appStore;
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSupport, setOpenSupport] = React.useState(false);
    const [isDemoDisabled, setIsDemoDisabled] = useState(false);
    const firstEnvId = getFirstEnvIdRule(provisionedEnvs);

    useEffect(() => {
        // Show the button only if the user is logged in
        if (isLogin === hnpLoginStatus.loggedIn) {
            setIsLoggedIn(true);
        }
    }, []);
    useEffect(() => {
        getRightActiveTab();
    }, [currPathname]);

    const handleRedirect = (url, newTab) => e => {
        e.preventDefault();
        safeRedirect(url, newTab);
    };
    const handleDrawerOpen = () => {
        setOpenSupport(!openSupport);
    };
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };
    const handleDisabledMsg = () => {
        if (filters.hcn.length !== 1) {
            setIsDemoDisabled(true);
        } else {
            setIsDrawerOpen(false);
        }
    };
    const handleAlertClose = () => {
        setIsDemoDisabled(false);
    };
    const getRightActiveTab = () => {
        setActiveTab(
            currPathname === hnpRoutes.environmentPage
                ? 0
                : currPathname.includes(hnpRoutes.metricsPage)
                ? 1
                : currPathname.includes(hnpRoutes.stakeHolderPage)
                ? 2
                : currPathname.includes(hnpRoutes.guidePage)
                ? 3
                : currPathname.includes(hnpRoutes.helpCenterPage)
                ? 4
                : currPathname.includes(hnpRoutes.termsPage)
                ? 5
                : null
        );
    };
    const handleTabChange = e => {
        // Enable tab change only when user is logged in
        if (isLoggedIn) {
            setActiveTab(parseInt(e.currentTarget.tabIndex));
        }
        setIsDrawerOpen(false);
    };
    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    const handleUserTrayMenuClick = e => {
        setAnchorEl(e.currentTarget);
    };
    const handleUserTrayClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        setAnchorEl(null);
        setIsDrawerOpen(false);
        setIsLoggedIn(false);
        // Reset the context level state
        setAppStore({ ...appStore, userDetails: null });
        // Logout and destroy the existing session
        logoutAndRedirect(hnpApiUtils, Cookies);
    };

    const hnpLogoElm = (
        <Box className={classes.logoContainer}>
            <Box
                className={classes.logo}
                sx={sxStyles.tabFocus}
                component="button"
                onClick={handleRedirect(hclswUrl, true)}
            >
                <img
                    src={HCLSWLogo}
                    alt="HCL Software"
                    style={{
                        width: theme.spacing(16.125),
                        height: theme.spacing(3.5)
                    }}
                />
            </Box>
            <Box
                component="span"
                className={classes.headerSeparator}
                sx={sxStyles.separator}
            >
                |
            </Box>
            <StyledTooltip
                styles={{ marginTop: theme.spacing(1) }}
                title={hnpVersion}
                arrow
            >
                <Box
                    className={classes.appName}
                    sx={sxStyles.tabFocus}
                    component="button"
                    onClick={handleRedirect(
                        process.env.REACT_APP_PUBLIC_URL,
                        false
                    )}
                >
                    {hnpName}
                </Box>
            </StyledTooltip>
        </Box>
    );
    const drawer = (
        <StyledMobileMenuDrawer>
            <Box className={classes.mobileToolbar}>
                <Box
                    sx={{ pl: theme.spacing(1.5) }}
                    onClick={handleRedirect(hclswUrl, true)}
                >
                    <img
                        src={HCLSWLogo}
                        alt="HCL Software"
                        style={{
                            width: theme.spacing(16.125),
                            height: theme.spacing(3.5)
                        }}
                    />
                </Box>
                <CloseIcon
                    className={classes.closeIcon}
                    onClick={handleDrawerClose}
                />
            </Box>
            {userDetails ? (
                <Box
                    sx={{
                        padding: theme.spacing(2.125, 2),
                        borderBottom: `${theme.spacing(0.125)} solid ${
                            colors.grey11
                        }`
                    }}
                >
                    <Typography sx={{ color: colors.grey7, fontWeight: 600 }}>
                        {userDetails.name}
                    </Typography>
                    <Typography
                        sx={{
                            color: colors.grey7,
                            fontWeight: 400,
                            paddingBottom: theme.spacing(2)
                        }}
                    >
                        {userDetails.email}
                    </Typography>
                    <Typography variant="span" className={classes.userRoleBox}>
                        {getDisplayUserRole(userDetails.role)}
                    </Typography>
                </Box>
            ) : null}
            {userDetails ? (
                <StyledMenuList>
                    <List>
                        {hnpNavigationItems.map((item, index) => {
                            return (
                                <ListItemButton
                                    key={index}
                                    sx={{
                                        padding: theme.spacing(2.125, 2),
                                        borderBottom: `${theme.spacing(
                                            0.125
                                        )} solid ${colors.grey11}`
                                    }}
                                >
                                    <ListItemText
                                        className={classes.drawerListItemText}
                                    >
                                        <Link
                                            to={
                                                index === 1
                                                    ? firstEnvId
                                                        ? `${hnpRoutes.metricsPage}/${firstEnvId}`
                                                        : hnpRoutes.environmentPage
                                                    : item.linkTo
                                            }
                                            alt="Menu"
                                            tabIndex={index}
                                            className={
                                                classes.desktopAppbarNavItem
                                            }
                                            onClick={handleTabChange}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: colors.grey7,
                                                    fontWeight: 600
                                                }}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Link>
                                    </ListItemText>
                                </ListItemButton>
                            );
                        })}
                        {hnpFeatureFlags && hnpFeatureFlags.enableTermsPage ? (
                            <ListItemButton>
                                <ListItemIcon
                                    className={classes.listItemIconSize}
                                >
                                    <BookIcon
                                        className={
                                            (activeTab === 5 &&
                                                classes.activeDrawerLink) ||
                                            ''
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    className={classes.drawerListItemText}
                                >
                                    <Link
                                        to={hnpRoutes.termsPage}
                                        alt="Menu"
                                        tabIndex="4"
                                        className={classes.desktopAppbarNavItem}
                                        onClick={handleTabChange}
                                    >
                                        <Typography variant="body2">
                                            Terms
                                        </Typography>
                                    </Link>
                                </ListItemText>
                            </ListItemButton>
                        ) : null}
                    </List>
                    <List sx={{ pt: theme.spacing(0) }}>
                        {getFilteredRoles(userDetails.role) ? (
                            <ListItemButton
                                onClick={handleDisabledMsg}
                                className={classes.greyBorder}
                                sx={{ pt: theme.spacing(0) }}
                            >
                                <ListItemText
                                    className={classes.drawerListItemText}
                                >
                                    <HnpDemo closeUserTray={setIsDrawerOpen} />
                                </ListItemText>
                            </ListItemButton>
                        ) : null}
                        <ListItemButton
                            onClick={handleLogout}
                            className={classes.greyBorder}
                            sx={{ p: theme.spacing(2.125, 2) }}
                        >
                            <ListItemText>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: colors.grey7,
                                        fontWeight: 600
                                    }}
                                >
                                    Sign out
                                </Typography>
                            </ListItemText>
                            <ListItemIcon className={classes.listItemIconSize}>
                                <ExitToAppIcon sx={{ color: colors.grey7 }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </List>
                </StyledMenuList>
            ) : null}
            {/* Snackbar for mobile devices when demo mode in disabled state */}
            <StyledSnackBar
                open={isDemoDisabled}
                onClose={handleAlertClose}
                sx={{ textAlign: 'center' }}
                message={hnpDemoModeAlerts.demoModeDisable}
                autoHideDuration={4000}
                anchorOrigin={hnpMetricsAlert.bottomCenterPlacement}
            />
        </StyledMobileMenuDrawer>
    );

    const container =
        props.window !== undefined ? () => window().document.body : undefined;

    return (
        <Root className={classes.root}>
            <AppBar
                position="fixed"
                className={`${classes.appBar} ${classes.headerBorderBottom}`}
                onClick={() => (openSupport ? setOpenSupport(false) : null)}
            >
                <Toolbar className={classes.toolBar}>
                    {!(
                        currPathname.includes(hnpRoutes.loginPage) ||
                        currPathname.includes(hnpRoutes.maintenancePage)
                    ) ? (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.hamburgerBtn}
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : null}
                    {hnpLogoElm}
                    {isLogin === hnpLoginStatus.loggedIn ? (
                        <StyledMenuList
                            className={classes.desktopNavItemsContainer}
                        >
                            <Box
                                className={`${classes.desktopAppbarNavItems} ${classes.desktopAppbarNavItemsLeft}`}
                            >
                                {hnpNavigationItems.map((item, index) =>
                                    item.visibleOnDesktop ? (
                                        <Box
                                            className={`${
                                                classes.desktopAppbarNavItemBox
                                            } ${
                                                activeTab === index
                                                    ? classes.tealBorder
                                                    : ''
                                            }`}
                                            key={index}
                                        >
                                            <Link
                                                to={
                                                    index === 1
                                                        ? firstEnvId
                                                            ? `${hnpRoutes.metricsPage}/${firstEnvId}`
                                                            : hnpRoutes.environmentPage
                                                        : item.linkTo
                                                }
                                                alt="Menu"
                                                tabIndex={index}
                                                className={
                                                    classes.desktopAppbarNavItem
                                                }
                                                onClick={handleTabChange}
                                            >
                                                {item.title}
                                            </Link>
                                        </Box>
                                    ) : null
                                )}
                                {hnpFeatureFlags &&
                                hnpFeatureFlags.enableTermsPage ? (
                                    <Box
                                        mr={(!userDetails && 2) || 3}
                                        className={`${
                                            classes.desktopAppbarNavItemBox
                                        } ${
                                            activeTab === 5
                                                ? classes.tealBorder
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to={hnpRoutes.termsPage}
                                            alt="Menu"
                                            tabIndex="5"
                                            className={
                                                classes.desktopAppbarNavItem
                                            }
                                            onClick={handleTabChange}
                                        >
                                            Terms
                                        </Link>
                                    </Box>
                                ) : null}
                            </Box>
                            <Box
                                className={`${classes.desktopAppbarNavItems} ${classes.desktopAppbarNavItemsRight}`}
                            >
                                {hnpFeatureFlags &&
                                hnpFeatureFlags.showSearchBox ? (
                                    <Box mr={2} className={classes.search}>
                                        <Box className={classes.searchIcon}>
                                            <SearchIcon />
                                        </Box>
                                        <InputBase
                                            placeholder="Search…"
                                            classes={{
                                                input: classes.searchInput
                                            }}
                                            sx={{
                                                '&.MuiInputBase-root': {
                                                    color: 'inherit'
                                                }
                                            }}
                                            inputProps={{
                                                'aria-label': 'search'
                                            }}
                                            disabled
                                        />
                                    </Box>
                                ) : null}
                                <Box className={classes.iconsContainer}>
                                    <StyledTooltip
                                        styles={{
                                            marginTop: theme.spacing(0.5)
                                        }}
                                        title={hnpSupportTitle}
                                        arrow
                                    >
                                        <IconButton
                                            aria-label="help and support dialog"
                                            onClick={handleDrawerOpen}
                                            size="large"
                                        >
                                            <HelpIcon
                                                className={classes.contactUs}
                                            />
                                        </IconButton>
                                    </StyledTooltip>
                                    {hnpFeatureFlags &&
                                    hnpFeatureFlags.showAnnouncementsIcon ? (
                                        <StyledTooltip
                                            title="Click to toggle announcements"
                                            arrow
                                        >
                                            <IconButton
                                                aria-label="new releases"
                                                size="large"
                                            >
                                                <NewReleasesIcon />
                                            </IconButton>
                                        </StyledTooltip>
                                    ) : null}
                                    {hnpFeatureFlags &&
                                    hnpFeatureFlags.showNotificationsIcon ? (
                                        <StyledTooltip
                                            title="Notifications"
                                            arrow
                                        >
                                            <IconButton
                                                aria-label="notifications"
                                                size="large"
                                            >
                                                <Badge
                                                    badgeContent={1}
                                                    color="error"
                                                >
                                                    <NotificationsIcon />
                                                </Badge>
                                            </IconButton>
                                        </StyledTooltip>
                                    ) : null}
                                </Box>
                                {userDetails && (
                                    <Box
                                        className={
                                            classes.desktopUserNRcontainer
                                        }
                                    >
                                        <StyledUserDp
                                            className={
                                                classes.desktopUserTrayHanger
                                            }
                                            onClick={handleUserTrayMenuClick}
                                        >
                                            <StyledTooltip
                                                title={
                                                    userDetails &&
                                                    userDetails.name
                                                }
                                                arrow
                                            >
                                                <IconButton
                                                    aria-label="account of current user"
                                                    aria-controls="menu-appbar"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    sx={userDpStyle}
                                                    size="large"
                                                >
                                                    {userDetails.dp ? (
                                                        <img
                                                            src={userMaleDP}
                                                            alt="User DP"
                                                        />
                                                    ) : (
                                                        getDisplayName(
                                                            userDetails.name
                                                        )
                                                    )}
                                                </IconButton>
                                            </StyledTooltip>
                                        </StyledUserDp>
                                        <HnpUserTray
                                            anchorEl={anchorEl}
                                            handleUserTrayClose={
                                                handleUserTrayClose
                                            }
                                            handleLogout={handleLogout}
                                            setAnchorEl={setAnchorEl}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </StyledMenuList>
                    ) : null}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                sx={{
                    zIndex: '1000 !important',
                    '& .MuiPaper-root': {
                        width: supportWidth,
                        padding: theme.spacing(8, 2, 2, 2)
                    }
                }}
                anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                open={openSupport}
                onClose={() => setOpenSupport(false)}
            >
                <HnpSupport setOpen={setOpenSupport} open={openSupport} />
            </Drawer>
            <nav aria-label="HCL Software Innovation Labs nav links">
                {!isScreenSizeMedium ? (
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={isDrawerOpen}
                        onClose={handleDrawerToggle}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: '100vw',
                                backgroundColor: colors.black
                            }
                        }}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                        {drawer}
                    </Drawer>
                ) : null}
            </nav>
        </Root>
    );
};

HnpHeader.propTypes = {
    window: PropTypes.any
};

export default HnpHeader;
