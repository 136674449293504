import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CardContent from '@mui/material/CardContent';
import Cookies from 'js-cookie';
import { HnpContext } from '../../contexts/HnpContext';
import HnpCustomerName from '../HnpCustomerName';
import HnpFilters from '../HnpFilters';
import HnpNotFound from '../HnpNotFound';
import { StyledCard } from '../HnpMuiComponents';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { hnpLoginStatus } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import EnvProvisioned from '../../assets/images/env-provisioned.png';
import EnvRequest from '../../assets/images/env-request.png';
import Terms from '../../assets/images/terms-conditions.png';
import ThemeLogo from '../../assets/images/theme-customization.png';
import Contacts from '../../assets/images/stakeholder-contacts.png';

const PREFIX = 'HnpTerms';

const classes = {
    container: `${PREFIX}-container`,
    heading: `${PREFIX}-heading`,
    filters: `${PREFIX}-filters`,
    topCards: `${PREFIX}-topCards`,
    bottomCards: `${PREFIX}-bottomCards`,
    tempCard: `${PREFIX}-tempCard`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2.5, 0, 3)
    },
    [`& .${classes.heading}`]: {
        display: 'flex',
        alignItems: 'center',
        color: colors.grey1
    },
    [`& .${classes.filters}`]: {
        display: 'flex',
        alignItems: 'center'
    },
    [`& .${classes.topCards}`]: {
        display: 'flex',
        '& .MuiGrid2-container': {
            '& .MuiGrid2-root': {
                '&:nth-of-type(1)': {
                    '& .MuiCard-root': {
                        backgroundImage: `url(${EnvProvisioned})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%'
                    }
                },
                '&:nth-of-type(2)': {
                    '& .MuiCard-root': {
                        backgroundImage: `url(${EnvRequest})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%'
                    }
                },
                '&:nth-of-type(3)': {
                    '& .MuiCard-root': {
                        backgroundImage: `url(${Terms})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%'
                    }
                }
            }
        }
    },
    [`& .${classes.bottomCards}`]: {
        display: 'flex',
        '& .MuiGrid2-container': {
            '& .MuiGrid2-root': {
                '&:nth-of-type(1)': {
                    '& .MuiCard-root': {
                        backgroundImage: `url(${ThemeLogo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%'
                    }
                },
                '&:nth-of-type(2)': {
                    '& .MuiCard-root': {
                        backgroundImage: `url(${Contacts})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% 100%'
                    }
                }
            }
        }
    },
    [`& .${classes.tempCard}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(5),
        height: theme.spacing(38)
    }
}));

const HnpTerms = () => {
    const { appStore } = useContext(HnpContext);
    const { isLogin } = appStore || {};

    //Display terms page only if the HCL role user enable it from the Feature Flag page
    let enableTermsPage = false;
    let ffsData = Cookies.get('hnp_ffs');
    if (ffsData) {
        ffsData = JSON.parse(ffsData);
        enableTermsPage = ffsData.enableTermsPage;
    }
    return isLogin === hnpLoginStatus.loggedIn && enableTermsPage ? (
        <StyledBox className={classes.container} sx={sxStyles.appContainer}>
            <Box>
                <HnpCustomerName />
            </Box>
            <Box className={classes.filters} sx={sxStyles.mb}>
                <HnpFilters />
            </Box>
            <Box className={classes.topCards} sx={sxStyles.mt2}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {[...new Array(3)].fill(0).map((item, i) => (
                        <Grid key={i} xs={12} md={4}>
                            <StyledCard className={classes.tempCard}>
                                <CardContent />
                            </StyledCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box className={classes.bottomCards} sx={sxStyles.mt3}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {[...new Array(2)].fill(0).map((item, i) => (
                        <Grid key={i} xs={12} md={i === 0 ? 8 : 4}>
                            <StyledCard className={classes.tempCard}>
                                <CardContent />
                            </StyledCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </StyledBox>
    ) : (
        <HnpNotFound />
    );
};

export default HnpTerms;
