import { useEffect, useRef } from 'react';

export const useDebounce = (cb, timeout, depsArr) => {
    const timeoutRef = useRef();

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(cb, timeout);

        return () => clearTimeout(timeoutRef.current);
    }, depsArr);
};

export const usePrevious = value => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    return ref.current;
};

export const useIsMount = () => {
    const isMountRef = useRef(true);

    useEffect(() => {
        isMountRef.current = false;
    }, []);

    return isMountRef.current;
};
