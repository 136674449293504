import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LegendComponent, DatasetComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { SVGRenderer } from 'echarts/renderers';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { HnpContext } from '../../contexts/HnpContext';

// Register the required components
echarts.use([LegendComponent, DatasetComponent, SVGRenderer, PieChart]);

const option = {
    color: [colors.purple1, colors.purple2, colors.purple3],
    legend: {
        type: 'scroll',
        orient: 'vertical',
        top: 5,
        left: '45%',
        itemWidth: 8,
        itemHeight: 8,
        selectedMode: false,
        textStyle: {
            color: colors.black,
            fontSize: 14,
            fontFamily: 'inherit',
            lineHeight: 25,
            opacity: 0.87
        }
    },
    series: [
        {
            name: 'All Open Cases',
            type: 'pie',
            radius: ['68%', '90%'],
            center: ['20%', '50%'],
            avoidLabelOverlap: false,
            legendHoverLink: false,
            label: {
                show: true,
                position: 'center',
                formatter: null,
                fontSize: 20,
                fontWeight: 600
            },
            labelLine: {
                show: false
            },
            emphasis: {
                disabled: true
            },
            data: [
                { value: null, name: null },
                { value: null, name: null },
                { value: null, name: null }
            ]
        }
    ],
    media: [
        {
            query: {
                minWidth: 190,
                maxWidth: 230
            },
            option: {
                legend: {
                    left: '35%',
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        },
        {
            query: {
                minWidth: 320,
                maxWidth: 470
            },
            option: {
                legend: {
                    left: '50%',
                    textStyle: {
                        fontSize: 14
                    }
                }
            }
        },
        {
            //media query for macbook, ipad and iphones
            query: {
                minWidth: 250,
                maxWidth: 320
            },
            option: {
                legend: {
                    left: '41%',
                    textStyle: {
                        fontSize: 14
                    }
                }
            }
        },
        {
            option: {
                legend: {
                    left: '45%',
                    textStyle: {
                        fontSize: 12
                    }
                }
            }
        }
    ]
};

const HnpOpenCasesChart = props => {
    const { appStore } = useContext(HnpContext);
    const { openCases } = appStore || {};
    const totalCount =
        openCases.totalIncidents +
        openCases.totalServiceRequests +
        openCases.totalChangeRequests;
    const [caseChartOption, setCaseChartOption] = useState(option);

    // style object for react echart core, the styles cannot apply using classname
    const caseStyleOption = {
        height: '115px',
        width: '100%',
        position: 'relative',
        top: '7px'
    };

    useEffect(() => {
        if (openCases) {
            setCaseChartOption({
                ...caseChartOption,
                label: {
                    ...caseChartOption.label,
                    formatter: `${totalCount}`
                },
                series: {
                    ...caseChartOption.series,
                    data: [
                        {
                            value: openCases.totalIncidents,
                            name: `${openCases.totalIncidents} Incidents`
                        },
                        {
                            value: openCases.totalChangeRequests,
                            name: `${openCases.totalChangeRequests} Change Requests`
                        },
                        {
                            value: openCases.totalServiceRequests,
                            name: `${openCases.totalServiceRequests} Service Requests`
                        }
                    ]
                }
            });
        }
    }, [openCases]);

    return (
        // opts attirbute for echart init configuration for unit tests
        <ReactEChartsCore
            echarts={echarts}
            option={caseChartOption}
            style={caseStyleOption}
            {...(props.opts && props)}
        />
    );
};

HnpOpenCasesChart.propTypes = {
    opts: PropTypes.any
};

export default HnpOpenCasesChart;
