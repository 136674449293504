import React, { useContext } from 'react';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import {
    getDisplayUserRole,
    getFilteredRoles
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import HnpDemo from '../HnpDemo';
import { HnpContext } from '../../contexts/HnpContext';

const PREFIX = 'HnpUserTray';
const classes = {
    drawerUserContainer: `${PREFIX}-drawerUserContainer`,
    listItemIconSize: `${PREFIX}-listItemIconSize`,
    desktopUserTray: `${PREFIX}-desktopUserTray`,
    userName: `${PREFIX}-userName`,
    userEmail: `${PREFIX}-userEmail`,
    userRoleDetail: `${PREFIX}-userRoleDetail`,
    logoutIcon: `${PREFIX}-logoutIcon`,
    logout: `${PREFIX}-logout`,
    logoutText: `${PREFIX}-logoutText`,
    noTopPadding: `${PREFIX}-noTopPadding`,
    drawerUserContainerWrapper: `${PREFIX}-drawerUserContainerWrapper`
};

const StyledMenu = styled(Menu)(({ theme }) => ({
    [`&.${classes.desktopUserTray}`]: {
        '& .MuiPaper-root': {
            overflow: 'visible',
            marginTop: theme.spacing(1.25),
            minWidth: theme.spacing(15.625),
            borderRadius: theme.spacing(0.5),
            '&:before': {
                content: '""',
                position: 'absolute',
                top: theme.spacing(-1.25),
                right: theme.spacing(0.75),
                borderStyle: 'solid',
                borderWidth: `0 ${theme.spacing(1.25)} ${theme.spacing(
                    1.5
                )} ${theme.spacing(1.25)}`,
                borderColor: `transparent transparent ${colors.white} transparent`
            }
        },
        '& .MuiTypography-root': {
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiBox-root': {
                minHeight: theme.spacing(6.25),
                width: '100%',
                marginLeft: 0
            }
        }
    },
    [`&.${classes.menuPaddingTop}`]: {
        paddingTop: theme.spacing(1.25)
    },
    [`& .${classes.logoutIcon}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.logout}`]: {
        paddingLeft: theme.spacing(0.75)
    },
    [`& .${classes.listItemIconSize}`]: {
        minWidth: theme.spacing(4.5),
        minHeight: theme.spacing(3.5)
    },
    [`& .${classes.noTopPadding}`]: {
        padding: theme.spacing(0, 2, 1.75, 2),
        '&:hover': {
            backgroundColor: colors.white
        }
    },
    [`& .${classes.logoutText}, .MuiTypography-root`]: {
        fontSize: theme.spacing(1.75)
    }
}));

const StyledUserContainer = styled(Box)(({ theme }) => ({
    [`&.${classes.drawerUserContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
        minHeight: theme.spacing(14.375),
        width: '100%',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3, 0, 3)
        }
    },
    [`& .${classes.userName}`]: {
        fontSize: theme.spacing(1.75),
        color: colors.loginGrad2,
        fontWeight: 600,
        lineHeight: theme.spacing(2.5),
        letterSpacing: theme.spacing(0.02),
        padding: theme.spacing(1, 0, 0, 0),
        [theme.breakpoints.down('md')]: {
            color: colors.white,
            fontSize: theme.spacing(2.6)
        }
    },
    [`& .${classes.userEmail}`]: {
        marginBottom: theme.spacing(0.875),
        fontSize: theme.spacing(1.75),
        color: colors.loginGrad2,
        fontWeight: 400,
        lineHeight: theme.spacing(2.5),
        letterSpacing: theme.spacing(0.02),
        padding: theme.spacing(0, 0, 1.5, 0),
        [theme.breakpoints.down('md')]: {
            color: colors.white
        }
    },
    [`& .${classes.userRoleDetail}`]: {
        color: colors.loginGrad2,
        backgroundColor: colors.white,
        fontWeight: 400,
        borderRadius: theme.spacing(0.25),
        padding: theme.spacing(0, 0.5),
        width: 'fit-content',
        fontSize: theme.spacing(1.375),
        border: `${theme.spacing(0.125)} solid`,
        [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent',
            fontWeight: 500
        }
    }
}));

const HnpUserTray = ({
    anchorEl,
    handleUserTrayClose,
    handleLogout,
    setAnchorEl
}) => {
    const theme = useTheme();
    const { appStore } = useContext(HnpContext);
    const { userDetails } = appStore;
    const openUserTray = Boolean(anchorEl);

    return (
        <StyledMenu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={openUserTray}
            className={classes.desktopUserTray}
            onClose={handleUserTrayClose}
        >
            <MenuItem
                onClick={handleUserTrayClose}
                className={classes.noTopPadding}
            >
                <ListItemText
                    primary={
                        <StyledUserContainer
                            className={classes.drawerUserContainer}
                        >
                            <Box
                                className={classes.userName}
                                title={userDetails && userDetails.name}
                            >
                                {userDetails.name || ''}
                            </Box>
                            <Box className={classes.userEmail}>
                                {userDetails.email}
                            </Box>
                            <Box className={classes.userRoleDetail}>
                                {getDisplayUserRole(userDetails.role)}
                            </Box>
                        </StyledUserContainer>
                    }
                />
            </MenuItem>
            {getFilteredRoles(userDetails.role) ? (
                <Box>
                    <Divider />
                    <Box
                        sx={{
                            '&:hover': {
                                backgroundColor: colors.grey13
                            }
                        }}
                    >
                        <HnpDemo closeUserTray={setAnchorEl} />
                    </Box>
                </Box>
            ) : null}
            <MenuItem
                onClick={handleLogout}
                sx={{
                    marginTop: theme.spacing(0),
                    '&:hover': {
                        backgroundColor: colors.grey13
                    }
                }}
            >
                <ListItemIcon
                    className={`${classes.listItemIconSize} ${classes.logoutIcon}`}
                >
                    <LogoutIcon
                        sx={{
                            color: colors.loginGrad2,
                            marginLeft: theme => theme.spacing(-0.25)
                        }}
                    />
                </ListItemIcon>
                <ListItemText
                    primary="Sign out"
                    className={`${classes.logout} ${classes.logoutText}`}
                    style={{ fontSize: theme.spacing(1.75) }}
                />
            </MenuItem>
        </StyledMenu>
    );
};

HnpUserTray.propTypes = {
    handleUserTrayClose: PropTypes.func,
    handleLogout: PropTypes.func,
    setAnchorEl: PropTypes.func,
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default HnpUserTray;
