import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { HnpContext } from '../../contexts/HnpContext';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import {
    hnpRoutes,
    hclNowLinks
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import AppDown from '../../assets/images/app-down.png';

const PREFIX = 'HnpMaintenance';

const classes = {
    mainContainer: `${PREFIX}-mainContainer`,
    container: `${PREFIX}-container`,
    content: `${PREFIX}-content`,
    msg: `${PREFIX}-msg`,
    img: `${PREFIX}-img`,
    imgBox: `${PREFIX}-imgBox`,
    unavailableMsgEnv: `${PREFIX}-unavailableMsgEnv`,
    backSoonMsgEnv: `${PREFIX}-backSoonMsgEnv`,
    contactMsgEnv: `${PREFIX}-contactMsgEnv`,
    contactMsgLinkEnv: `${PREFIX}-contactMsgLinkEnv`,
    msgGrid: `${PREFIX}-msgGrid`,
    imgGrid: `${PREFIX}-imgGrid`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.mainContainer}`]: {
        height: 'calc(100vh - 118px)',
        width: '100%',
        backgroundColor: colors.white,
        display: 'flex',
        justifyContent: 'center'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.between('sm', 'lg')]: {
            marginTop: '27%'
        },
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: theme.spacing(0)
        }
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        marginBottom: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center'
        }
    },
    [`& .${classes.msg}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
            marginTop: theme.spacing(5)
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            order: 2,
            marginTop: theme.spacing(8)
        }
    },
    [`& .${classes.img}`]: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            minWidth: theme.spacing(25)
        }
    },
    [`& .${classes.imgBox}`]: {
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            minWidth: theme.spacing(50),
            marginTop: '20%'
        },
        [theme.breakpoints.down('md')]: {
            order: 1,
            minWidth: theme.spacing(37.5),
            marginTop: '20%'
        },
        [theme.breakpoints.down('sm')]: {
            order: 1,
            alignItems: 'center',
            padding: theme.spacing(0, 3, 1),
            width: '100%',
            marginTop: '20%',
            height: theme.spacing(25),
            minWidth: theme.spacing(25)
        }
    },
    [`& .${classes.unavailableMsgEnv}`]: {
        fontFamily: 'HCLTech Roobert',
        fontWeight: 700,
        lineHeight: 1.2,
        marginBottom: theme.spacing(5),
        [theme.breakpoints.up('md')]: {
            fontSize: theme.spacing(6.75),
            marginBottom: theme.spacing(7.5)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(6),
            marginBottom: theme.spacing(4.375)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(3.5),
            marginBottom: theme.spacing(5)
        }
    },
    [`& .${classes.backSoonMsgEnv}`]: {
        marginBottom: theme.spacing(7.5),
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            fontSize: theme.spacing(4),
            marginBottom: theme.spacing(7.5)
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(3.5),
            marginBottom: theme.spacing(3.125)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(2.5),
            marginBottom: theme.spacing(3.75)
        }
    },
    [`& .${classes.contactMsgEnv}`]: {
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.spacing(3)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(2)
        }
    },
    [`& .${classes.contactMsgLinkEnv}`]: {
        textDecoration: 'underline',
        color: colors.turquoise1,
        fontWeight: 600,
        fontSize: theme.spacing(3),
        fontFamily: 'inherit',
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
        padding: 0,
        '&:hover': {
            backgroundColor: 'none'
        },
        '&.active': {
            backgroundColor: 'none',
            boxShadow: '0 2px 0 0 #000'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(2.5)
        }
    },
    [`& .${classes.msgGrid}`]: {
        [theme.breakpoints.down('sm')]: {
            order: 2
        }
    },
    [`& .${classes.imgGrid}`]: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
            padding: theme.spacing(0)
        }
    }
}));

const HnpMaintenance = () => {
    const navigate = useNavigate();
    const currentUrl = useLocation().pathname;
    const { appStore } = useContext(HnpContext);
    const handleRedirect = url => e => {
        e.preventDefault();
        safeRedirect(url, true);
    };
    useEffect(() => {
        // Redirect to home page (default) if the user is logged in and API service is not down
        if (
            !appStore.isAPISvcDown &&
            currentUrl === hnpRoutes.maintenancePage
        ) {
            navigate(hnpRoutes.environmentPage);
        } else if (appStore.isAPISvcDown) {
            navigate(hnpRoutes.maintenancePage);
        }
    }, [appStore.isAPISvcDown]);

    return (
        <StyledBox className={classes.mainContainer}>
            <Box className={classes.container} sx={sxStyles.appContainer}>
                <Grid container className={classes.content}>
                    <Grid xs={12} sm={7} className={classes.msgGrid}>
                        <Box className={classes.msg}>
                            <Typography
                                variant="h3"
                                className={classes.unavailableMsgEnv}
                            >
                                HCL Now Application is currently unavailable!
                            </Typography>
                            <Typography
                                variant="h4"
                                className={classes.backSoonMsgEnv}
                            >
                                Sorry for the inconvenience.
                                <br />
                                It will be back soon
                            </Typography>
                            <Typography
                                variant="h5"
                                className={classes.contactMsgEnv}
                            >
                                <Box component="span">
                                    For details, contact{' '}
                                </Box>
                                <Box
                                    className={classes.contactMsgLinkEnv}
                                    sx={sxStyles.tabFocus}
                                    component="button"
                                    onClick={handleRedirect(hclNowLinks.hclCSM)}
                                >
                                    HCL Support
                                </Box>
                                <Box component="span">.</Box>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={5} className={classes.imgGrid}>
                        <Box className={classes.imgBox}>
                            <img
                                src={AppDown}
                                className={classes.img}
                                alt="Under Maintenance"
                            ></img>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </StyledBox>
    );
};

export default HnpMaintenance;
