import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { hnpOpenCases } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import { HnpContext } from '../../contexts/HnpContext';
import { StyledCard } from '../HnpMuiComponents';
import HnpOpenCasesChart from '../HnpOpenCasesChart';

const PREFIX = 'HnpOpenCases';

const classes = {
    chartParent: `${PREFIX}-chartParent`,
    noCases: `${PREFIX}-noCases`,
    chartIcon: `${PREFIX}-chartIcon`,
    header: `${PREFIX}-header`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.chartParent}`]: {
        display: 'flex',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.25),
        height: theme.spacing(17.5),
        '&:last-child': {
            paddingBottom: theme.spacing(1.5)
        }
    },
    [`& .${classes.noCases}`]: {
        marginLeft: theme.spacing(0.5)
    },
    [`& .${classes.chartIcon}`]: {
        display: 'flex',
        fontSize: theme.spacing(4.6),
        backgroundColor: colors.black5,
        borderRadius: theme.spacing(0.625),
        color: colors.white,
        padding: 3
    },
    [`& .${classes.header}`]: {
        '& .MuiCardHeader-avatar': {
            margin: theme.spacing(0.25, 2.9, 0, 0)
        },
        '& .MuiCardHeader-subheader': {
            lineHeight: 1.43
        }
    }
}));

let HnpOpenCases = () => {
    const { appStore, setAppStore } = useContext(HnpContext);
    const { filters, openCases, hnpApiUtils } = appStore || {};
    const totalCount =
        openCases.totalIncidents +
        openCases.totalServiceRequests +
        openCases.totalChangeRequests;

    const openCasesCount = () => {
        async function caseAsync() {
            const casesCounts = {
                incidents: 0,
                serviceRequest: 0,
                changeRequests: 0
            };
            const casesResponses = await openCasesFetch();
            casesCounts.incidents = casesResponses.incident;
            casesCounts.serviceRequests = casesResponses.serviceRequest;
            casesCounts.changeRequests = casesResponses.changeRequest;

            setAppStore(prevState => ({
                ...prevState,
                openCases: {
                    totalIncidents: casesCounts.incidents,
                    totalServiceRequests: casesCounts.serviceRequests,
                    totalChangeRequests: casesCounts.changeRequests
                }
            }));
        }
        caseAsync();
    };
    const openCasesFetch = async () => {
        // Get Cases metrics counts for open cases
        const [selectedHCN] = appStore.filters.hcn;
        const openCaseSummary = await hnpApiUtils.getOpenCasesCountSummary({
            hcn: selectedHCN.value
        });
        const incident = openCaseSummary.data.totalIncidents;
        const serviceRequest = openCaseSummary.data.totalServiceRequests;
        const changeRequest = openCaseSummary.data.totalChangeRequests;

        return { incident, serviceRequest, changeRequest };
    };

    useEffect(() => {
        // Fetch open case summary regarding available products for the customer. It will update the UI asynchrounously.
        openCasesCount();
    }, [filters && filters.hcn]);

    useEffect(() => {
        return () => {
            // Reset the openCases on component unmount.
            setAppStore(prevState => ({
                ...prevState,
                openCases: {
                    totalIncidents: null,
                    totalServiceRequests: null,
                    totalChangeRequests: null
                }
            }));
        };
    }, []);

    return (
        openCases &&
        openCases.totalIncidents !== null &&
        openCases.totalIncidents !== undefined && (
            <StyledGrid xs={12} sm={6} lg={3}>
                <StyledCard sx={sxStyles.envDetailsCard}>
                    <CardHeader
                        className={classes.header}
                        avatar={<DataUsageIcon className={classes.chartIcon} />}
                        title={hnpOpenCases.title}
                        subheader={hnpOpenCases.subTitle}
                    />
                    <CardContent className={classes.chartParent}>
                        {totalCount !== 0 ? (
                            <HnpOpenCasesChart />
                        ) : (
                            <Typography
                                variant="body2"
                                className={classes.noCases}
                                sx={[
                                    sxStyles.verticalEllipsis,
                                    sxStyles.italicText
                                ]}
                            >
                                No cases to be displayed.
                            </Typography>
                        )}
                    </CardContent>
                </StyledCard>
            </StyledGrid>
        )
    );
};

export default HnpOpenCases;
