import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StyledSnackBar } from '../HnpMuiComponents';
import {
    hnpDemoModeAlerts,
    hnpMetricsAlert
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import { HnpContext } from '../../contexts/HnpContext';
import { useIsMount } from '../../customHooks/customHooks';

const PREFIX = 'HnpDemoMsg';

const classes = {
    demoMsg: `${PREFIX}-demoMsg`
};

const StyledHnpDemoMsg = styled(StyledSnackBar)(({ theme }) => ({
    [`&.${classes.demoMsg}`]: {
        right: theme.spacing(5),
        '& .MuiSnackbarContent-root': {
            minWidth: theme.spacing(23),
            maxWidth: theme.spacing(25)
        },
        [theme.breakpoints.down('md')]: {
            right: theme.spacing(0)
        }
    }
}));

const HnpDemoMsg = () => {
    const theme = useTheme();
    const isMountDemoOn = useIsMount();
    const isMountDemoOff = useIsMount();
    const [demoOn, setDemoOn] = useState(false);
    const [demoOff, setDemoOff] = useState(false);
    const isScreenSizeMedium = useMediaQuery(theme.breakpoints.up('md'));
    const { appStore } = useContext(HnpContext);
    const { isDemoMode } = appStore || {};

    useEffect(() => {
        if (!isMountDemoOff && !isDemoMode) {
            setDemoOff(true);
        }
        if (!isMountDemoOn && isDemoMode) {
            setDemoOn(true);
        }
    }, [isDemoMode]);

    const handleDemoOn = () => {
        setDemoOn(false);
    };

    const handleDemoOff = () => {
        setDemoOff(false);
    };

    return (
        <StyledHnpDemoMsg
            open={isDemoMode ? demoOn : demoOff}
            onClose={isDemoMode ? handleDemoOn : handleDemoOff}
            className={classes.demoMsg}
            message={
                isDemoMode
                    ? hnpDemoModeAlerts.demoOnAlert
                    : hnpDemoModeAlerts.demoOffAlert
            }
            autoHideDuration={isScreenSizeMedium ? 5000 : 4000}
            anchorOrigin={
                isScreenSizeMedium
                    ? hnpMetricsAlert.topRightPlacement
                    : hnpMetricsAlert.bottomCenterPlacement
            }
        />
    );
};

export default HnpDemoMsg;
