import React, { useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { HnpContext } from '../../contexts/HnpContext';
import HnpCustomerName from '../HnpCustomerName';
import HnpFilters from '../HnpFilters';
import HnpMessage from '../HnpMessage';
import HnpStakeholderMobile from '../HnpStakeholderMobile';
import {
    hnpNoStakehMsg,
    hnpLoginStatus,
    hnpFilters
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import { addUiPropsInStakehData } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import HnpStakeholderDesktop from '../HnpStakeholderDesktop';

const PREFIX = 'HnpStakeholders';

const classes = {
    stakehContainer: `${PREFIX}-stakehContainer`,
    stakehContent: `${PREFIX}-stakehContent`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.stakehContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2.5, 0, 3),
        [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 118px)'
        }
    },
    [`& .${classes.stakehContent}`]: {
        color: colors.grey1
    }
}));

const HnpStakeholders = () => {
    const theme = useTheme();
    const deviceWidth = useMediaQuery(theme.breakpoints.up('lg'), {
        noSsr: true
    });
    const { appStore, setAppStore } = useContext(HnpContext);
    const { selectedOption } = appStore;
    const {
        isLogin,
        stakeholdersDetails,
        filters,
        orderDetails,
        hnpApiUtils,
        provisionedEnvs
    } = appStore;
    // Filter names
    const { hcnName, partNumberName, notificationName, roleName } = hnpFilters;

    useEffect(() => {
        // Resetting the priorityAlert to maintain the page switch and filter changes
        if (selectedOption !== 'preselected@') {
            setAppStore(prevState => ({
                ...prevState,
                priorityAlert: []
            }));
        }
    }, [selectedOption]);

    useEffect(() => {
        if (selectedOption !== null) {
            const fetchStakholderData = async () => {
                const filterKeys = [
                    hcnName,
                    partNumberName,
                    notificationName,
                    roleName
                ];
                // Filter the stakeholders details if the user is on stakeholders page
                const stakehStates = {};
                // Build query parameter object
                const stakehParams = Object.keys(filters)
                    .filter(key => filterKeys.includes(key))
                    .reduce((obj, key) => {
                        if (filters[key].length) {
                            obj[key] = filters[key]
                                .map(option =>
                                    key === hcnName ? option.value : option.data
                                )
                                .join(',');
                        }

                        return obj;
                    }, {});

                // Get filtered stakeholders details
                const stakehRes = await hnpApiUtils.getStakeholder({
                    $queryParameters: stakehParams
                });

                stakehStates.stakeholdersDetails = addUiPropsInStakehData(
                    orderDetails,
                    stakehRes.data
                );

                setAppStore(prevState => ({
                    ...prevState,
                    ...stakehStates
                }));
            };

            fetchStakholderData();
        }
    }, [provisionedEnvs]);

    return isLogin === hnpLoginStatus.loggedIn ? (
        <StyledBox
            className={classes.stakehContainer}
            sx={sxStyles.appContainer}
        >
            <HnpCustomerName />
            {/* Render the Fitler component */}
            <HnpFilters />
            {stakeholdersDetails && stakeholdersDetails.length ? (
                <Box className={classes.stakehContent} sx={sxStyles.mt3}>
                    {deviceWidth ? (
                        <HnpStakeholderDesktop />
                    ) : (
                        <HnpStakeholderMobile />
                    )}
                </Box>
            ) : (
                <HnpMessage msg={hnpNoStakehMsg} />
            )}
        </StyledBox>
    ) : null;
};

export default HnpStakeholders;
