import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import Banner from '../../../assets/images/banner.png';
import { HnpContext } from '../../../contexts/HnpContext';

const HnpBanner = ({ children }) => {
    const { appStore } = useContext(HnpContext);
    const { userDetails } = appStore;
    const location = useLocation().pathname;

    return (
        <>
            {(userDetails || location !== '/login') && (
                <Box
                    sx={theme => ({
                        height: theme => theme.spacing(22.5),
                        width: '100%',
                        position: 'absolute',
                        top: '0',
                        backgroundImage: `url(${Banner})`,
                        backgroundSize: { sm: 'auto', md: 'contain' },
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: { sm: 'left', md: 'right' },
                        backgroundColor: colors.grey10,
                        [theme.breakpoints.down('sm')]: {
                            height: theme.spacing(41.625),
                            backgroundImage: 'none'
                        }
                    })}
                ></Box>
            )}
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'stretch'
                }}
            >
                {children}
            </Box>
        </>
    );
};
HnpBanner.propTypes = {
    children: PropTypes.node
};

export default HnpBanner;
