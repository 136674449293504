import React from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

const PREFIX = 'HnpNotFound';

const classes = {
    root: `${PREFIX}-root`,
    errorInfo: `${PREFIX}-errorInfo`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '15%',
        [theme.breakpoints.between('sm', 'md')]: {
            paddingTop: '25%'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(51.625)
        }
    },
    [`& .${classes.errorInfo}`]: {
        fontSize: theme.spacing(7.25),
        color: colors.black,
        fontFamily: 'HCLTech Roobert',
        fontWeight: '700',
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.spacing(6)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(4.5)
        }
    }
}));

const HnpNotFound = () => {
    return (
        <Root className={classes.root}>
            <p className={classes.errorInfo}>404 - Page Not Found</p>
        </Root>
    );
};

export default HnpNotFound;
