import {
    sortArrayOfObjects,
    getEnvStatus
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import Workload from '../images/workload-automation.png';

export const envUrlandPort = {
    hnp: {
        ui: `${window.location.protocol}//${window.location.hostname}:${
            window.location.port === '' ? 443 : 9343
        }`,
        api: process.env.REACT_APP_API_URL
    }
};

// Returns a random integer between min (inclusive) and max (inclusive)
export const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

// Get min date as today and disable the past dates in calendar
// Format as YYYY-MM-DD
export const getDateAsToday = () => {
    const dateToday = new Date();
    const year = dateToday.getFullYear();
    let month = dateToday.getMonth() + 1;
    let day = dateToday.getDate();

    if (month < 10) {
        month = '0' + month.toString();
    }
    if (day < 10) {
        day = '0' + day.toString();
    }

    return `${year}-${month}-${day}`;
};

export const getFutureDateFromToday = (interval = 3) => {
    let dateToday = new Date();
    dateToday.setMonth(dateToday.getMonth() + interval);
    dateToday = dateToday.toLocaleDateString();
    const dateTodayArr = dateToday.split('/');
    let month = dateTodayArr[0];
    let day = dateTodayArr[1];

    if (parseInt(month) < 10) {
        month = '0' + month.toString();
    }
    if (parseInt(day) < 10) {
        day = '0' + day.toString();
    }

    return `${dateTodayArr[2]}-${month}-${day}`;
};

// Just to log formData values; keeping as handy tool (for dev only)
export const consoleFormData = formData => {
    for (let [key, value] of formData) {
        console.log('key =', key, ', value =', value);
    }
};

export const hnpFeatureFlagsData = [
    { label: 'Show Terms page', name: 'enableTermsPage' },
    { label: 'Show Search box', name: 'showSearchBox' },
    { label: 'Show Announcements icon', name: 'showAnnouncementsIcon' },
    { label: 'Show Notifications icon', name: 'showNotificationsIcon' }
];

export const sofySolutions = [
    {
        logo: require('../images/hcl-commerce.svg'),
        name: 'HCL Commerce',
        env: 'Prod',
        version: 'Version 9.1.13',
        provisioned: '2021-02-11T11:56:17.307Z',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae atque sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae atque sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae atque sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit.',
        extHomeLink: 'https://www.hcltechsw.com/commerce',
        extConsoleLink: ''
    },
    {
        logo: Workload,
        name: 'Workload Automation',
        env: 'Prod',
        version: 'Version 1.1.0',
        provisioned: '2021-03-11T11:56:17.307Z',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae atque sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae atque sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae atque sit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit.',
        solConsoleMessage:
            'Since this product came through HCL SoFy, the Solution Console is accessible to view Product info, Infra & Metrics.',
        extHomeLink: 'https://www.hcltechsw.com/workload-automation',
        extConsoleLink: 'https://sofy-console.35.226.10.151.nip.io/#/dashboard'
    },
    {
        logo: require('../images/unica.svg'),
        name: 'Unica',
        env: 'QA',
        version: 'Version 2.0.0',
        provisioned: '2021-04-11T11:56:17.307Z',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit.',
        extHomeLink: 'https://www.hcltechsw.com/unica',
        extConsoleLink: ''
    },
    {
        logo: require('../images/hcl-domino.svg'),
        name: 'HCL Domino',
        env: 'Dev',
        version: 'Version 3.9.1',
        provisioned: '2021-04-22T11:56:17.307Z',
        desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos eveniet repudiandae iusto atque aspernatur. Sint expedita ducimus harum beatae sit.',
        extHomeLink: 'https://www.hcltechsw.com/domino',
        extConsoleLink: ''
    }
];

// Solution filter options
const hnpSolutionsData = [
    {
        value: 'jjill-commerce',
        label: 'jjill-commerce'
    },
    {
        value: 'solution-2',
        label: 'solution-2'
    },
    {
        value: 'solution-3',
        label: 'solution-3'
    },
    {
        value: 'solution-4',
        label: 'solution-4'
    },
    {
        value: 'solution-5',
        label: 'solution-5'
    }
];
// Sort and export the solution options
export const hnpSolutions = sortArrayOfObjects(hnpSolutionsData);

// Order ID filter options
const hnpCustomerNumberData = [
    {
        value: 'ASDFGHJKL1290',
        label: 'ASDFGHJKL1290'
    }
];
// Sort and export the order id options
export const hnpCustomerNumber = sortArrayOfObjects(hnpCustomerNumberData);

// Order ID filter options
const hnpOrderIdData = [
    {
        value: 'HCLNOW-7886-2240',
        label: 'HCLNOW-7886-2240'
    },
    {
        value: 'HCLNOW-7886-2241',
        label: 'HCLNOW-7886-2241'
    },
    {
        value: 'HCLNOW-7886-2242',
        label: 'HCLNOW-7886-2242'
    },
    {
        value: 'HCLNOW-7886-2243',
        label: 'HCLNOW-7886-2243'
    },
    {
        value: 'HCLNOW-7886-2244',
        label: 'HCLNOW-7886-2244'
    }
];
// Sort and export the order id options
export const hnpOrderId = sortArrayOfObjects(hnpOrderIdData);

// Product filter options
const hnpProductsData = [
    {
        value: 'HCL Commerce',
        label: 'HCL Commerce'
    },
    {
        value: 'Workload Automation',
        label: 'Workload Automation'
    },
    {
        value: 'Unica',
        label: 'Unica'
    },
    {
        value: 'Digital Solutions',
        label: 'Digital Solutions'
    },
    {
        value: 'HCL Domino',
        label: 'HCL Domino'
    }
];
// Sort and export the products options
export const hnpProducts = sortArrayOfObjects(hnpProductsData);

// Environment type filter options
const hnpEnvTypesData = [
    {
        value: 'Prod',
        label: 'Prod'
    },
    {
        value: 'Pre-Prod',
        label: 'Pre-Prod'
    },
    {
        value: 'QA',
        label: 'QA'
    },
    {
        value: 'Dev',
        label: 'Dev'
    }
];
// Sort and export the environment types options
export const hnpEnvTypes = sortArrayOfObjects(hnpEnvTypesData);

// Flatten environment data from [GET]/order response
export const updateEnvsWithProps = (orders, priorityCases) =>
    (orders &&
        orders.reduce((arr, order) => {
            order.orders.forEach(({ lineItems }) => {
                // Update Customer name
                const customerName = order.customerAccount.name;
                const getPriorityCasesForHcn =
                    priorityCases &&
                    priorityCases.data.result.filter(
                        obj => obj.hcn === order.customerAccount.hcn
                    )[0];
                lineItems.forEach(({ environments, shortName, ...rest }) => {
                    environments.forEach(environment => {
                        const {
                            provisionedEnvironment: { type }
                        } = environment;
                        // Update environment display name
                        const updatedEnvName =
                            type === 'dr' || type === 'qa'
                                ? type.toUpperCase()
                                : `${type[0].toUpperCase()}${type.slice(1)}`;
                        // Update product display name
                        const updatedDisplayName = rest.name
                            .replace(/(Enterprise|Platform)|(on Now)/gim, '')
                            .trim();
                        // Lowercased display name
                        const comparable = updatedDisplayName.toLowerCase();
                        const envState =
                            getPriorityCasesForHcn &&
                            getEnvStatus(getPriorityCasesForHcn, type);
                        // Add product logo icon
                        const logo = comparable.includes('commerce')
                            ? require('../images/hcl-commerce.svg').default
                            : comparable.includes('workload')
                            ? Workload
                            : comparable.includes('unica')
                            ? require('../images/unica.svg').default
                            : comparable.includes('domino')
                            ? require('../images/hcl-domino.svg').default
                            : '';
                        // Add message for solution console redirection (if sofy solution)
                        const solConsoleMessage = comparable.includes(
                            'workload'
                        )
                            ? 'Since this product came through HCL SoFy, the Solution Console is accessible to view Product information, Cloud Native Infra & Product Health Metrics.'
                            : type === 'prod'
                            ? 'Since this product did not come through HCL SoFy, the Solution Console is not accessible. However, the metrics dashboards can be viewed through the other tiles on this page.'
                            : 'Since this product did not come through HCL SoFy, the Solution Console is not accessible.';
                        // Push to final option array
                        arr.push({
                            ...rest,
                            ...environment,
                            shortName,
                            customerName,
                            provisionedEnvironment: {
                                ...environment.provisionedEnvironment,
                                name: updatedEnvName
                            },
                            name: updatedDisplayName,
                            solConsoleMessage,
                            logo,
                            envState
                        });
                    });
                });
            });

            return arr;
        }, [])) ||
    [];

// Empty response messages
export const hnpNoEnvMsg =
    'No environments found for the selected filter(s). For further clarifications, contact HCL Support.';
export const hnpNoMetricsMsg =
    'No metrics found for the selected filter(s). For further clarifications, contact HCL Support.';
export const hnpNoStakehMsg =
    'No stakeholders found for the selected filter(s). For further clarifications, contact HCL Support.';
export const hnpNoStakehMsgTile =
    'No stakeholders found for the selected filter(s). Contact HCL Support.';

// Notification type filter options
export const hnpNotificationTypes = [
    {
        value: 'Performance',
        label: 'Performance',
        data: 'appPerformance'
    },
    {
        value: 'CritSits',
        label: 'CritSits',
        data: 'critSits'
    },
    {
        value: 'Maintenance',
        label: 'Maintenance',
        data: 'maintenance'
    }
];

// Organization type filter options
export const hnpOrganization = [
    {
        value: 'Customer',
        label: 'Customer',
        data: 'customer'
    },
    {
        value: 'Partner',
        label: 'Partner',
        data: 'partner'
    },
    {
        value: 'HCL',
        label: 'HCL',
        data: 'hcl'
    }
];

// Routes matcher values
export const hnpRoutes = {
    stakeHolderPage: '/stakeholders',
    environmentPage: '/',
    loginPage: '/login',
    metricsPage: '/metrics',
    guidePage: '/guide',
    termsPage: '/terms',
    helpCenterPage: '/help-center',
    maintenancePage: '/maintenance',
    newFeaturePage: '/new-features',
    wildCardPage: '*'
};

// Application version number
export const hnpVersion = '1.1.4';

//Application name
export const hnpName = 'HCL Now';

//hcltechsw link
export const hclswUrl = 'https://www.hcltechsw.com/';

// HCL Now Support Details component title
export const hnpSupportTitle = 'Help Center';

// HCL Now Help Center section details
export const helpCenterSection = [
    {
        text: 'Need to report an issue or get in touch with us? Open a ticket and HCL Support will reach out to you as soon as possible.',
        buttonText: 'Open Support Ticket',
        link: 'https://support.hcltechsw.com/csm'
    },
    {
        text: ' For additional assistance, you can also contact HCL Support on these phone numbers:',
        buttonText: 'Contact Helpdesk',
        link: 'https://support.hcltechsw.com/csm?id=kb_article&sysparm_article=KB0010151'
    },
    {
        text: 'Submit your ideas in the HCL Software Customer Idea Portal.',
        buttonText: 'Submit Idea',
        link: 'https://www.hcltechsw.com/resources/submit-idea?referrer=www.hcltechsw.com'
    },
    {
        text: 'View detailed documentation for all HCL Software products.',
        buttonText: 'View Documentation',
        link: 'https://help.hcltechsw.com/ '
    }
];

// Navigation bar items
export const hnpNavigationItems = [
    {
        title: 'Environments',
        linkTo: '/',
        visibleOnDesktop: true
    },
    {
        title: 'Metrics',
        linkTo: '/metrics',
        visibleOnDesktop: true
    },
    {
        title: 'Stakeholders',
        linkTo: '/stakeholders',
        visibleOnDesktop: true
    },
    {
        title: 'Guide',
        linkTo: '/guide',
        visibleOnDesktop: true
    },
    {
        title: 'Help Center',
        linkTo: '/help-center',
        visibleOnDesktop: false
    }
];

// Environment constants
export const hnpEnvironments = {
    prod: 'prod',
    qa: 'qa',
    dev: 'dev',
    dr: 'dr',
    preProd: 'pre-prod'
};

// Filter values
export const hnpFilters = {
    hcnName: 'hcn',
    orderIdName: 'orderId',
    partNumberName: 'partNumber',
    envTypeName: 'envType',
    notificationName: 'notification',
    roleName: 'role'
};

// Open cases tile labels
export const hnpOpenCases = {
    title: 'All Open Cases',
    subTitle: 'Last 1 year'
};

// Metrics Duration Values and Labels
export const hnpDurations = {
    thisMonth: { label: 'This Month', value: '&from=now%2FM&to=now' },
    lastMonth: { label: 'Last Month', value: '&from=now-1M%2FM&to=now-1M%2FM' },
    past30Days: { label: 'Last 30 days', value: '&from=now-30d&to=now' },
    past60Days: { label: 'Last 60 days', value: '&from=now-60d&to=now' },
    past90Days: { label: 'Last 90 days', value: '&from=now-90d&to=now' }
};

//User Login Status
export const hnpLoginStatus = {
    initialState: 'initalState',
    loggedIn: 'loggedIn',
    loggedOut: 'loggedOut'
};
// Metrics page Snackbar configuration
export const hnpMetricsAlert = {
    message: 'Filters pre-selected for',
    topRightPlacement: { vertical: 'top', horizontal: 'right' },
    bottomCenterPlacement: { vertical: 'bottom', horizontal: 'center' }
};

//Now Order Types
export const hnpNowOrderType = {
    hclSoftwareProduct: 'HCL Software Product',
    hclNowInfrastructure: 'HCL Now Infrastructure',
    hclNowManagedServices: 'HCL Now Managed Services',
    hclSoftwareSS: 'HCL Software S&S'
};

// HCL Now application external links
export const hclNowLinks = {
    hclCSM: 'https://support.hcltechsw.com/csm'
};

// Demo mode alert messages
export const hnpDemoModeAlerts = {
    turnOffFilter:
        'Once you turn off demo mode, you will be able to select other customers.',
    dialogBoxTitle: 'Turn off demo mode',
    demoModeDisable: 'Select one customer to turn ON demo mode',
    turnOffDemo: 'Turn OFF after demo',
    turnOnDemo: 'Turn ON before starting demo',
    demoOnAlert: 'Demo mode turned ON',
    demoOffAlert: 'Demo mode turned OFF'
};
