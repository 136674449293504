import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { StyledTooltip } from '../../HnpMuiComponents';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

const sxStyle = {
    envStatusIcon: theme => ({
        fontSize: theme.spacing(3),
        color: theme.palette.success.main,
        '&.warning': {
            color: theme.palette.warning.main
        },
        '&.error': {
            color: theme.palette.error.main
        },
        '&.unknown': {
            color: colors.grey2
        }
    })
};
const HnpEnvStatusIcon = props => {
    const theme = useTheme();
    const envState = props.envState;

    return (
        <StyledTooltip
            title={envState.message || ''}
            placement="bottom-end"
            styles={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                whiteSpace: 'pre-line'
            }}
            arrow
        >
            {envState.status === '' ? (
                <CheckCircleIcon
                    className={envState.status}
                    sx={sxStyle.envStatusIcon}
                />
            ) : (
                <ErrorIcon
                    className={envState.status}
                    sx={sxStyle.envStatusIcon}
                />
            )}
        </StyledTooltip>
    );
};

HnpEnvStatusIcon.propTypes = {
    envState: PropTypes.object
};

export default HnpEnvStatusIcon;
