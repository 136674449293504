import React from 'react';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => {
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return {
        color: colors.black4,
        fontSize: theme.spacing(1.75),
        fontWeight: '600',
        lineHeight: theme.spacing(2.5),
        padding: matches ? theme.spacing(0, 5) : '0'
    };
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    color: colors.black6,
    fontSize: theme.spacing(2.5),
    fontWeight: '400',
    lineHeight: theme.spacing(3.75),
    padding: theme.spacing(1.75, 1, 1.5, 2)
}));

const PREFIX = 'HnpDialog';

const classes = {
    alert: `${PREFIX}-alert`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`&.${classes.alert}`]: {
        '& .MuiDialog-paper': {
            width: '75%',
            borderRadius: theme.spacing(0.625),
            borderTop: `${theme.spacing(1)} solid ${colors.loginGrad2}`
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(0)
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiDialog-paper': {
                width: theme.spacing(40)
            }
        }
    }
}));

const HnpDialog = ({ dialogOn, title, content, actions }) => {
    return (
        <StyledDialog
            disableEnforceFocus
            open={dialogOn}
            className={classes.alert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <StyledDialogTitle id="alert-dialog-title">
                {title}
            </StyledDialogTitle>
            <DialogContent>
                <StyledDialogContentText id="alert-dialog-description">
                    {content}
                </StyledDialogContentText>
            </DialogContent>
            <DialogActions>{actions}</DialogActions>
        </StyledDialog>
    );
};

HnpDialog.propTypes = {
    dialogOn: PropTypes.bool,
    title: PropTypes.object,
    content: PropTypes.object,
    actions: PropTypes.object
};

export default HnpDialog;
