import React, { useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Docs } from 'hclnow-portal-guide';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { appTheme } from '../../themes/theme';
import { HnpProvider } from '../../contexts/HnpContext';
import { hnpRoutes } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import HnpBanner from '../common/HnpBanner';
import HnpHeader from '../HnpHeader';
import HnpFooter from '../HnpFooter';
import HnpNotFound from '../HnpNotFound';
import HnpHome from '../HnpHome';
import HnpMetrics from '../HnpMetrics';
import HnpStakeholders from '../HnpStakeholders';
import HnpTerms from '../HnpTerms';
import HnpLogin from '../HnpLogin';
import HnpSupport from '../HnpSupport';
import HnpDeprecation from '../HnpDeprecation';
import HnpFeatureFlagsControl from '../HnpFeatureFlagsControl';
import HnpMaintenance from '../HnpMaintenance';
import HnpDemoMsg from '../HnpDemoMsg';
import './App.scss';


const App = () => {
    // useTheme method use custom theme values as it is provided in the ThemeProvider
    const isScreenSizeMedium = useMediaQuery(appTheme.breakpoints.up('md'));
    const ref = useRef(null);

    return (
        <ThemeProvider theme={appTheme}>
            <HnpProvider>
                <CssBaseline />
                <HnpHeader />
                <HnpDeprecation />
                <main className="hnp-main" ref={ref}>
                    <HnpDemoMsg />
                    <HnpBanner>
                        <Routes>
                            <Route
                                path={hnpRoutes.environmentPage}
                                element={<HnpHome />}
                            />
                            <Route
                                path={`${hnpRoutes.metricsPage}/:solId`}
                                element={<HnpMetrics />}
                            />
                            <Route
                                path={hnpRoutes.termsPage}
                                element={<HnpTerms />}
                            />
                            <Route
                                path={hnpRoutes.stakeHolderPage}
                                element={<HnpStakeholders />}
                            />
                            <Route
                                path={hnpRoutes.loginPage}
                                element={<HnpLogin parentRef={ref} />}
                            />
                            <Route
                                path={hnpRoutes.newFeaturePage}
                                element={<HnpFeatureFlagsControl />}
                            />
                            <Route
                                path={hnpRoutes.guidePage}
                                element={<Docs />}
                            />
                            <Route
                                path={hnpRoutes.helpCenterPage}
                                element={
                                    !isScreenSizeMedium ? (
                                        <HnpSupport />
                                    ) : (
                                        <HnpHome />
                                    )
                                }
                            />
                            <Route
                                path={hnpRoutes.maintenancePage}
                                element={<HnpMaintenance />}
                            />
                            <Route
                                path={hnpRoutes.wildCardPage}
                                element={<HnpNotFound />}
                            />
                        </Routes>
                    </HnpBanner>
                </main>
                <HnpFooter />
            </HnpProvider>
        </ThemeProvider>
    );
};

export default App;
