import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import { HnpButton } from '../HnpMuiComponents';
import {
    colors,
    sxStyles
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import {
    hnpSupportTitle,
    helpCenterSection
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';

const PREFIX = 'HnpSupport';

const classes = {
    container: `${PREFIX}-container`,
    title: `${PREFIX}-title`,
    closedIcon: `${PREFIX}-closedIcon`,
    linkButton: `${PREFIX}-linkButton`,
    text: `${PREFIX}-text`,
    textPadding: `${PREFIX}-textPadding`,
    linkIcon: `${PREFIX}-linkIcon`
};

// MUI styles
const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            margin: theme.spacing(3),
            padding: theme.spacing(0, 3, 3, 3),
            width: '85%',
            backgroundColor: colors.white
        }
    },
    [`& .${classes.title}`]: {
        padding: theme.spacing(3, 0, 3, 0),
        display: 'inline-block',
        width: '90%',
        color: colors.loginGrad2,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2, 0, 2, 0)
        }
    },
    [`& .${classes.closedIcon}`]: {
        cursor: 'pointer',
        color: colors.turquoise1,
        marginLeft: theme.spacing(1.2),
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    [`& .${classes.linkButton}`]: {
        '&.MuiButtonBase-root': {
            margin: theme.spacing(0.75, 1.5, 4.5, 0),
            textTransform: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    [`& .${classes.text}`]: {
        padding: theme.spacing(0, 3, 1.5, 0)
    },
    [`& .${classes.textPadding}`]: {
        paddingRight: theme.spacing(1.5)
    },
    [`& .${classes.linkIcon}`]: {
        paddingBottom: theme.spacing(0.3)
    }
}));

const HnpSupport = props => {
    const handleClose = () => {
        props.setOpen(false);
    };

    const handleRedirect = url => e => {
        e.preventDefault();
        safeRedirect(url, true);
    };

    return (
        <StyledBox className={classes.container} data-testid="hnpSupport">
            <Box>
                <Typography variant="h4" className={classes.title}>
                    {hnpSupportTitle}
                </Typography>
                <CloseIcon
                    className={classes.closedIcon}
                    onClick={handleClose}
                ></CloseIcon>
            </Box>
            {helpCenterSection.map(({ text, buttonText, link }, index) => (
                <Box key={index}>
                    <Typography variant="body2" className={classes.text}>
                        {text}
                    </Typography>
                    <HnpButton
                        variant="contained"
                        className={classes.linkButton}
                        sx={sxStyles.containedBtn}
                        onClick={handleRedirect(link)}
                    >
                        <Box className={classes.textPadding}>{buttonText}</Box>
                        <OpenInNewIcon
                            className={classes.linkIcon}
                        ></OpenInNewIcon>
                    </HnpButton>
                    {index !== helpCenterSection.length - 1 ? (
                        <Divider
                            sx={{
                                ...sxStyles.mb3,
                                backgroundColor: 'transparent'
                            }}
                        />
                    ) : null}
                </Box>
            ))}
        </StyledBox>
    );
};

HnpSupport.propTypes = {
    setOpen: PropTypes.func
};

export default HnpSupport;
