import React, { useContext, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SvgIcon from '@mui/material/SvgIcon';
import { StyledAutocomplete, StyledTooltip } from '../HnpMuiComponents';
import { HnpContext } from '../../contexts/HnpContext';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import {
    getProductPartNoOptions,
    getEnvTypeOptions,
    stringifyArrayValues,
    getOrderIdOptions,
    getFilteredOptions,
    setCookieValue
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import {
    updateEnvsWithProps,
    hnpRoutes,
    hnpFilters,
    hnpNowOrderType,
    hnpLoginStatus,
    hnpDemoModeAlerts
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import {
    useDebounce,
    usePrevious,
    useIsMount
} from '../../customHooks/customHooks';
import { ReactComponent as CheckBoxSvgIcon } from '../../assets/images/checkbox-checked.svg';

const PREFIX = 'HnpFilters';

const classes = {
    container: `${PREFIX}-container`,
    customerNameFilterOptions: `${PREFIX}-customerNameFilterOptions`,
    checkIcon: `${PREFIX}-checkIcon`,
    dropDown: `${PREFIX}-dropDown`
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`&.${classes.customerNameFilterOptions}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    [`& .${classes.checkIcon}`]: {
        color: colors.white,
        marginTop: theme.spacing(-0.85),
        marginLeft: theme.spacing(-1)
    },
    [`& .${classes.dropDown}`]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        margin: 0,
        padding: theme.spacing(0.5, 0, 0.5)
    }
}));

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 2, 0)
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            margin: theme.spacing(1, 0, 1.25, 0)
        }
    }
}));

// Filter change debounce timeout (in ms)
const TIMEOUT = 500;

const HnpFilters = () => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMount = useIsMount();
    const { appStore, setAppStore } = useContext(HnpContext);
    const {
        isLogin,
        filters,
        selectedOption,
        hnpApiUtils,
        orderDetails,
        isDemoMode,
        provisionedEnvs
    } = appStore || {};
    // The appStore currentUrl is having the asynchronous changing and re-rendering of components, so overriding here with useLocation.
    const currentUrl = useLocation().pathname;
    // Get the previous value (was passed into hook on last render)
    const prevSelectedOption = usePrevious(selectedOption);
    // Filter names
    const {
        hcnName,
        orderIdName,
        partNumberName,
        envTypeName,
        notificationName,
        roleName
    } = hnpFilters;

    const filterOptions = createFilterOptions({
        stringify: option => (option.data2 ? option.data2 : option.value)
    });

    useEffect(() => {
        if (!isMount) {
            // Update the values to the hnp_pers cookie for every filter changes.
            const newPersData = {
                filters: {
                    hcn: filters.hcn.map(obj => obj.data),
                    orderId: filters.orderId.map(obj => obj.data),
                    partNumber: filters.partNumber.map(obj => obj.data),
                    envType: filters.envType.map(obj => obj.data),
                    notification: filters.notification.map(obj => obj.data),
                    role: filters.role.map(obj => obj.data)
                }
            };
            setCookieValue('hnp_pers', newPersData);
        }
    }, [provisionedEnvs]);

    const getOrderDetails = async () => {
        const isFilterChanged = () =>
            prevSelectedOption !== undefined &&
            prevSelectedOption !== selectedOption;

        // Trigger API call to get latest details on every filter change on HOME page
        // (because, for other page like METRICS page need to filter only OSD dahsboards);
        // `selectedOption` is `null` on initial page load but in the subsequent
        // option changes it will be either '' (for no option) or some value
        if (selectedOption !== null && selectedOption !== 'preselected@') {
            // Default filter keys
            let filterKeys = [
                hcnName,
                orderIdName,
                partNumberName,
                envTypeName
            ];
            // Check only `hcn` and `partNumber` to get order details if it's a stakeholders page
            if (currentUrl === hnpRoutes.stakeHolderPage) {
                filterKeys = [hcnName, partNumberName];
            }
            let ordersRes = {};
            // Trigger order API call, If the filter values are changed.
            if (isFilterChanged()) {
                // Build query parameter object
                const orderParams = Object.keys(filters)
                    .filter(key => filterKeys.includes(key))
                    .reduce((obj, key) => {
                        if (filters[key].length) {
                            obj[key] = filters[key]
                                .map(option =>
                                    key === hcnName ? option.value : option.data
                                )
                                .join(',');
                        }

                        return obj;
                    }, {});

                orderParams['nowOrderType'] =
                    hnpNowOrderType.hclSoftwareProduct;
                orderParams['includeTrialOrders'] = false;

                // Get filtered order details
                ordersRes = await hnpApiUtils.getOrder({
                    $queryParameters: orderParams
                });
            } else {
                // Else use the existing orderDetails from context state
                ordersRes.data = orderDetails;
            }
            const selectedOptionArr = selectedOption.split('@');
            const provisionedEnvs = updateEnvsWithProps(ordersRes.data);
            // Update dependent state like `orderId`, `partNumber` and `envType`
            let dependentState = {};
            let updatedOrderIdOptions = getOrderIdOptions(ordersRes.data);
            let updatedPartNoOptions = getProductPartNoOptions(ordersRes.data);
            let updatedEnvTypeOptions = getEnvTypeOptions(ordersRes.data);
            // Pre-select dependent filters for single provisioned environment
            if (provisionedEnvs.length === 1) {
                if (selectedOption.includes(hcnName) && selectedOptionArr[1]) {
                    updatedOrderIdOptions = updatedOrderIdOptions.filter(
                        getFilteredOptions(provisionedEnvs[0].orderId)
                    );
                    updatedPartNoOptions = updatedPartNoOptions.filter(
                        getFilteredOptions(provisionedEnvs[0].partNumber)
                    );
                    updatedEnvTypeOptions = updatedEnvTypeOptions.filter(
                        getFilteredOptions(
                            provisionedEnvs[0].provisionedEnvironment.type
                        )
                    );
                } else if (
                    selectedOption.includes(orderIdName) &&
                    selectedOptionArr[1]
                ) {
                    if (filters.hcn.length < 1) {
                        dependentState[hcnName] = filters.options.hcn.filter(
                            getFilteredOptions(provisionedEnvs[0].customer.hcn)
                        );
                    }
                    updatedPartNoOptions = updatedPartNoOptions.filter(
                        getFilteredOptions(provisionedEnvs[0].partNumber)
                    );
                    updatedEnvTypeOptions = updatedEnvTypeOptions.filter(
                        getFilteredOptions(
                            provisionedEnvs[0].provisionedEnvironment.type
                        )
                    );
                } else if (
                    selectedOption.includes(partNumberName) &&
                    selectedOptionArr[1]
                ) {
                    if (filters.hcn.length < 1) {
                        dependentState[hcnName] = filters.options.hcn.filter(
                            getFilteredOptions(provisionedEnvs[0].customer.hcn)
                        );
                    }
                    updatedOrderIdOptions = updatedOrderIdOptions.filter(
                        getFilteredOptions(provisionedEnvs[0].orderId)
                    );
                    updatedEnvTypeOptions = updatedEnvTypeOptions.filter(
                        getFilteredOptions(
                            provisionedEnvs[0].provisionedEnvironment.type
                        )
                    );
                } else if (
                    selectedOption.includes(envTypeName) &&
                    selectedOptionArr[1]
                ) {
                    if (filters.hcn.length < 1) {
                        dependentState[hcnName] = filters.options.hcn.filter(
                            getFilteredOptions(provisionedEnvs[0].customer.hcn)
                        );
                    }
                    updatedOrderIdOptions = updatedOrderIdOptions.filter(
                        getFilteredOptions(provisionedEnvs[0].orderId)
                    );
                    updatedPartNoOptions = updatedPartNoOptions.filter(
                        getFilteredOptions(provisionedEnvs[0].partNumber)
                    );
                }
            }

            if (selectedOption.includes(hcnName) && !selectedOptionArr[1]) {
                // Reset `hcn`, `orderId`, `partNumber`, `envType`, `notification` and `role` when specifically unselecting the single option
                dependentState[hcnName] = [];
                dependentState[orderIdName] = [];
                dependentState[partNumberName] = [];
                dependentState[envTypeName] = [];
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (
                selectedOption.includes(orderIdName) &&
                !selectedOptionArr[1]
            ) {
                // Reset `orderId`, `partNumber`, `envType`, `notification` and `role` when specifically unselecting the single option
                dependentState[orderIdName] = [];
                dependentState[partNumberName] = [];
                dependentState[envTypeName] = [];
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (
                selectedOption.includes(partNumberName) &&
                !selectedOptionArr[1]
            ) {
                // Reset `partNumber`, `envType`, `notification` and `role` when specifically unselecting the single option
                dependentState[partNumberName] = [];
                dependentState[envTypeName] = [];
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (
                selectedOption.includes(envTypeName) &&
                !selectedOptionArr[1]
            ) {
                // Reset `envType`, `notification` and `role` when specifically unselecting the single option
                dependentState[envTypeName] = [];
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (
                selectedOption.includes(notificationName) &&
                !selectedOptionArr[1]
            ) {
                // Reset `notification` and `role` when specifically unselecting the single option
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (
                selectedOption.includes(roleName) &&
                !selectedOptionArr[1]
            ) {
                // Reset `role` when specifically unselecting the single option
                dependentState[roleName] = [];
            } else {
                if (
                    updatedOrderIdOptions &&
                    updatedOrderIdOptions.length === 1
                ) {
                    // Update `orderId` only when single option is present (to pre-select)
                    dependentState[orderIdName] = updatedOrderIdOptions;
                }
                if (updatedPartNoOptions && updatedPartNoOptions.length === 1) {
                    // Update `partNumber` only when single option is present (to pre-select)
                    dependentState[partNumberName] = updatedPartNoOptions;
                }
                if (
                    updatedEnvTypeOptions &&
                    updatedEnvTypeOptions.length === 1
                ) {
                    // Update `envType` only when single option is present (to pre-select)
                    dependentState[envTypeName] = updatedEnvTypeOptions;
                }
            }

            // Update context state
            if (isFilterChanged()) {
                setAppStore(prevState => ({
                    ...prevState,
                    orderDetails: ordersRes.data,
                    filters: {
                        ...appStore.filters,
                        ...dependentState,
                        options: {
                            ...appStore.filters.options,
                            orderId: updatedOrderIdOptions,
                            partNumber: updatedPartNoOptions,
                            envType: updatedEnvTypeOptions
                        }
                    },
                    provisionedEnvs: provisionedEnvs
                }));
            }
        }
    };
    // Get the new order details after `TIMEOUT` (default = 1000ms) only
    useDebounce(getOrderDetails, TIMEOUT, [selectedOption]);

    // Filter onChange event handler
    const handleOnChange = name => (e, value) => {
        let dependentState = {};
        if (name === hcnName) {
            // Reset other filters when `hcn` value is empty
            dependentState[orderIdName] = [];
            dependentState[partNumberName] = [];
            dependentState[envTypeName] = [];
            dependentState[notificationName] = [];
            dependentState[roleName] = [];
        } else if (name === orderIdName) {
            // Reset `partNumber`, `envType`, `notification` and `role` filters when `orderId` value is empty
            dependentState[partNumberName] = [];
            dependentState[envTypeName] = [];
            dependentState[notificationName] = [];
            dependentState[roleName] = [];
        } else if (name === partNumberName) {
            // Reset `envType`, `notification` and `role` filters when `partNumber` value is empty
            dependentState[envTypeName] = [];
            dependentState[notificationName] = [];
            dependentState[roleName] = [];
        } else if (name === envTypeName) {
            // Reset `notification` and `role` filters when `envType` value is empty
            dependentState[notificationName] = [];
            dependentState[roleName] = [];
        } else if (name === notificationName) {
            // Reset `role` filter when `notification` value is empty
            dependentState[roleName] = [];
        }
        // Update context state
        setAppStore({
            ...appStore,
            filters: {
                ...filters,
                ...dependentState,
                [name]: value
            },
            selectedOption:
                (stringifyArrayValues(value) &&
                    `${name}@${stringifyArrayValues(value)}`) ||
                `${name}@`
        });
    };
    const handleOnInputChange = name => (e, value, reason) => {
        // Reset filter values on click of clear icon, X
        if (reason === 'clear') {
            let dependentState = {};
            if (name === hcnName) {
                // Reset other filters when `hcn` value is empty
                dependentState[orderIdName] = [];
                dependentState[partNumberName] = [];
                dependentState[envTypeName] = [];
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (name === orderIdName) {
                // Reset `partNumber`, `envType`, `notification` and `role` filters when `orderId` value is empty
                dependentState[partNumberName] = [];
                dependentState[envTypeName] = [];
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (name === partNumberName) {
                // Reset `envType`, `notification` and `role` filters when `partNumber` value is empty
                dependentState[envTypeName] = [];
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (name === envTypeName) {
                // Reset `notification` and `role` filters when `envType` value is empty
                dependentState[notificationName] = [];
                dependentState[roleName] = [];
            } else if (name === notificationName) {
                // Reset `role` filter when `notification` value is empty
                dependentState[roleName] = [];
            }
            // Update context state
            setAppStore({
                ...appStore,
                filters: {
                    ...filters,
                    ...dependentState
                },
                selectedOption: `${name}@`
            });
        }
    };
    const handleOnOpen = () => () => {
        // removed the filter handle open context update as it is not needed for bug-HCLNOW-1036 requirement.
        // Please check bug-HCLNOW-1036 changes to enable in future
    };
    const handleOnClose = () => () => {
        // removed the filter handle close context update as it is not needed for bug-HCLNOW-1036 requirement.
        // Please check bug-HCLNOW-1036 changes to enable in future
    };
    // Render filters using autocomplete component
    const renderFilter = (optionData, name, label) => {
        const renderFiltersOption = option => {
            return name === hcnName
                ? `${option.data2} (${option.label})`
                : name === partNumberName
                ? `${option.label} (${option.data})`
                : option.label;
        };
        const customTags = item => {
            return name === hcnName
                ? `${item.data2} (${item.value})`
                : name === partNumberName
                ? `${item.value} (${item.data})`
                : item.value;
        };
        const renderFiltersTag = value => {
            return name === hcnName
                ? filters[name].length > 1
                    ? value.length === optionData.length
                        ? `All ${value.length} selected`
                        : `${value.length} of ${optionData.length} selected`
                    : filters[name].length && customTags(filters[name][0])
                : name === notificationName || name === roleName
                ? filters[name].map(item => item.value).join(', ')
                : filters[name].length && customTags(filters[name][0]);
        };
        const enableFilterSearch = filters => {
            return filters[name].length === 0
                ? isMobileScreen
                    ? false
                    : true
                : true;
        };

        return (
            <StyledAutocomplete
                multiple
                fullWidth
                disableCloseOnSelect
                size="small"
                data-testid="autocomplete"
                limitTags={1}
                clearText="Clear all"
                filterOptions={filterOptions}
                id={`hnp-filter-${name.toLowerCase()}`}
                name={name}
                options={optionData}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={(option, value) => {
                    return name === partNumberName
                        ? option.data === value.data
                        : option.value === value.value;
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.data}>
                        <StyledDiv
                            {...((name === hcnName ||
                                name === partNumberName) && {
                                className: classes.customerNameFilterOptions
                            })}
                        >
                            <Box className={classes.dropDown}>
                                <Checkbox
                                    icon={
                                        <CheckBoxOutlineBlankIcon
                                            fontSize="small"
                                            className={classes.checkIcon}
                                        />
                                    }
                                    checkedIcon={
                                        <SvgIcon
                                            fontSize="small"
                                            className={classes.checkIcon}
                                            component={CheckBoxSvgIcon}
                                        />
                                    }
                                    sx={sxStyles.mr}
                                    checked={selected}
                                />
                                <Box
                                    component="span"
                                    sx={sxStyles.filterOptionText}
                                >
                                    {renderFiltersOption(option)}
                                </Box>
                            </Box>
                        </StyledDiv>
                    </li>
                )}
                renderInput={({ inputProps, ...params }) => {
                    const inputElement = (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={label}
                            inputProps={{
                                ...inputProps,
                                readOnly: enableFilterSearch(filters)
                            }}
                        />
                    );

                    return name === hcnName && isDemoMode && isDesktopScreen ? (
                        <StyledTooltip
                            title={hnpDemoModeAlerts.turnOffFilter}
                            arrow
                            styles={{
                                marginTop: theme.spacing(-1),
                                width: theme.spacing(16),
                                left: theme.spacing(-9)
                            }}
                        >
                            {inputElement}
                        </StyledTooltip>
                    ) : (
                        inputElement
                    );
                }}
                renderTags={value => (
                    <Box component="span" sx={sxStyles.inputEllipsis2}>
                        {renderFiltersTag(value)}
                    </Box>
                )}
                defaultValue={filters[name].length === 1 ? filters[name] : []}
                value={filters[name]}
                onOpen={handleOnOpen(name)}
                onChange={handleOnChange(name)}
                onInputChange={handleOnInputChange(name)}
                onClose={handleOnClose(name)}
                disabled={name === hcnName && isDemoMode}
            />
        );
    };

    return isLogin === hnpLoginStatus.loggedIn && filters ? (
        <StyledBox
            className={classes.container}
            sx={[sxStyles.mt2, sxStyles.mb4]}
        >
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={12} sm={6} lg={3}>
                    {renderFilter(
                        filters.options.hcn,
                        hcnName,
                        'Customer Name'
                    )}
                </Grid>
                {currentUrl !== hnpRoutes.stakeHolderPage ? (
                    <Grid xs={12} sm={6} lg={3}>
                        {renderFilter(
                            filters.options.orderId,
                            orderIdName,
                            'Order ID'
                        )}
                    </Grid>
                ) : null}
                <Grid xs={12} sm={6} lg={3}>
                    {renderFilter(
                        filters.options.partNumber,
                        partNumberName,
                        'Product Name'
                    )}
                </Grid>
                {currentUrl !== hnpRoutes.stakeHolderPage ? (
                    <Grid xs={12} sm={6} lg={3}>
                        {renderFilter(
                            filters.options.envType,
                            envTypeName,
                            'Environment Type'
                        )}
                    </Grid>
                ) : null}
                {currentUrl === hnpRoutes.stakeHolderPage ? (
                    <Fragment>
                        <Grid xs={12} sm={6} lg={3}>
                            {renderFilter(
                                filters.options.notification,
                                notificationName,
                                'Notification Type'
                            )}
                        </Grid>
                        <Grid xs={12} sm={6} lg={3}>
                            {renderFilter(
                                filters.options.role,
                                roleName,
                                'Organization'
                            )}
                        </Grid>
                    </Fragment>
                ) : null}
            </Grid>
        </StyledBox>
    ) : null;
};

export default HnpFilters;
