import React, { useContext, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { sxStyles } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import {
    getDisplayName,
    getProductNameByCommaRule,
    getPriorityAlertData,
    getEnvChipColor
} from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import {
    updateEnvsWithProps,
    hnpNoEnvMsg,
    hnpEnvironments,
    hnpLoginStatus,
    hnpRoutes
} from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import {
    StyledCard,
    HnpButton,
    StyledChip,
    StyledEnvIcon,
    StyledTooltip
} from '../HnpMuiComponents';
import { HnpContext } from '../../contexts/HnpContext';
import HnpCustomerName from '../HnpCustomerName';
import HnpFilters from '../HnpFilters';
import HnpMessage from '../HnpMessage';
import HnpCaseSummary from '../HnpCaseSummary';
import HnpTitleTooltip from '../HnpTitleTooltip';
import HnpPriorityAlert from '../HnpPriorityAlert';
import HnpEnvStatusIcon from '../common/HnpEnvStatusIcon';
import HnpStakeholderCard from '../HnpStakeholderCard';
import HnpOpenCases from '../HnpOpenCases';

const PREFIX = 'HnpHome';

const classes = {
    container: `${PREFIX}-container`,
    topCards: `${PREFIX}-topCards`,
    productCard: `${PREFIX}-productCard`,
    cardActions: `${PREFIX}-cardActions`,
    contentSpace: `${PREFIX}-contentSpace`,
    headerSpace: `${PREFIX}-headerSpace`,
    detailsLineHeight: `${PREFIX}-detailsLineHeight`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2.5, 0, 3)
    },
    [`& .${classes.topCards}`]: {
        display: 'flex',
        '& .MuiGrid2-container': {
            flexGrow: 1
        }
    },
    [`& .${classes.productCard}`]: {
        height: theme.spacing(36.25),
        padding: theme.spacing(0, 1, 1.25),
        position: 'relative',
        '& .MuiCardHeader-title': {
            lineHeight: 1.43
        },
        '& .MuiCardHeader-content': {
            overflow: 'hidden'
        },
        '& .MuiAvatar-square': {
            width: theme.spacing(6.25),
            height: theme.spacing(6.25),
            marginTop: theme.spacing(0)
        },
        '& .MuiCardContent-root': {
            height: theme.spacing(15.375)
        },
        '& .MuiCardHeader-action': {
            marginTop: theme.spacing(3),
            marginRight: theme.spacing(0),
            height: theme.spacing(2)
        }
    },
    [`& .${classes.cardActions}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 1.75, 1.5, 2),
        minHeight: theme.spacing(6.84375)
    },
    [`& .${classes.contentSpace}`]: {
        padding: theme.spacing(2.625, 2, 3),
        fontWeight: 'bold'
    },
    [`& .${classes.headerSpace}`]: {
        padding: theme.spacing(0, 2, 1.5, 1.5),
        height: theme.spacing(11.75)
    },
    [`& .${classes.detailsLineHeight}`]: {
        lineHeight: 1.43
    }
}));

const HnpHome = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { appStore, setAppStore } = useContext(HnpContext);
    const {
        isLogin,
        selectedOption,
        provisionedEnvs,
        filters,
        hnpApiUtils,
        orderDetails,
        priorityAlert
    } = appStore || {};

    useEffect(() => {
        if (selectedOption !== 'preselected@') {
            const fetchPriorityCases = async () => {
                let hcnQuery;

                if (filters.hcn.length === 0) {
                    hcnQuery = filters.options.hcn
                        .map(hcnObj => hcnObj.data)
                        .join(',');
                } else {
                    hcnQuery = filters.hcn.map(hcnObj => hcnObj.data).join(',');
                }

                let priorityCases;
                // wrapping the priority cases api with trycatch to handle the error. The catch block will be implemented when needed.
                try {
                    priorityCases =
                        await hnpApiUtils.getOpensearchPriorityCases({
                            hcn: hcnQuery,
                            size: 500
                        });
                } catch (error) {
                    // this is for error boundary
                }

                setAppStore(prevState => ({
                    ...prevState,
                    provisionedEnvs: updateEnvsWithProps(
                        orderDetails,
                        priorityCases
                    ),
                    priorityAlert:
                        filters.hcn.length === 1
                            ? getPriorityAlertData(
                                  filters.hcn[0].data,
                                  priorityCases
                              )
                            : []
                }));
            };
            fetchPriorityCases();
        }
    }, [orderDetails]);

    const handleOnClick = solId => () => {
        // Redirect to metrics page
        navigate(`${hnpRoutes.metricsPage}/${solId}`);
    };
    const getTooltipTitle = type => {
        switch (type) {
            case hnpEnvironments.prod:
                return 'Production Environment';
            case hnpEnvironments.qa:
                return 'QA Environment';
            case hnpEnvironments.dev:
                return 'Development Environment';
            case hnpEnvironments.dr:
                return 'Disaster Recovery Environment';
            case hnpEnvironments.preProd:
                return 'Pre-Production Environment';
            default:
                return '';
        }
    };

    return isLogin === hnpLoginStatus.loggedIn && provisionedEnvs ? (
        <StyledBox className={classes.container} sx={sxStyles.appContainer}>
            <HnpCustomerName />
            {/* Render the Fitler component */}
            <HnpFilters />
            {provisionedEnvs.length ? (
                <Fragment>
                    <Box className={classes.topCards} sx={sxStyles.mt3}>
                        <Grid container spacing={2}>
                            {provisionedEnvs.map(
                                (
                                    {
                                        _id,
                                        name: partNumberName,
                                        customerName,
                                        logo,
                                        envState,
                                        provisionedEnvironment: {
                                            name: envName,
                                            type,
                                            provisionedTime
                                        },
                                        environmentContent: { version }
                                    },
                                    index
                                ) => (
                                    <Grid
                                        key={`${_id}_${index}`}
                                        xs={12}
                                        sm={6}
                                        lg={3}
                                    >
                                        <StyledCard
                                            className={classes.productCard}
                                        >
                                            <CardHeader
                                                avatar={
                                                    <Avatar
                                                        variant="square"
                                                        aria-label="Product logo"
                                                        alt="Product logo"
                                                        src={logo}
                                                        sx={
                                                            sxStyles.noProductLogoBg
                                                        }
                                                    >
                                                        {getDisplayName(
                                                            partNumberName
                                                        )}
                                                    </Avatar>
                                                }
                                                title={getProductNameByCommaRule(
                                                    partNumberName
                                                )}
                                                action={
                                                    type ===
                                                        hnpEnvironments.prod &&
                                                    envState && (
                                                        <HnpEnvStatusIcon
                                                            envState={envState}
                                                        />
                                                    )
                                                }
                                                className={classes.headerSpace}
                                            />
                                            <CardContent
                                                className={classes.contentSpace}
                                            >
                                                <Fragment>
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.detailsLineHeight
                                                        }
                                                        sx={
                                                            sxStyles.horizontalEllipsis
                                                        }
                                                    >
                                                        Version {version}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className={
                                                            classes.detailsLineHeight
                                                        }
                                                        sx={
                                                            sxStyles.horizontalEllipsis
                                                        }
                                                    >
                                                        Provisioned:{' '}
                                                        <Moment
                                                            format="MMM DD, YYYY"
                                                            date={
                                                                provisionedTime
                                                            }
                                                        />
                                                    </Typography>

                                                    <Typography
                                                        variant="body2"
                                                        component="div"
                                                        className={
                                                            classes.detailsLineHeight
                                                        }
                                                    >
                                                        <HnpTitleTooltip
                                                            title={customerName}
                                                            arrow
                                                            type="customerName"
                                                        >
                                                            {customerName}
                                                        </HnpTitleTooltip>
                                                    </Typography>
                                                </Fragment>
                                            </CardContent>
                                            <Divider
                                                sx={[
                                                    sxStyles.ml2,
                                                    sxStyles.mr2,
                                                    {
                                                        marginBottom:
                                                            theme.spacing(0.75)
                                                    }
                                                ]}
                                            />
                                            <CardActions
                                                className={classes.cardActions}
                                            >
                                                <StyledTooltip
                                                    title={getTooltipTitle(
                                                        type
                                                    )}
                                                    arrow
                                                    styles={{
                                                        marginTop:
                                                            theme.spacing(0.5)
                                                    }}
                                                    placement="bottom-start"
                                                >
                                                    <StyledChip
                                                        size="small"
                                                        label={envName}
                                                        bgcolor={getEnvChipColor(
                                                            hnpEnvironments,
                                                            type
                                                        )}
                                                        icon={
                                                            type ===
                                                            hnpEnvironments.prod ? (
                                                                <StyledEnvIcon />
                                                            ) : null
                                                        }
                                                    />
                                                </StyledTooltip>
                                                {type ===
                                                hnpEnvironments.prod ? (
                                                    <HnpButton
                                                        size="small"
                                                        sx={sxStyles.textBtn}
                                                        onClick={handleOnClick(
                                                            _id
                                                        )}
                                                    >
                                                        View Metrics
                                                    </HnpButton>
                                                ) : null}
                                            </CardActions>
                                        </StyledCard>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Box>
                    {appStore.filters.hcn.length === 1 && (
                        <Fragment>
                            <Box className={classes.topCards} sx={sxStyles.mt3}>
                                <Grid container spacing={2}>
                                    <HnpOpenCases />
                                    <HnpCaseSummary />
                                    <HnpStakeholderCard />
                                </Grid>
                            </Box>
                            {priorityAlert.length > 0 ? (
                                <Box
                                    className={classes.topCards}
                                    sx={sxStyles.mt3}
                                >
                                    <Grid container spacing={2}>
                                        <Grid xs={12} sm={12} lg={12}>
                                            <HnpPriorityAlert
                                                alertData={priorityAlert}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            ) : null}
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                <HnpMessage msg={hnpNoEnvMsg} />
            )}
        </StyledBox>
    ) : null;
};

export default HnpHome;
