import { createTheme } from '@mui/material/styles';
import { colors } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';

export const appTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            lg1: 1500,
            lg2: 1600,
            xl: 1920
        }
    },
    typography: {
        allVariants: { color: colors.black },
        fontFamily: 'Open Sans',
        h2: {
            fontFamily: 'HCLTech Roobert',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'HCLTech Roobert',
            fontSize: '1.625rem',
            fontWeight: 500
        },
        body2: {
            lineHeight: 1.78
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '0.625rem'
                }
            }
        }
    }
});
