import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { HnpContext } from '../../contexts/HnpContext';
import { StyledPaper, HnpButton } from '../HnpMuiComponents';
import WatchVideo from '../../assets/images/watch-video.png';
import {
    colors,
    sxStyles
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import './HnpLogin.scss';

const PREFIX = 'HnpLogin';

const classes = {
    container: `${PREFIX}-container`,
    messageBox: `${PREFIX}-messageBox`,
    welcomeMsg: `${PREFIX}-welcomeMsg`,
    message: `${PREFIX}-message`,
    formContainer: `${PREFIX}-formContainer`,
    rightGrid: `${PREFIX}-rightGrid`,
    form: `${PREFIX}-form`,
    formMessage: `${PREFIX}-formMessage`,
    loginContainer: `${PREFIX}-loginContainer`,
    watchVideo: `${PREFIX}-watchVideo`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.container}`]: {
        width: '100vw',
        margin: 0,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    [`& .${classes.messageBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        color: colors.white2,
        padding: '15% 0 0 0',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
            padding: theme.spacing(15, 2, 2)
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4, 0, 2)
        }
    },
    [`& .${classes.welcomeMsg}`]: {
        margin: theme.spacing(0, 0, 8),
        color: colors.white2,
        textTransform: 'uppercase',
        fontWeight: '600',
        letterSpacing: theme.spacing(0.5),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 0, 6)
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0, 4),
            fontSize: theme.spacing(1.75)
        }
    },
    [`& .${classes.message}`]: {
        lineHeight: 0.96,
        letterSpacing: 0,
        color: colors.white2,
        [theme.breakpoints.down('md')]: {
            fontSize: theme.spacing(5),
            lineHeight: 1.2,
            textAlign: 'center',
            marginBottom: theme.spacing(6)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(3),
            marginBottom: theme.spacing(0)
        }
    },
    [`& .${classes.formContainer}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '75%',
        padding: theme.spacing(7.5),
        [theme.breakpoints.up('md')]: {
            marginTop: '30%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            padding: theme.spacing(4, 3)
        }
    },
    [`& .${classes.rightGrid}`]: {
        alignSelf: 'center',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            height: '100%'
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    [`& .${classes.form}`]: {
        width: '100%'
    },
    [`& .${classes.formMessage}`]: {
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(2.5)
        }
    },
    [`& .${classes.loginContainer}`]: {
        margin: 0,
        width: '100vw',
        [theme.breakpoints.up('md')]: {
            width: '82%',
            '& .MuiGrid2-root': {
                padding: 0
            }
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiGrid2-root': {
                padding: theme.spacing(2, 0)
            }
        }
    },
    [`& .${classes.watchVideo}`]: {
        height: theme.spacing(15),
        width: theme.spacing(15),
        marginTop: theme.spacing(8),
        backgroundImage: `url(${WatchVideo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2)
        },
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(10),
            width: theme.spacing(10),
            marginTop: theme.spacing(3.5)
        }
    }
}));

const HnpLogin = ({ parentRef }) => {
    const { appStore } = useContext(HnpContext);
    const { hnpApiUtils } = appStore || {};

    const handleOnSubmit = e => {
        e.preventDefault();
        safeRedirect(`${hnpApiUtils.domain}/login`);
    };
    const handleVideoClick = e => {
        e.preventDefault();
        safeRedirect('https://youtu.be/SWfUrfuSE2U', true);
    };

    useEffect(() => {
        if (parentRef && parentRef.current) {
            // Set flex direction unset for login page due to single child element
            parentRef.current.style.flexDirection = 'unset';
        }
        return () => {
            // Anything in here is fired on component unmount.
            parentRef.current.style.flexDirection = 'column';
        };
    }, []);

    return (
        <StyledBox className={`hnp-login-container ${classes.container}`}>
            <Grid container spacing={4} className={classes.loginContainer}>
                <Grid xs={12} md={7}>
                    <Box className={classes.messageBox}>
                        <Typography
                            variant="subtitle1"
                            className={classes.welcomeMsg}
                        >
                            Welcome to HCL Now
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.message}
                            sx={sxStyles.mb2}
                        >
                            HCL Software
                        </Typography>
                        <Typography variant="h2" className={classes.message}>
                            Go Cloud Native Now
                        </Typography>
                        <Box
                            className={classes.watchVideo}
                            data-testid="watchVideo"
                            onClick={handleVideoClick}
                        />
                    </Box>
                </Grid>
                <Grid xs={12} md={5} className={classes.rightGrid}>
                    <StyledPaper className={classes.formContainer}>
                        <form
                            className={classes.form}
                            name="hnp-login-form"
                            autoComplete="off"
                            noValidate
                            onSubmit={handleOnSubmit}
                        >
                            <Typography
                                variant="h4"
                                className={classes.formMessage}
                                sx={sxStyles.mb4}
                            >
                                Login to HCL Now
                            </Typography>
                            <HnpButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={sxStyles.loginBtn}
                            >
                                Login with HCL Software ID
                            </HnpButton>
                        </form>
                    </StyledPaper>
                </Grid>
            </Grid>
        </StyledBox>
    );
};

HnpLogin.propTypes = {
    parentRef: PropTypes.object
};

export default HnpLogin;
