import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Router basename={process.env.REACT_APP_PUBLIC_URL}>
        <App />
    </Router>
);

// Start measuring performance of the app
reportWebVitals();
