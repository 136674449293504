import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { safeRedirect } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { HnpContext } from '../../contexts/HnpContext';
import { StyledCard, StyledPaper, StyledTooltip } from '../HnpMuiComponents';

const PREFIX = 'HnpStakeholderDesktop';

const classes = {
    tablePaper: `${PREFIX}-tablePaper`,
    tableContainer: `${PREFIX}-tableContainer`,
    tableHeadIcon: `${PREFIX}-tableHeadIcon`,
    nameCompanyColumn: `${PREFIX}-nameCompanyColumn`,
    tableHeadPadding: `${PREFIX}-tableHeadPadding`,
    tableHeadNoPadding: `${PREFIX}-tableHeadNoPadding`,
    copyIcon: `${PREFIX}-copyIcon`,
    checkIcon: `${PREFIX}-checkIcon`,
    emailBox: `${PREFIX}-emailBox`,
    emailBtn: `${PREFIX}-emailBtn`,
    iconContainer: `${PREFIX}-iconContainer`
};

const StyledHnpPaper = styled(StyledPaper)(({ theme }) => ({
    [`&.${classes.tablePaper}`]: {
        padding: theme.spacing(3, 2.5, 3, 3),
        boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(1)}  0 ${
            colors.black2
        }`
    },
    [`& .${classes.tableContainer}`]: {
        maxHeight: 'calc(100vh - 400px)',
        '&::-webkit-scrollbar': {
            width: theme.spacing(0.8)
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.grey,
            borderRadius: theme.spacing(0.5)
        },
        '& .MuiTableCell-root': {
            color: colors.black
        },
        '& th': {
            fontWeight: 600
        },
        '& td': {
            padding: theme.spacing(0.5, 2, 0.5),
            verticalAlign: 'baseline'
        }
    },
    [`& .${classes.tableHeadIcon}`]: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: theme.spacing(2.25)
        }
    },
    [`& .${classes.nameCompanyColumn}`]: {
        background: 'transparent',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none !important',
            transform: 'none !important',
            transition: 'none !important'
        },
        '& .MuiCardHeader-root': {
            padding: theme.spacing(1.5, 2, 1.5, 0),
            '& .MuiCardHeader-title': {
                color: colors.black,
                fontSize: theme.spacing(1.75),
                fontWeight: 400
            },
            '& .MuiCardHeader-subheader': {
                fontSize: theme.spacing(1.75)
            }
        },
        '& .MuiTypography-colorTextSecondary': {
            color: colors.grey6
        }
    },
    [`& .${classes.tableHeadPadding}`]: {
        padding: theme.spacing(1.5, 2, 1.5)
    },
    [`& .${classes.tableHeadNoPadding}`]: {
        padding: 0
    },
    [`& .${classes.copyIcon}`]: {
        visibility: 'hidden',
        paddingLeft: theme.spacing(0.5),
        opacity: 0.54,
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.87
        }
    },
    [`& .${classes.checkIcon}`]: {
        paddingLeft: theme.spacing(0.5),
        color: colors.turquoise1
    },
    [`& .${classes.emailBox}`]: {
        display: 'flex',
        flexWrap: 'nowrap',
        width: '98%',
        '&:hover #copy-icon': {
            visibility: 'visible'
        },
        '&:hover :first-of-type': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    },
    [`& .${classes.emailBtn}`]: {
        fontSize: theme.spacing(1.75),
        color: colors.turquoise5,
        textAlign: 'left',
        textWrap: 'nowrap',
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
        padding: 0
    },
    [`& .${classes.iconContainer}`]: {
        margin: theme.spacing(0, 0, 0, 1),
        display: 'inline-block',
        height: theme.spacing(3)
    }
}));

// TEMP: subject to be removed
const columns = [
    'Product',
    'Notification Type',
    'Name & Company',
    'Title',
    'Escalation',
    'Email'
];

const StakeholderRow = props => {
    const theme = useTheme();
    const emailTooltipStyle = {
        margin: theme.spacing(1, 0, 0, 0.5)
    };
    const { user, productName, company, notification } = props;
    const [copyEmail, setCopyEmail] = useState(false);

    const handleCopyClick = email => () => {
        navigator.clipboard.writeText(email);
        setCopyEmail(true);
    };

    const handleCheckClick = () => () => {
        setCopyEmail(false);
    };

    const openEmailClient = email => () => {
        safeRedirect(`mailto:${email}`);
    };

    return (
        <Fragment>
            <TableRow hover role="checkbox" tabIndex={-1}>
                {columns.map((col, j) => (
                    <TableCell
                        key={col}
                        align={j === 4 ? 'center' : 'left'}
                        colSpan={1}
                    >
                        {j === 0 ? (
                            productName
                        ) : j === 1 ? (
                            <StyledTooltip
                                title={
                                    <Box>
                                        {Object.keys(notification).map(
                                            (key, k) => {
                                                const notificationLen =
                                                    Object.keys(
                                                        notification
                                                    ).length;
                                                const keyStr = key.replace(
                                                    'app',
                                                    ''
                                                );
                                                const keySubStr = Object.keys(
                                                    notification[key]
                                                ).map((iKey, l) => {
                                                    const iArrLen = Object.keys(
                                                        notification[key]
                                                    ).length;

                                                    return (
                                                        <Fragment
                                                            key={Math.random()
                                                                .toString()
                                                                .slice(-8)}
                                                        >
                                                            <Box component="span">
                                                                {`${iKey[0].toUpperCase()}${iKey.slice(
                                                                    1
                                                                )}: `}
                                                            </Box>
                                                            <Box
                                                                component="span"
                                                                fontStyle="italic"
                                                            >
                                                                {
                                                                    notification[
                                                                        key
                                                                    ][iKey]
                                                                }
                                                            </Box>
                                                            {l < iArrLen - 1
                                                                ? ', '
                                                                : null}
                                                        </Fragment>
                                                    );
                                                });

                                                return (
                                                    <Box
                                                        key={Math.random()
                                                            .toString()
                                                            .slice(-8)}
                                                        mb={
                                                            k <
                                                            notificationLen - 1
                                                                ? 1.5
                                                                : 0
                                                        }
                                                    >
                                                        <Box
                                                            component="span"
                                                            fontWeight={
                                                                theme.typography
                                                                    .fontWeightBold
                                                            }
                                                        >
                                                            {`${keyStr[0].toUpperCase()}${keyStr.slice(
                                                                1
                                                            )} - `}
                                                        </Box>
                                                        <Box component="span">
                                                            {keySubStr}
                                                        </Box>
                                                    </Box>
                                                );
                                            }
                                        )}
                                    </Box>
                                }
                                styles={{
                                    maxWidth: 560,
                                    padding: theme.spacing(2)
                                }}
                                arrow
                            >
                                <Box>
                                    {notification
                                        ? Object.keys(notification)
                                              .map(key => {
                                                  const keyStr = key.replace(
                                                      'app',
                                                      ''
                                                  );

                                                  return `${keyStr[0].toUpperCase()}${keyStr.slice(
                                                      1
                                                  )}`;
                                              })
                                              .join(', ')
                                        : '-'}
                                </Box>
                            </StyledTooltip>
                        ) : j === 2 ? (
                            <StyledCard className={classes.nameCompanyColumn}>
                                <CardHeader
                                    title={user.name}
                                    subheader={company}
                                />
                            </StyledCard>
                        ) : j === 3 ? (
                            user.jobTitle
                        ) : j === 4 ? (
                            <Typography
                                variant="body2"
                                fontWeight="bold"
                                fontSize={theme.spacing(1.75)}
                            >
                                {notification.critSits
                                    ? notification.critSits.escalationPriority
                                        ? notification.critSits
                                              .escalationPriority
                                        : '-'
                                    : '-'}
                            </Typography>
                        ) : j === 5 ? (
                            <Box className={classes.emailBox}>
                                <Box
                                    onClick={openEmailClient(user.email)}
                                    component="button"
                                    className={classes.emailBtn}
                                    sx={sxStyles.tabFocus}
                                >
                                    {user.email}
                                </Box>
                                <StyledTooltip
                                    styles={emailTooltipStyle}
                                    title={!copyEmail ? 'Copy Email' : 'Copied'}
                                    arrow
                                >
                                    <Box
                                        component="span"
                                        className={classes.iconContainer}
                                    >
                                        {!copyEmail ? (
                                            <FileCopyOutlinedIcon
                                                className={classes.copyIcon}
                                                id="copy-icon"
                                                onClick={handleCopyClick(
                                                    user.email
                                                )}
                                            />
                                        ) : (
                                            <CheckIcon
                                                className={classes.checkIcon}
                                                onMouseOut={handleCheckClick()}
                                            />
                                        )}
                                    </Box>
                                </StyledTooltip>
                            </Box>
                        ) : null}
                    </TableCell>
                ))}
            </TableRow>
        </Fragment>
    );
};

StakeholderRow.propTypes = {
    index: PropTypes.number,
    user: PropTypes.object,
    productName: PropTypes.string,
    company: PropTypes.string,
    notification: PropTypes.object
};

const HnpStakeholderDesktop = () => {
    const theme = useTheme();
    const headerTooltipStyle = { padding: theme.spacing(1.5) };

    const { appStore } = useContext(HnpContext);
    const { stakeholdersDetails } = appStore || {};

    return stakeholdersDetails ? (
        <StyledHnpPaper className={classes.tablePaper}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="stakeholders sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((col, i) => (
                                <TableCell
                                    key={col}
                                    align="left"
                                    colSpan={1}
                                    sx={{
                                        '&.MuiTableCell-stickyHeader': {
                                            backgroundColor: colors.grey7
                                        }
                                    }}
                                    classes={{
                                        root:
                                            i === 4
                                                ? classes.tableHeadNoPadding
                                                : classes.tableHeadPadding
                                    }}
                                >
                                    {i === 1 ? (
                                        <Box className={classes.tableHeadIcon}>
                                            <Box component="span" mr={0.5}>
                                                {col}
                                            </Box>
                                            <StyledTooltip
                                                title="Hover over Notification Type value to refer to the detailed list of notifications and method of contact for each Stakeholder."
                                                styles={headerTooltipStyle}
                                                arrow
                                            >
                                                <InfoOutlinedIcon />
                                            </StyledTooltip>
                                        </Box>
                                    ) : i === 4 ? (
                                        <Box className={classes.tableHeadIcon}>
                                            <Box component="span" mr={0.5}>
                                                {col}
                                            </Box>
                                            <StyledTooltip
                                                title="Escalation refers to the order in which to contact Stakeholders for assistance with Priority 1 incidents."
                                                styles={headerTooltipStyle}
                                                arrow
                                            >
                                                <InfoOutlinedIcon />
                                            </StyledTooltip>
                                        </Box>
                                    ) : (
                                        col
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stakeholdersDetails.map(
                            ({
                                _id,
                                user,
                                productName,
                                company,
                                notification
                            }) => (
                                <StakeholderRow
                                    key={_id}
                                    user={user}
                                    productName={productName}
                                    company={company}
                                    notification={notification}
                                />
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledHnpPaper>
    ) : null;
};

export default HnpStakeholderDesktop;
