import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { sxStyles } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { getCustomerNameByHcn } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { hnpLoginStatus } from '@hclnow-portal/hclnow-portal-data-sample/data/hnpData';
import { HnpContext } from '../../contexts/HnpContext';
import HnpTitleTooltip from '../HnpTitleTooltip';

const PREFIX = 'HnpCustomerName';

const classes = {
    title: `${PREFIX}-title`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.title}`]: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2)
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            margin: theme.spacing(-0.5, 0, 1, 0)
        }
    }
}));

const HnpCustomerName = () => {
    const { appStore } = useContext(HnpContext);
    const { isLogin, orderDetails, filters, selectedOption } = appStore || {};
    const [customerName, setCustomerName] = useState('');

    useEffect(() => {
        setCustomerName(getCustomerNameByHcn(orderDetails, filters));
    }, [selectedOption, orderDetails && orderDetails.length]);

    return isLogin === hnpLoginStatus.loggedIn ? (
        <StyledBox className={classes.title} sx={sxStyles.mb2}>
            <HnpTitleTooltip title={customerName} arrow type="mainCustomerName">
                {customerName}
            </HnpTitleTooltip>
        </StyledBox>
    ) : null;
};

export default HnpCustomerName;
