import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { sxStyles } from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { StyledTooltip } from '../HnpMuiComponents';

const HnpTitleTooltip = props => {
    const theme = useTheme();
    const [productTitleTooltip, setProductTitleTooltip] = useState(false);

    return (
        <StyledTooltip
            {...props}
            open={productTitleTooltip}
            styles={{
                marginTop: theme.spacing(0.6)
            }}
            onOpen={e => {
                if (
                    e.target.parentElement.offsetWidth < e.target.offsetWidth ||
                    e.target.parentElement.offsetHeight < e.target.offsetHeight
                ) {
                    setProductTitleTooltip(true);
                }
            }}
            onClose={() => setProductTitleTooltip(false)}
        >
            <Box
                sx={
                    props.type === 'productNameEnvPage'
                        ? sxStyles.verticalEllipsis1
                        : props.type === 'customerName' ||
                          props.type === 'productNameMetricsPage'
                        ? sxStyles.horizontalEllipsis
                        : props.type === 'mainCustomerName'
                        ? sxStyles.inputEllipsis3
                        : ''
                }
            >
                <span>{props.children}</span>
            </Box>
        </StyledTooltip>
    );
};

HnpTitleTooltip.propTypes = {
    type: PropTypes.string,
    children: PropTypes.any
};

export default HnpTitleTooltip;
