import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {
    sxStyles,
    colors
} from '@hclnow-portal/hclnow-portal-helpers/js/muiCommonStyles';
import { getDurationValue } from '@hclnow-portal/hclnow-portal-helpers/js/helpers';
import { HnpContext } from '../../contexts/HnpContext';
import { StyledCard } from '../HnpMuiComponents';
import HnpCasesChart from '../HnpCasesChart';
import HnpSelect from '../HnpSelect';

const PREFIX = 'HnpCaseSummary';

const classes = {
    chartParent: `${PREFIX}-chartParent`,
    chartIcon: `${PREFIX}-chartIcon`,
    header: `${PREFIX}-header`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.chartParent}`]: {
        height: theme.spacing(17.5),
        '&:last-child': {
            paddingBottom: theme.spacing(1.5)
        }
    },
    [`& .${classes.chartIcon}`]: {
        display: 'flex',
        fontSize: theme.spacing(6.125),
        color: colors.blue2
    },
    [`& .${classes.header}`]: {
        paddingRight: theme.spacing(1),
        '& .MuiSvgIcon-root': {
            marginTop: theme.spacing(-0.5)
        },
        '& .MuiCardHeader-avatar': {
            marginLeft: theme.spacing(-0.625),
            marginRight: theme.spacing(1.5)
        }
    }
}));

let HnpCaseSummary = () => {
    const { appStore, setAppStore } = useContext(HnpContext);
    const { filters, caseMetrics, hnpApiUtils, duration } = appStore || {};
    const metricsDuration = duration ? duration.value : '';
    const durationValue = getDurationValue(metricsDuration) || [];
    const from = decodeURIComponent(durationValue[0]) || '';
    const to = decodeURIComponent(durationValue[1]) || '';

    const caseSummaryProducts = (from = 'now-30d', to = 'now') => {
        async function caseAsync() {
            const casesCounts = { total: 0, openCases: 0, closedCases: 0 };
            // We are supporting single product for now, hence the Promise.all, reduce response has been removed with updatedPartNoOptions
            // task-HCLNOW-724 check commit history for multiple product support.
            const casesResponses = await caseSummaryFetch(from, to);
            casesCounts.total = casesResponses.total;
            casesCounts.openCases = casesResponses.openCases;
            casesCounts.closedCases = casesResponses.closedCases;

            setAppStore(prevState => ({
                ...prevState,
                caseMetrics: {
                    total: casesCounts.total,
                    open: casesCounts.openCases,
                    closed: casesCounts.closedCases
                }
            }));
        }
        caseAsync();
    };
    const caseSummaryFetch = async (from, to) => {
        // Get Cases metrics counts for open and closed cases
        const [selectedHCN] = appStore.filters.hcn;
        const caseParam = {
            customer: selectedHCN.value,
            from,
            to,
            size: 500
        };
        const caseSummary = await hnpApiUtils.getOpensearchServiceMetrics(
            caseParam
        );
        const openCases = caseSummary.data.summary.opened;
        const closedCases = caseSummary.data.summary.closed;
        const total =
            caseSummary.data.summary.opened + caseSummary.data.summary.closed;

        return { total, openCases, closedCases };
    };

    useEffect(() => {
        // Fetch case summary regarding available products for the customer. It will update the UI asynchrounously.
        caseSummaryProducts(from, to);
    }, [filters && filters.hcn, from, to]);

    useEffect(() => {
        return () => {
            // Reset the caseMetrics on component unmount.
            setAppStore(prevState => ({
                ...prevState,
                caseMetrics: {
                    total: null,
                    open: null,
                    closed: null
                }
            }));
        };
    }, []);

    return (
        caseMetrics &&
        caseMetrics.total !== null &&
        caseMetrics.total !== undefined && (
            <StyledGrid xs={12} sm={6} lg={3}>
                <StyledCard sx={sxStyles.envDetailsCard}>
                    <CardHeader
                        className={classes.header}
                        avatar={
                            <AssessmentIcon className={classes.chartIcon} />
                        }
                        title="Service Metrics Summary"
                        subheader={<HnpSelect />}
                    />
                    <CardContent className={classes.chartParent}>
                        {caseMetrics.total ? (
                            <HnpCasesChart />
                        ) : (
                            <Typography
                                variant="body2"
                                sx={[
                                    sxStyles.verticalEllipsis,
                                    sxStyles.italicText
                                ]}
                            >
                                No cases to be displayed.
                            </Typography>
                        )}
                    </CardContent>
                </StyledCard>
            </StyledGrid>
        )
    );
};

export default HnpCaseSummary;
